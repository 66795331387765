const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:9c3386b1-5342-4ece-b2ed-59667b2aec17",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_r9PHK1tZL",
    "aws_user_pools_web_client_id": "3jkm2g4rj9g0bjsfo2oqsdu33h",
    "oauth": {},
    "aws_mobile_analytics_app_region": "ap-south-1",
    "aws_mobile_analytics_app_id": "9b7ffa0f54f74ef7856b33d0daa48014"
  };
  export default awsmobile;