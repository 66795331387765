import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

export const DualChart = (props) => {
  let usa = [];
  let brazil = [];
  let subset1 = [];
  let subset2 = [];

  if (props) {
    for (let i in props.data) {
      subset1.push(props.data[i]["date"]);
      subset1.push(props.data[i]["USD_UGX"]);
      usa.push(subset1);
      subset1 = [];
      subset2.push(props.data[i]["date"]);
      subset2.push(props.data[i]["USD_BRL"]);
      brazil.push(subset2);
      subset2 = [];
    }
  }
  const options = {
    chart: {
      zoomType: "x",
      type: "area",
      height: 185,
    },
    title: {
      text: "",
    },

    xAxis: {
      type: "datetime",
      lineWidth: 1,
      lineColor: "#515151",
      tickColor: "#515151",
      tickInterval: 1000 * 60 * 60 * 24 * 365,
      labels: {
        useHTML: true,
        style: {
          fontSize: "10px",
          padding: "10px",
        },
        formatter: function () {
          return moment(this.value).format("YYYY"); // Format as YYYY-MM-DD
        },
      },
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value}",
          style: {
            color: "#E31A1A",
            fontSize: "10px",
          },
        },
        title: {
          text: "USD/B-Real",
          style: {
            color: "#E31A1A",
            fontSize: "10px",
          },
        },
      },
      {
        // Secondary yAxis
        title: {
          text: "USD/UGX",
          style: {
            color: "#4C9A4E",
            fontSize: "10px",
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: "#4C9A4E",
            fontSize: "10px",
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: false,
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b><br/>',
    },
  
    legend: {
      verticalAlign: "top",
      layout: "horizontal",
      x: 200,
      y: -20,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "USA",
        data: usa,
        color: "#4C9A4E",
        yAxis: 1,
      },
      {
        name: "Brazil",
        data: brazil,
        color: "#E31A1A",
        tooltip: {
        valueDecimals: 2, // Display two decimal places for Brazil series
      },
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
