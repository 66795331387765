import React, { useState } from "react";
import { Sidebar } from "./Sidebar";
import { Prices } from "./Market/Prices/Prices";
import { Production } from "./Market/Production/Production";
import { News } from "./Market/News/News";
import { Transaction } from "./Transaction/Transaction";
import MainRisk from "./Risk/Monitor/MainRisk";
import { RiskPosition } from "./Risk/RiskPosition/RiskPosition";
import { Glossary } from "./Glossary/Glossary";
import Footer from "./Footer";
import Header from "./Header";
import { Home } from "./Home/Home";


export const MainLayout = () => {
  const [menu, setMenu] = useState("Home");
  return (
    <>
    <Header/>
    <div style={{ marginTop: '90px' , marginBottom:'50px'}}>
      <Sidebar setMenu={setMenu} menu={menu} />
     { menu === "Home" ? (
        <Home />
      ) : menu === "Prices" || menu === "Market" ? (
        <Prices />
      ) : menu === "News" ? (
        <News />
      ) : menu === "Production" ? (
        <Production />
      ) : menu === "Transaction" ? (
        <Transaction />
      ) : menu === "Risk" || menu === "Monitor" ? (
        <MainRisk />
      ) : menu === "Risk Position" ? (
        <RiskPosition />
      ) : menu === "Glossary" ? (
        <Glossary />
      )
      
       : (
        ""
      )}
      </div>
    
     <Footer/>
  
    </>
  );
};
