import React, {useState} from "react";
import { Label, Input, FormGroup } from "reactstrap";
import "../../transaction.css";
import { useDispatch, useSelector } from "react-redux";
import { changePurchaseGrade, changePurchasePrice, changeincome_other_grades, changeoutTurn } from "../../redux/action";
export const PurchasePrice = (props) => {
  const dispatch = useDispatch();
  const purchaseGrade= useSelector((state) => state.purchaseGrade); 
  const purchasePrice= useSelector((state) => state.purchasePrice); 
  const outTurn= useSelector((state) => state.outTurn); 
  const income_other_grades = useSelector((state) => state.income_other_grades); 
  const [outTurnError, setOutTurnError] = useState("");
  const customStyles = {
    border:'2px solid #c6c6c6',
  };
  const validateOutTurn = (input) => {
    if (input === "") {
      return true;
    }
    return /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$/.test(input);
  };
  
  const handleOutTurnChange = (e) => {
    const inputValue = e.target.value;
    if (validateOutTurn(inputValue)) {
      setOutTurnError(" ");
      dispatch(changeoutTurn(inputValue));
    } else {
      setOutTurnError("Out Turn should be a number between 0 and 100.");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-7 big-label">Contract Details</div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Label className="small-label">Purchase Grade</Label>
          <div className="row radio-text">
            {props.coffeeType === "Arabica" ? (
              <>
                <div className="col-md-4">
                  <FormGroup check>
                    <Input
                      name="grade"
                      type="radio"
                      style={customStyles}
                      checked={purchaseGrade === 'Arabica-Drugar'}
                      onClick={() => dispatch(changePurchaseGrade('Arabica-Drugar'))  }
                    />{" "}
                    <Label check>Arabica-Drugar</Label>
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup check>
                    <Input
                      name="grade"
                      type="radio"
                      style={customStyles}
                      checked={purchaseGrade === 'Arabica Parchment'}
                      onClick={() => dispatch(changePurchaseGrade('Arabica Parchment'))  }
                    />{" "}
                    <Label check>Arabica Parchment</Label>
                  </FormGroup>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-4">
                  <FormGroup check>
                    <Input
                      name="grade"
                      type="radio"
                      style={customStyles}
                      checked={purchaseGrade === 'Robusta-Kiboko'}
                      onClick={() => dispatch(changePurchaseGrade('Robusta-Kiboko'))  }
                    />{" "}
                    <Label check>Robusta-Kiboko</Label>
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup check>
                    <Input
                      name="grade"
                      type="radio"
                      style={customStyles}
                      checked={purchaseGrade === 'Robusta- FAQ'}
                      onClick={() => dispatch(changePurchaseGrade('Robusta- FAQ'))  }
                    />{" "}
                    <Label check>Robusta- FAQ</Label>
                  </FormGroup>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-4">
          <FormGroup>
            <Label className="small-label">Purchase Price</Label>
            <Input
              bsSize="sm"
              type="number"
              style={customStyles}
              onWheel={(e) => e.target.blur()}
              placeholder="Enter value in UGX/Kg"
              value={purchasePrice}
              onChange={(e) => {
                dispatch(changePurchasePrice(e.target.value));
              }}
            />
          </FormGroup>
        </div>
        <div className="col-md-4">
          <FormGroup>
            <Label className="small-label">Out Turn</Label>
            <Input
              bsSize="sm"
              type="number"
              onWheel={(e) => e.target.blur()}
              value={outTurn}
              style={customStyles}
              placeholder="Enter percentage"
              onChange={handleOutTurnChange}
              className={outTurnError ? "error" : ""}
            />
            {outTurnError && (
              <div style={{ color: "red", fontSize: "10px" }}>
                {outTurnError}
              </div>
            )}
          </FormGroup>{" "}
        </div>
        <div className="col-md-4">
          <FormGroup>
            <Label className="small-label">Income-Other Grades </Label>
            <Input
              bsSize="sm"
              type="number"
              onWheel={(e) => e.target.blur()}
              value={income_other_grades}
              style={customStyles}
              placeholder="Lumpsum value in UGX"
              onChange={(e) => {
                dispatch(changeincome_other_grades(e.target.value));
                console.log(income_other_grades)
              }}
            />
           
          </FormGroup>{" "}
        </div>
      </div>
    </>
  );
};
