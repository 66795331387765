import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Auth } from "aws-amplify";
import { Icegraph } from "./Icegraph";

export const Linegraph = () => {
  const [coffeeType, setCoffeeType] = useState("Robusta");
  const [averageicePrice, setAverageicePrice] = useState(null);
  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
        fetch("/api/home/averageIcePrice?coffee_type="+coffeeType,  {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setAverageicePrice(data);
            if(data.message === "No transactions found for the specified account")
            setAverageicePrice(null);
           else
           setAverageicePrice(data);
          })
         .catch((error) => {
          setAverageicePrice(null);
          console.log("Error occured",error)
        });
    }
    genToken();
  }, [coffeeType]);
  return (
    <>
     
          <Card className="shadow">
            <CardBody>
              <CardTitle
                style={{ textAlign: "left" }}
                className="card-title-prices"
              >
                <div className="row">
                  <div className="col-md-9" style={{ textAlign: "left" }}>
                  {/* Monthly Average Unit Sales Price vs Monthly Average ICE Price
                   */}
                   Monthly Average ICE Price
                  </div>
                  <div className="col-md-2" >
                  <div className="switches-container">
              <input
                type="radio"
                id="switchRobusta"
                name="switchPlan"
                value="Robusta"
                checked={coffeeType === "Robusta"}
                onChange={() => setCoffeeType("Robusta")}
              />
              <input
                type="radio"
                id="switchArabica"
                name="switchPlan"
                value="Arabica"
                checked={coffeeType === "Arabica"}
                onChange={() => setCoffeeType("Arabica")}
              />
              <label htmlFor="switchRobusta">Robusta</label>
              <label htmlFor="switchArabica">Arabica</label>
              <div className="switch-wrapper">
                <div className="switch">
                  <div>Robusta</div>
                  <div>Arabica</div>
                </div>
              </div>
                  </div>
                  </div>
                </div>
              </CardTitle>
 
           <Icegraph data={averageicePrice} coffeeType={coffeeType}/>
          
            </CardBody>
          </Card>
          
    </>
  );
};
