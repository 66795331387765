import React from "react";

export const Prices = () => {
    const cardData = [
        {
          title: 'Futures Price',
          subtext: "Futures prices in the table ('ICE Futures' table) refer to the intra-day coffee prices for the latest contracts as published by Barcharts. Prices for Arabica are based on the contracts traded on InterContinental Exchange(ICE)-New York and for Robusta it is based on the contracts traded on InterContinental Exchange (ICE)-London. Prices are updated twice a day and the timestamp is visible on the lower bottom of the table. Prices are expressed in USD/Kg for Robusta and US cents/Pound for Arabica.Futures prices in the graph are based on continuous series as published by Investing.com. Graph data is available for the near month, mid-month and far month contracts",
        },
        {
          title: '1D %',
          subtext: 'Difference between current price and the price 15 trading days prior ',
        },
        {
          title: '15D %',
          subtext: 'Difference between current price and the price 15 trading days prior  expressed in percentages',
        },
       
        {
           title: 'Contract',
           subtext: 'The contract name refers to the month in which coffee is delivered. Each contract has its own Futures price which is traded till the contract is active',
        },
        {
            title: 'Export Prices',
            subtext: 'Reference Ugandan Export coffee prices published by UCDA for the previous day. Prices for export grades are expressed in US Cents/Pounds  ',
        },
        {
            title: 'Screen 18',
            subtext: 'Coffee beans that have passed through a sieve with 18/64th inch holes,  indicating a specific grading standard based on size for coffee beans  that are to be exported',
        },
        {
            title: 'Screen 15',
            subtext: 'Coffee beans that have passed through a sieve with 15/64th inch holes,  indicating a specific grading standard based on size for coffee beans  that are to be exported',
        },
        {
            title: 'Screen 12',
            subtext: 'Coffee beans that have passed through a sieve with 12/64th inch holes,  indicating a specific grading standard based on size for coffee beans  that are to be exported',
        },
        {
            title: 'Farm Gate Prices',
            subtext: 'Daily reference farm Gate prices published by UCDA for grades traded locally. Prices are expressed in UGX/Kg- ',
        },
        {
            title: 'Kiboko',
            subtext: 'Ripe Coffee Cherries',
        },
        {
            title: 'FAQ',
            subtext: 'The ripe coffee fruits (cherries) undergo various processing in order to remove pulp, mucilage, parchment, and film to improve their appearance,resulting in clean Robusta coffee (FAQ). ',
        },
        {
            title: ' Arabica Parchment',
            subtext: ' Arabica coffee that has undergone a wet processing method, but is still encased in the parchment layer. Arabica parchment is benchmarkable to the Coffee traded in ICE New York',
        },
        {
            title: 'USD/UGX',
            subtext: 'The rate at which one US Dollar can be exchanged for Ugandan Shillings',
        },
        {
            title: 'USD/B- Real',
            subtext: 'The rate at which one US Dollar can be exchanged for Brazilian Real',
        },
        {
            title: 'Fairtrade minimum Price',
            subtext: 'The minimum price that is set by fair trade International as a safeguard to protect producers in developing countries . The given rates for Arabica and Robusta coffee types are effective from 1 August 2023. Source Fairtrade International',
        },
        {
            title: 'Fair Trade Premium',
            subtext: 'Additional amount of money paid on top of the final price for Fair Trade certified coffee. Expressed in USD/Pound. Source Fairtrade International',
        },
        {
            title: 'Organic Premium',
            subtext: ' Additional price or premium paid for products that are certified organic set by Fairtrade. Expressed in USD/Pound. Source Fairtrade International  ',
        },

      ];
  return (
    <>
      <div className="row mt-4 ">
      {cardData.map((card, index) => (
      <div className="col-md-11 " style={{border:'1px solid #E0E0E0',borderRadius:'8px',textAlign: "left", padding:'12px 16px', marginBottom:'10px', marginLeft:'10px'}}>
      <h3 
      style={{
        fontFamily:'Inter',
        fontSize:'16px',
        fontStyle: 'normal',
        fontWeight:700,
        lineHeight:'20px',
        letterSpacing:'-0.5px'
       }}>
        {card.title}</h3>
       <p 
       style={{
        fontFamily:'Inter',
        fontSize:'14px',
        fontStyle: 'normal',
        fontWeight:500,
        lineHeight:'20px',
        letterSpacing:'-0.2px',
        color:'#575757'
       }}>{card.subtext}</p>
      </div>
      ))}
        </div>
    
     
    </>
  );
};
