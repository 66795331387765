import React from "react";
import "react-notifications-component/dist/theme.css";
import "animate.css";
import ContractTable from "./ContractTable";
import "../../../styles.css";

export const Monitor = (props) => {
  const handleContractNumber = (e) => {
    props.contractNumber(e)
  }

  return (
    <>
      <div className="scrollable-content">
      <ContractTable contractNumber={handleContractNumber} />
      </div>
    </>
  );
};
