import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Intersectiongraph = ({ monthlySalesQuantity, monthlySalesValue }) => {
  if (monthlySalesQuantity == null|| monthlySalesValue==null) {
    return <div>No data available</div>;
  }
   const categories = monthlySalesQuantity.map((item) => {
    const month = item.month;
    const year = item.year.toString().slice(-2); // Get the last two characters of the year
    return `${month} '${year}`;
  });

  const options = {
    chart: {
      type: "column",
      height: 400, 
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: categories,
      
    },

   
    yAxis: [
      {
        title: {
          text: "Monthly Sales Quantity (MT)",
        },
      },
      {
        title: {
          text: "Monthly Sales Value (UGX Mn)",
          style: {
            whiteSpace: "normal", // Allow text to wrap
          },
        },
        opposite: true,
      },
    ],
    credits: {
      enabled: false,
    },

    
    series: [
      {
        name: "Monthly Sales Quantity",
        data: monthlySalesQuantity.map((item) => item.monthly_sales_quantity),
        yAxis: 0,
        type: "column",
        color: '#4A42A0',
        pointWidth: 30,
      },
      {
        name: "Monthly Sales Value",
        data: monthlySalesValue.map((item) => item["Monthly Sales Value"]),
        yAxis: 1,
        color: 'red',
        type: "line",
      },
    ],
  };
  
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default Intersectiongraph;
