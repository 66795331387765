import React, { useState, useEffect } from "react";
import { LineColumnChart } from "./LineColumnChart";
import { Linegraph } from "./Linegraph";
import { Salesgrade } from "./Salesgrade";
import { Auth, Analytics } from "aws-amplify";
import { FcSalesPerformance } from 'react-icons/fc';
import { Card, CardBody, CardTitle } from "reactstrap";
import { TopBuyers } from "./TopBuyers";
import { MdOutlineProductionQuantityLimits } from 'react-icons/md';
import "../../switch.css";
import TickerComponent from "./TickerComponent";

export const Home = () => {
  const [totalSalesQuantity, settotalSalesQuantity] = useState(null);
  const [totalSalesValue, settotalSalesValue] = useState(null);

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      Analytics.autoTrack('session', {
        // REQUIRED, turn on/off the auto tracking
        enable: true,
        // OPTIONAL, the attributes of the event, you can either pass an object or a function
        // which allows you to define dynamic attributes
        attributes: {
          email: email,
          page:'Home'
        },
      });


      fetch("/api/home/totalSalesQuantity?account_id=" + email, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          settotalSalesQuantity(data);
          if (data.message === "No transactions found for the specified account")
            settotalSalesQuantity(null);
          else
            settotalSalesQuantity(data);

        });

      fetch("/api/home/totalSalesValue?account_id=" + email, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          settotalSalesValue(data);
          if (data.message === "No transactions found for the specified account")
            settotalSalesValue(null);
          else
            settotalSalesValue(data);

        })
    }
    genToken();
  }, []);
  return (
    <>
     <div className="row mt-5 justify-content-end mb-2">
     <div className="col-md-11">
     <div className="scrollable-content">

     <div className="row mb-2">
     <div className="col-md-3 mt-2" data-testid="text1" style={{fontSize:'18px', fontWeight:700, padding:0, textAlign: "left", marginLeft:10}} >
     Daily Coffee Market Prices
      </div>
      <div className="col-md-9" style={{ padding: 0, marginLeft: -70 }}>
    <TickerComponent/>
      </div>
      </div>
     < div className="col-md-10 mt-4" style={{ textAlign: "left" }} data-testid="text2">
      <h5
      style={{
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      padding:0,
      lineHeight: "28px",
      letterSpacing: "-0.5px",
       }}
      >
      Sales Dashboard
      </h5>
      </div>
     <div className="row mt-1">
     <div className="col-md-8">
      <div className="mt-5">
      <LineColumnChart/>
       </div>
       </div>
      
      <div className="col-md-4 justify-content-center">
        <div className="mt-5 ">
        <Card className="shadow" style={{ width: "100%", height: "220px", background: '#E5E5F2' }}>
            <CardBody style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
            <CardTitle className="card-title-prices">
            <div className="d-flex align-items-center">
            <div className="col-md-2 mt-4">
            <MdOutlineProductionQuantityLimits size={65} color="#4A42A0" />
            </div>
            <div className="col-md-10" style={{ color: '#4A42A0', fontSize: '40px'}} data-testid="totalSalesQuantity">
              <span>{totalSalesQuantity}</span>
            </div>
          </div>
          <div  style={{ marginLeft:'40px',fontSize:'22px'}}>YTD Sales Quantity (MT)</div>
        </CardTitle>
      </CardBody>
     </Card>
       </div>
        <div className="mt-4">
        <Card className="shadow" style={{ width: "100%", height: "220px", background: '#E5E5F2' }}>
            <CardBody style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
            <CardTitle className="card-title-prices">
            <div className="d-flex align-items-center">
            <div className="col-md-2 mt-4">
            <FcSalesPerformance size={65} color="#4A42A0" />

            </div>
            <div className="col-md-10" style={{ color: '#4A42A0', fontSize: '40px' , fontWeight:700}} data-testid="totalSalesValue">
              <span> {totalSalesValue}</span>
            </div>
          </div>
          <div style={{  textAlign:'right', fontSize:'22px'}}>YTD Sales Values (UGX Mn)</div>
        </CardTitle>
      </CardBody>
    </Card>
          </div>
          </div>
      </div>
     <div className="row mt-2">
            <div className="col-md-8">
              <div className="mt-5">
                <Linegraph data-testid="lineGraph" />
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="mt-5">
                <Salesgrade />
              </div>
            </div>
          </div>
          <div className="row mt-5 justify-content-center mb-2">
            <div className="col-md-11">
              <TopBuyers className="topBuyersComponent" />
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};