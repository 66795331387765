import React, { useState, useEffect } from "react";
import { Card, CardTitle, CardSubtitle, CardBody } from "reactstrap";
import { FiDownload } from "react-icons/fi";
import { BsGraphUpArrow } from "react-icons/bs";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton } from "@mui/material";
import { Chart } from "./Chart";
import "../../../styles.css";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import moment from "moment";

export const ICESection = (props) => {
  const [contract, setContract] = useState(1);
  const [contractname, setContractname] = useState('');
  const [futuresGraph, setFuturesGraph] = useState([]);
  const [futuresTable, setFuturesTable] = useState([]);
  const [futuresCsvTable, setFuturesCsvTable] = useState([]);
  const [stats, setStats] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      fetch(
        "/api/market/prices/continousFutureTable?coffee_type=" +
          props.coffeeType,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setFuturesTable(data["result"]);
          setLastUpdated(data["Updated On"]);
          setContractname(data["result"][0]["contract"]);
          //setStats(data["result"][0]["stats"]);
          const csvdata = [...data["result"]]
          for (let i in csvdata){
            csvdata[i]['change'] = csvdata[i]['stats']['change']
            csvdata[i]['date'] = moment(new Date(csvdata[i]['date'])).format('DD-MM-YYYY');
          }
          const newArray = csvdata.map((item) => {
            // Create a copy of the object without the 'stats' key
            const { stats, ...newObject } = item;
            return newObject;
          });
          setFuturesCsvTable(newArray)
        })
        .catch((error) => {
       alert("Error in ICE FutureTable")
      });
     
    }
    genToken();
  }, [props.coffeeType]);

  useEffect(()=>{
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      fetch(
        "/api/market/prices/continousFutureGraph?coffee_type=" +
          props.coffeeType +
          "&contract_name=" +
          contractname,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setStats(data[data.length-1]['stats'])
          setFuturesGraph(data);  
        })
        .catch((error) => {
          setFuturesGraph(null);  
          alert("Error while loading ICE futures graph")
      });
     
    }
    genToken();
  },[props.coffeeType,contractname])

 
  const columns = [
    {
      header: "Contract",
      accessorKey: "contract",
      size: 50,
    },
    {
      header: "Current Price",
      accessorKey: "price",
      size: 50,
    },
    {
      header: "1D% Change",
      accessorKey: "1day%",
      size: 70,
    },
    {
      header: "15D% Change",
      accessorKey: "15day%",
      size: 50,
    },
  ];
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <Card className="shadow">
            <CardBody>
              <CardTitle className="card-title-prices">
                <div className="row">
                  <div className="col-md-10" style={{ textAlign: "left" }}>
                  {props.coffeeType === "Robusta" ? "ICE Futures (London)" : "ICE Futures (NewYork)"} 
                    <div className="row">
                      <div className="col-md-10 grey-text"> {props.coffeeType === "Robusta" ? "USD/kg" : "Cents/Pound"}</div>
                    </div>
                  </div>
                  <div className="col-md-2">
                  <CSVLink data={futuresCsvTable} filename= {props.coffeeType === "Robusta" ? "ICE Futures (London).csv" : "ICE Futures (NewYork).csv"} >
                    <FiDownload  style={{ color: "#4C44D8" }} />
                    </CSVLink>
                  </div>
                </div>
              </CardTitle>
              <MaterialReactTable
                columns={columns}
                data={futuresTable}
                enableRowSelection={false}
                enableColumnOrdering={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnFilters={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableRowActions
                positionActionsColumn={"last"}
                initialState={{
                  density: "compact",
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "20px",
                    letterSpacing: "-0.5px",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    zIndex: "auto",
                    color: "#4C44D8",

                    /* inter/semibold/16 */
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    letterSpacing: "-0.5px",
                  },
                }}
                muiTablePaperProps={{
                  elevation: 0, //change the mui box shadow
                }}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "Chart", //change header text
                  },
                }}
                renderRowActions={({ row }) => (
                  <Box>
                    {row.index < 3 && (
                    <IconButton
                      onClick={() => {
                        setContractname(row["original"]["contract"]);
                        setContract(row.index+1)
                        //console.log(contract,'index')
                        //setStats(row["original"]["stats"]);
                      }}
                    >
                      <BsGraphUpArrow size={15} />
                    </IconButton>
                  )}
                  </Box>
                )}
              />
              <CardSubtitle className="time-text mt-2">
                <div className="row">
                  <div className="col-md-10">Updated On {lastUpdated}</div>
                </div>
              </CardSubtitle>
            </CardBody>
          </Card>
        </div>
        <div className="col-md-6">
          <Card className="shadow">
            <CardBody>
              <CardTitle
                style={{ textAlign: "left" }}
                className="card-title-prices"
              >
                <div className="row">
                  <div className="col-md-9" style={{ textAlign: "left" }}>
                    Historical Futures
                  </div>
                  <div className="col-md-3 big-number">{stats["current"]}</div>
                </div>
              </CardTitle>
              <CardSubtitle
                className="mb-2 text-muted"
                tag="h6"
                style={{ textAlign: "left" }}
              >
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-10 light-text">ICE Futures</div>
                    </div>
                    <div className="row">
                      <div className="col-md-10 grey-text">{contractname}</div>
                    </div>
                  </div>
                  {stats["change"] || stats["1day%"] ? (
                    <>
                      <div className="col-md-3">
                        <div className="row justify-content-left">
                          <div className="col-md-10 light-text">
                            <span
                              style={{
                                color:
                                  parseFloat(stats["change"]) >= 0
                                    ? "#4C9A4E"
                                    : "#C90000",
                              }}
                            >
                              {parseFloat(stats["change"]) >= 0 ? "+" : ""}
                              {stats["change"]}
                            </span>
                            <span
                              style={{
                                color:
                                  parseFloat(stats["1day%"]) >= 0
                                    ? "#4C9A4E"
                                    : "#C90000",
                              }}
                            >
                              ({parseFloat(stats["1day%"]) >= 0 ? "+" : ""}
                              {stats["1day%"]}%){" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </CardSubtitle>
              <Chart data={futuresGraph} name="ICE" coffeeType={props.coffeeType}/>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};
