import React from "react";
import "../../progress.css";
export const Progress = (props) => {
  return (
    <>
      <div className="stepper-wrapper">
        <div
          className={
            props.step > 1
              ? "stepper-item completed"
              : props.step === 1
              ? "stepper-item active"
              : "stepper-item"
          }
        >
          <div className="step-counter">1</div>
          <div style={{ fontSize: "10px" }}>Contract</div>
        </div>
        <div
          className={
            props.step > 2
              ? "stepper-item completed"
              : props.step === 2
              ? "stepper-item active"
              : "stepper-item"
          }
        >
          <div className="step-counter">2</div>
          <div style={{ fontSize: "10px" }}>Sales Price</div>
        </div>
        <div
          className={
            props.step > 3
              ? "stepper-item completed"
              : props.step === 3
              ? "stepper-item active"
              : "stepper-item"
          }
        >
          <div className="step-counter">3</div>
          <div style={{ fontSize: "10px" }}>Purchase Price</div>
        </div>
      </div>
    </>
  );
};
