import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const Icegraph = (props) => {
if (!props.data || typeof props.data !== "object") {
        return <div>No data available</div>;
      }
  const { data } = props;

  const options = {
    chart: {
      type: "line",
      height:292
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "  ",
      },
    },
    yAxis: {
      title: {
        text: props.coffeeType === "Robusta" ? "Monthly Average ICE Price (USD/kg)" :"Monthly Average ICE Price (Cents/Pound)",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "",
        showInLegend: false,
        color: '#4A42A0',
        data: data.map((item) => [new Date(item.date).getTime(), item.price]),
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
