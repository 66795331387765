// BillList.js
import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { Auth } from "aws-amplify";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { FaPercent } from 'react-icons/fa';

const ListTable = (props) => {
  const [value, setValue] = useState(10);
  const [billItems, setbillItems] = useState([])

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    //console.log('props',props.simulatedPrice,value)
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      if(value!==''){
      fetch(
        "/api/risk/monitor/simulation?account_id="+email+"&contract_number=" + props.contractNumber + "&interest_rate=" + value +"&simulated_price="+props.simulatedPrice,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setbillItems(data)
         // console.log(data)
          props.billItems([data])
        });
        fetch(
          "/api/risk/monitor/historicalVolatility?account_id="+email+"&contract_number=" + props.contractNumber + "&interest_rate=" + value +"&simulated_price="+props.simulatedPrice,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            props.historicalVolatility(data.historical_volatility)
          })
          .catch(()=>{
            alert("Error occured !")
          })
      }
    }
    genToken();
// eslint-disable-next-line
  }, [value, props.contractNumber, props.simulatedPrice]);

  
  

  return (
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
      }}
    >
  <>
      {Object.keys(billItems).slice(0, 3).map((key, index) => {
        const value = billItems[key];
        return (
          <ListItem
            sx={{
              fontFamily: 'Inter',
              padding: 0,
              gap: '8px',
              lineHeight: '10px',
              letterSpacing: '-0.2px',
            }}
            key={index}
          >
            <ListItemText primary={key} />
            <ListItemSecondaryAction>{value}</ListItemSecondaryAction>
          </ListItem>
        );
      })}
      <div style={{ height: '16px' }}></div>
      <ListItem
        sx={{
          fontFamily: 'Inter',
          padding: 0,
          gap: '8px',
          lineHeight: '10px',
          letterSpacing: '-0.2px',
          marginBottom: 1,
          marginLeft: '-20px', 
        }}
      >
    <ListItemText primary={'Interest rate'}  sx={{
         marginLeft: '20px', 
      }} />
        <TextField
          variant="outlined"
          value={value}
          onChange={handleChange}
          sx={{
            width: '70px',
            height: '10px', // Adjust the height
            '& .MuiInputBase-input': {
              padding: '2px',
              textAlign: 'center',
            },
           
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FaPercent />
              </InputAdornment>
            ),
          }}
        />
      </ListItem>
      {Object.keys(billItems).slice(4, 7).map((key, index) => {
        const value = billItems[key];
        return (
          <ListItem
            sx={{
              fontFamily: 'Inter',
              padding: 0,
              gap: '8px',
              lineHeight: '10px',
              letterSpacing: '-0.2px',
            }}
            key={index}
          >
            <ListItemText primary={key} />
            <ListItemSecondaryAction>{value}</ListItemSecondaryAction>
          </ListItem>
        );
      })}
      <div style={{ height: '16px' }}></div>

      {Object.keys(billItems).slice(7,9).map((key, index) => {
        const value = billItems[key];
        return (
          <ListItem
            sx={{
              fontFamily: 'Inter',
              padding: 0,
              gap: '8px',
              lineHeight: '10px',
              letterSpacing: '-0.2px',
            }}
            key={index}
          >
            <ListItemText primary={key} />
            <ListItemSecondaryAction>{value}</ListItemSecondaryAction>
          </ListItem>
        );
      })}
      {Object.keys(billItems).slice(9).map((key, index) => {
        const value = billItems[key];
        const textColor = value.toString().includes('-') ? 'red' : 'green';
        return (
          <ListItem
            sx={{
              fontFamily: 'Inter',
              padding: 0,
              gap: '8px',
              lineHeight: '14px',
              letterSpacing: '-0.2px',
            }}
            key={index}
          >
            <ListItemText primary={<span style={{ fontWeight: 'bold', fontSize:'18px' }}>{key}</span>} />
            <ListItemSecondaryAction  style={{fontWeight:'bold',  fontSize:'18px',color: textColor}}>{value}</ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </>



    </List>
  );
};

export default ListTable;
