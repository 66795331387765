import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PopupSlider from './PopupSlider';
import ListTable from './ListTable';
import { ContractDetails } from './ContractDetails';
import { Auth, Analytics } from "aws-amplify";
import { FiFilter } from "react-icons/fi";
import "react-notifications-component/dist/theme.css";
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { CSVLink } from "react-csv";

const ContractTable = (args) => {

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filter, setFilter] = React.useState('All');
  const [historicalVolatility, setHistoricalVolatility] = useState(null)
  const [billItems, setBillItems] = useState([])
  const [simulatedPrice, setSimulatedPrice] = useState(null)


  const weightCondition = (weight, coffee_type) => {
    if ((coffee_type === "Arabica" && weight >= 17200) || (coffee_type === "Robusta" && weight >= 10000))
      return false
    else
      return true
  }
  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const savebuttonStyle = {
    backgroundColor: "#4C44D8",
    border: '1px solid #4C44D8',
    borderRadius: '8px',
    color: '#FFF',
    fontFamily: 'Inter'
  };
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [data, setData] = useState([])
  const [contractNumber, setContractNumber] = useState('')
  const [monitor, setMonitor] = useState(true)
  const [buyerName, setBuyerName] = useState('')
  const [coffeeType, setCoffeeType] = useState('')
  const handleBack = () => {
    setMonitor(true)
  }

  useEffect(() => {
    async function genToken() {
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      Analytics.autoTrack('session', {
        // REQUIRED, turn on/off the auto tracking
        enable: true,
        // OPTIONAL, the attributes of the event, you can either pass an object or a function
        // which allows you to define dynamic attributes
        attributes: {
          email: email,
          page: 'Risk_Monitor'
        },
      });
    }
    genToken()
  }, [])

  useEffect(() => {
    let didCancel = false;
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      fetch("/api/risk/monitor/filterContractTable?account_id=" + email + "&coffee_type=" + filter, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data);
        })
        .catch(() => {
          setData([]);
        })
      if (!didCancel) {
        console.log("....");
      }
    }
    genToken();
    return () => {
      didCancel = true;
    };
  }, [filter])

  const columns = [
    {
      accessorKey: 'Coffee_Type',
      header: 'Coffee',
      size: 20,
    },
    {
      accessorKey: 'Name',
      header: 'Buyer',
      size: 20,
    },

    {
      accessorKey: 'Quantity',
      id: 'Quantity',
      header: 'Quantity kg',
      size: 20,
    },
    {
      accessorKey: 'Currentvalue',
      header: 'Current value UGX',
      id: 'CurrentValue',
      size: 20,
      Cell: ({ cell }) => {
        const currentValue = cell.getValue();
        const currentstatus = cell["row"]["original"]["Current Value Status"];
        const cellStyle = {
          backgroundColor: currentstatus ? '#EA4444' : null,
          borderRadius: '0.25rem',
          maxWidth: '9ch',
          p: '0.25rem',
        };

        return (
          <Box component="span" sx={(theme) => cellStyle}>
            {currentValue}
          </Box>
        );
      },
    },
    {
      accessorKey: 'Breakevenvalue',
      header: 'Breakeven Value UGX',
      size: 20,
      enableSorting: true,
    },

    {
      accessorKey: 'DeliveryDate', // Convert to Date for sorting and filtering
      id: 'DeliveryDate',
      header: 'Delivery Date',
      filterFn: 'lessThanOrEqualTo',
      sortingFn: 'datetime',
      Cell: ({ cell }) => {
        const deliveryDate = cell.getValue();
        const deliverystatus = cell["row"]["original"]["Delivery Date Status"];
        const cellStyle = {
          backgroundColor: deliverystatus ? '#EA4444' : null,
          borderRadius: '0.25rem',
          maxWidth: '9ch',
          p: '0.25rem',
        };

        return (
          <Box component="span" sx={(theme) => cellStyle}>
            {deliveryDate}
          </Box>
        );
      },

      Filter: ({ column }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(newValue) => {
              column.setFilterValue(newValue);
            }}
            slotProps={{
              textField: {
                helperText: 'Filter Mode: Less Than',
                sx: { minWidth: '120px' },
                variant: 'standard',
              },
            }}
            value={column.getFilterValue()}
          />
        </LocalizationProvider>
      ),
    },
    {
      accessorKey: 'PriceFixingPeriod',
      header: 'Price Fixing Period',
      size: 20,
      Cell: ({ cell }) => {
        const priceFixingPeriod = cell.getValue();
        const [startMonthDay, endMonthDay] = priceFixingPeriod.split(' - ');

        const [startMonth, startDay] = startMonthDay.split(' ');
        const [endMonth, endDay] = endMonthDay.split(' ');

        // Calculate the start and end date objects, taking into account the current year
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const startDateObj = new Date(`${startMonth} ${startDay}, ${currentYear}`);
        const endDateObj = new Date(`${endMonth} ${endDay}, ${currentYear}`);

        // Check if the end date is before the current date and adjust it to the next year if necessary
        if (endDateObj < currentDate) {
          endDateObj.setFullYear(currentYear + 1);
        }

        // Calculate the duration of the price fixing period in days
        const durationInDays = Math.floor((endDateObj - startDateObj) / (1000 * 60 * 60 * 24));

        // Calculate the number of days remaining from the current date to the end date
        const daysRemaining = Math.floor((endDateObj - currentDate) / (1000 * 60 * 60 * 24));

        // Define a style object to apply to the cell based on the condition
        const cellStyle = {
          backgroundColor: daysRemaining < 30 && durationInDays < 30 ? '#EA4444' : null,
          borderRadius: '0.25rem',
          p: '0.25rem',
        };

        return (
          <Box component="span" sx={(theme) => cellStyle}>
            {priceFixingPeriod}
          </Box>
        );
      }, 
    },
  ]
  return (
    <>
      {monitor ? (
        <div data-testid="contractTable">
          <div className="row mt-5 justify-content-end mb-2">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-10 mb-4" style={{ textAlign: "left" }}>
                  <h5
                    style={{
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "28px",
                      letterSpacing: "-0.5px",
                    }}
                  >
                    Monitoring Forward contracts
                  </h5>
                </div>
                <div className="col-md-2">
                  <div className="row">
                    <div className="col mt-1" style={{ color: "#4A42A0" }}   onClick={toggleDrawer} >
                      <FiFilter size={20} style={{ color: "#4A42A0" }} data-testid="filterButton" /> Filter
                      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
                        <div style={{ width: 280 }}>
                          <List>
                            <ListItem onClick={toggleDrawer} data-testid="applyFilterButton">
                              <ListItemText primary="Filters" />
                            </ListItem>
                            <ListItem>Coffee type</ListItem>
                            <ListItem onClick={toggleDrawer}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={filter}
                                  label="Select"
                                  onChange={handleChange}

                                >
                                  <MenuItem value={"Robusta"}   data-testid="filterSelect" >Robusta</MenuItem>
                                  <MenuItem value={"Arabica"}>Arabica</MenuItem>
                                  <MenuItem value={"All"}>All</MenuItem>


                                </Select>
                              </FormControl>
                            </ListItem>
                            {/* Add more list items here if needed */}
                          </List>
                        </div>
                      </Drawer>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 row mt-2 justify-content-center">
                <MaterialReactTable
                  columns={columns}
                  data={data}
                  enableRowSelection={false}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableColumnFilters={false}
                  enableTopToolbar={false}
                  enableBottomToolbar={false}
                  enableRowActions
                  positionActionsColumn="last"
                  enableColumnActions={false}
                  enableColumnOrdering={false}
                  muiTableBodyCellProps={{
                    sx: {
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "40px",
                      letterSpacing: "-0.5px",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      zIndex: "auto",
                      lineHeight: "57px",
                      color: "#4C44D8",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      letterSpacing: "-0.5px",
                    },
                  }}
                  muiTableHeadRowProps={{
                    sx: {
                      background: '#E5E5F2',
                    },
                  }}

                  renderRowActions={({ row }) => (
                    <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "7px" }}>
                      <Button
                        style={{
                          borderColor: "#4C44D8",
                          backgroundColor: weightCondition(row.original.Quantity, row.original.Coffee_Type) ? "#808080" : "#4C44D8",
                          color: "#fff",
                          marginBlock: '10px'
                        }}
                        disabled={weightCondition(row.original.Quantity, row.original.Coffee_Type)}
                        onClick={() => {
                          setCoffeeType(row.original.Coffee_Type)
                          setContractNumber(row.original.SubName);
                          toggle();
                        }}
                        data-testid="simulationButton" >
                        Simulation
                      </Button>
                      <div className="col-md-1 mt-2 " onClick={() => {
                        //console.log('details',row)
                        setMonitor(false);
                        setBuyerName(row.original.Name)
                        setCoffeeType(row.original.Coffee_Type)
                        setContractNumber(row.original.SubName)
                      }} style={{
                        color: "#4C44D8", cursor: 'pointer'
                      }}
                      data-testid="detailsButton" 
                      >
                        Details
                      </div>
                    </Box>
                  )}
                />

                <Modal isOpen={modal} toggle={toggle} {...args} centered={true}>
                  <ModalHeader style={{ display: 'block' }}>
                    <div className="row" style={{ fontFamily: 'Inter', }}>
                      <div className="col-md-9" style={{ textAlign: "left", fontSize: '16px', fontWeight: 700 }}>
                        Historical Volatility
                      </div>
                      <div className="col-md-3" style={{ fontSize: '24px', color: "#4A42A0" }}>
                        {historicalVolatility}
                      </div>
                    </div>

                  </ModalHeader>
                  <ModalBody>
                    <div >
                      <PopupSlider contractNumber={contractNumber} handleSimulatedPrice={e => setSimulatedPrice(e)} coffee_type={coffeeType} />
                    </div>
                    <ListTable contractNumber={contractNumber} historicalVolatility={e => setHistoricalVolatility(e)} billItems={e => { setBillItems(e) }} simulatedPrice={simulatedPrice} />
                  </ModalBody>
                  <ModalFooter>
                    <div className="row " style={{ fontFamily: 'Inter', fontSize: '16px' }}>
                      <div className="col-md-12">
                        <CSVLink data={billItems} filename={"Simulation.csv"}>
                          <Button style={savebuttonStyle} onClick={toggle} data-testid="modalCloseButton">
                            Download Data
                          </Button>
                        </CSVLink>
                      </div>
                    </div>
                  </ModalFooter>
                </Modal>
              </div></div></div></div>) : (<ContractDetails contractNumber={contractNumber} buyerName={buyerName} handleBack={handleBack} data-testid="contractDetails" />)
      }
    </>
  )
};

export default ContractTable;