import React from "react";

export const RiskPositionTab = () => {
    const cardData = [
        {
          title: 'Purchase of clean coffee',
          subtext: 'Quantity of clean coffee to be purchased inorder honour the deliveries of that particular month.',
        },
        {
          title: 'Spot Sales ',
          subtext: 'Quantity of coffee to be sold in lessthan 7 days',
        },
        {
          title: 'Spot Sales (Forward Contracts Delivered) ',
          subtext: ' Quantity of forward contracts being delivered in the given month. This amount is re-allocated from the spot sales bucket',
        },
        {
          title: 'Total Sales',
          subtext: 'Total  of Spot Sales and Spot Sales (Forward Contracts Delivered)',
        },
        {
           title: 'Forward Contracts-open',
           subtext: 'Monthwise quantity to be delivered in an open forward contracts, that have been established but prices are yet to be fixed.',
        },
        {
            title: 'Forward Contracts  -fixed',
            subtext: 'Monthwise quanity to be delivered in an fixed forward contracts, that have been established with mutually agreed price .',
         },
        {
            title: 'Total Forward Contract Sales  ',
            subtext: 'Total quantity of coffee beans (in metric tons) to be sold  through forward contracts, both fixed-price and open contract',
        },
        {
            title: 'Total Physical Coffee Held (Stocks)',
            subtext: 'Stock of previous month plus purchases of clean coffee less Total Sales',
        },
        {
            title: 'Net Risk Position  ',
            subtext: 'Total  physical coffee held  minus forward contracts expressed in metric tons.'
        },
      
      ];
  return (
    <>
      <div className="row mt-4 ">
      {cardData.map((card, index) => (
      <div className="col-md-11 " style={{border:'1px solid #E0E0E0',borderRadius:'8px',textAlign: "left", padding:'12px 16px', marginBottom:'10px', marginLeft:'10px'}}>
      <h3 
      style={{
        fontFamily:'Inter',
        fontSize:'16px',
        fontStyle: 'normal',
        fontWeight:700,
        lineHeight:'20px',
        letterSpacing:'-0.5px'
       }}>
        {card.title}</h3>
       <p 
       style={{
        fontFamily:'Inter',
        fontSize:'14px',
        fontStyle: 'normal',
        fontWeight:500,
        lineHeight:'20px',
        letterSpacing:'-0.2px',
        color:'#575757'
       }}>{card.subtext}</p>
      </div>
      ))}
        </div>
    
     
    </>
  );
};
