import React from "react";
import NestedTbs from "./NestedTabs";
import "../../switch.css";

export const Glossary = () => {

  return (
    <>
      <div className="row mt-5 justify-content-end mb-2">
        <div className="col-md-11">
        <div className="scrollable-content">
          <div className="row">
            <div className="col-md-11 mb-4" style={{ textAlign: "left" }}>
              <h5
                style={{
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "28px",
                  letterSpacing: "-0.5px",
                }}
              >
                Glossary
              </h5>
            </div>
            <NestedTbs/>
           </div>
        </div>
      </div>
      </div>
     
    </>
  );
};
