import React from "react";
import { Card } from "reactstrap";

export const NewsCards = (props) => {
  return (
    <>
      {Object.entries(props.data).map(([key, value]) => (
        <Card
        style={{
          borderRadius: "8px",
          border: "1px solid #E0E0E0",
        }}
        className="mt-3"
      >
          <div
           key={key}
            style={{
              margin: "10px 150px 0px 0px",
              borderRadius: "0px 4px 4px 0px",
              border: "1px solid #DAF0F7",
              backgroundColor: getCategoryBackgroundColor(value["category"]),
              color: "black",
              fontSize: "12px",
            }}
          >
            {value["category"]}
          </div>
          <div className="row m-1">
            <div className="col-md-12 news-heading">
              <a
                href={props ? value["url"] : "none"}
                className="mt-3"
                style={{ color: "#000", textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                {value["headline"]}
              </a>
            </div>
          </div>
          <div className="row m-1">
            <div className="col-md-12 grey-text">{value["author"]}</div>
            <div className="col-md-12 grey-text">{value["publish_date"]}</div>
          </div>
        </Card>
      ))}
    </>
  );
};

const getCategoryBackgroundColor = (category) => {
  switch (category) {
    case "Coffee Production":
      return "#FFE5D0";
    case "Coffee Trade & Regulations":
      return "#DAF0F7";
    case "Reports":
      return "#FFF3D0";
    case "Weather & Shipping":
        return "#e6ffe6";
      

    default:
      return "white"; 
  };
};
