import React from "react";
import { Label, Input, FormGroup } from "reactstrap";
import Select from "react-select";
import "../../transaction.css";
import { useDispatch, useSelector } from "react-redux";
import { changeContractNo, changeContractType, changeSaleType, changeSalesGrade, changebuyerName, changecoffeeType, changecontractDate, changedeliveryDate } from "../../redux/action";

export const ContractDetails = (props) => {
  const coffeeType = useSelector((state) => state.coffeeType); 
  const buyerName = useSelector((state) => state.buyerName);
  const contractType = useSelector((state) => state.contractType); 
  const contractNo= useSelector((state) => state.contractNo); 
  const deliveryDate= useSelector((state) => state.deliveryDate); 
  const contractDate= useSelector((state) => state.contractDate); 
  const salesGrade = useSelector((state) => state.salesGrade); 
  const saleType = useSelector((state) => state.saleType); 

  const dispatch = useDispatch();
  const items = [
    { id: "Certified-Fair Trade", name: "Certified-Fair Trade" },
    { id: "Certified Rainforest", name: "Certified Rainforest" },
    { id: "Certified Organic", name: "Certified Organic" },
    { id: "Not Certified", name: "Not Certified" },
  ];
  const customStyles = {
    border:'2px solid #c6c6c6',
    control: (base) => ({
      ...base,
      minHeight: 12,
      fontSize: 11,
      border:'2px solid #c6c6c6',

    }),
    
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    })
  };
  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const minContractDate = new Date("2022-01-01");

    if (selectedDate < minContractDate) {
      props.setContractDateError(true);
    } else {
     props.setContractDateError(false);
    }

    dispatch(changecontractDate(e.target.value));
  };

  const handleDeliveryDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const contractDateObj = new Date(contractDate);

    if (selectedDate < contractDateObj) {
      props.setDeliveryDateError(true);
    } else {
      props.setDeliveryDateError(false);
    }
    dispatch(changedeliveryDate(e.target.value));
  };
  const arabica_sales = [
    { value: "Arabica Parchment", label: "Arabica Parchment" },
    { value: "Drugar coffee Clean", label: "Drugar coffee Clean" },
    { value: "Bugisu AA", label: "Bugisu AA" },
    { value: "Bugisu A", label: "Bugisu A" },
    { value: "Bugisu PB", label: "Bugisu PB" },
    { value: "Bugisu B", label: "Bugisu B" },
    { value: "Wugar", label: "Wugar" },
    { value: "Arabica Drugar", label: "Arabica Drugar" },
  ];
  const robusta_sales = [
    { value: "Screen 18", label: "Screen 18" },
    { value: "Screen 12", label: "Screen 12" },
    { value: "Screen 15", label: "Screen 15" },
    { value: "FAQ", label: "FAQ" },
    
  ];
  const fairtrade_options = [
    { value: "Natural", label: "Natural" },
    { value: "Washed", label: "Washed" },
  ];
  return (
    <>
      <div className="row">
        <div className="col-md-7 big-label">Contract Details</div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Label className="small-label"> Sales Type</Label>
          <div className="row">
            <div className="col-md-5">
              <div className="row radio-text">
                <div className="col-md-6">
                  <FormGroup check>
                    <Input
                      name="sale_type"
                      type="radio"
                      style={customStyles}
                      checked={saleType === 'Local'}
                      onChange={() => dispatch(changeSaleType('Local'))  }
                    />{" "}
                    <Label check>Local</Label>
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup check>
                    <Input
                      name="sale_type"
                      type="radio"
                      style={customStyles}
                      checked={saleType === 'Export'}
                      onChange={() => dispatch(changeSaleType('Export'))  }
                    />{" "}
                    <Label check>Export</Label>
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <Label className="small-label"> Contract Type</Label>
          <div className="row">
            <div className="col-md-5">
              <div className="row radio-text">
                <div className="col-md-6">
                  <FormGroup check>
                    <Input
                      name="contract_type"
                      type="radio"
                      style={customStyles}
                      checked={contractType === 'Fixed'}
                      onChange={() => dispatch(changeContractType('Fixed'))  }
                    />{" "}
                    <Label check>Fixed</Label>
                  </FormGroup>
                </div>
                {props.saleType === "Export" ? (
                <div className="col-md-6">
                  <FormGroup check>
                    <Input
                      name="contract_type"
                      type="radio"
                      style={customStyles}
                      checked={contractType === 'Open'}
                      onChange={() => dispatch(changeContractType('Open'))  }
                    />{" "}
                    <Label check>Open</Label>
                  </FormGroup>

                </div>
                 ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6">
          <FormGroup>
            <Label className="small-label">Buyer Name</Label>
            <Input
              bsSize="sm"
              type="text"
              style={{
                width: "70%",
                ...customStyles,
              }}
              value={buyerName}
              onChange={(e) => {
                dispatch(changebuyerName(e.target.value));
              }}
            />
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup>
            <Label className="small-label">Contract #</Label>
            <Input
              bsSize="sm"
              type="text"
              style={{
                width: "70%",
                ...customStyles,
              }}
              value={contractNo}
              onChange={(e) => {
                dispatch(changeContractNo(e.target.value));
              }}
            />
          </FormGroup>{" "}
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6">
          <FormGroup>
            <Label className="small-label">Contract Date</Label>
            <Input
              id="exampleDate"
              name="date"
              style={{
                width: "70%",
                ...customStyles,
              }}
              placeholder="date placeholder"
              type="date"
              onChange={handleDateChange}
              value={contractDate}
              className={props.contractDateError ? "error" : ""}
            />
            {props.contractDateError && (
              <div style={{color:'red', fontSize:'10px'}}>
                Contract date should not be older than 2022.
              </div>
            )}{" "}
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup>
            <Label className="small-label">Delivery Date</Label>
            <Input
              required
              id="exampleDate"
              name="date"
              style={{
                width: "70%",
                ...customStyles,
              }}
              placeholder="date placeholder"
              type="date"
              value={deliveryDate}
              onChange={handleDeliveryDateChange}
              className={props.deliveryDateError ? "error" : ""}
            />
            {props.deliveryDateError && (
              <div style={{ color: "red", fontSize: "10px" }}>
                Delivery date cannot be before contract date.
              </div>
            )}{" "}
          </FormGroup>{" "}
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-5">
              <Label className="small-label"> Coffee Type</Label>
              <div className="row radio-text">
                <div className="col-md-6">
                  <FormGroup check>
                    <Input
                      name="coffee_type"
                      type="radio"
                      style={customStyles}
                      checked={coffeeType === 'Arabica'}
                      onClick={() => dispatch(changecoffeeType('Arabica'))}
                    />{" "}
                    <Label check>Arabica</Label>
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup check>
                    <Input
                      name="coffee_type"
                      type="radio"
                      style={customStyles}
                      checked={coffeeType === 'Robusta'}
                      onClick={() => dispatch(changecoffeeType('Robusta'))}
                    />{" "}
                    <Label check>Robusta</Label>
                  </FormGroup>
                </div>
              </div>
            </div>
            {coffeeType ? (
              <>
                <div className="row">
                  <div className="col-md-9">
                    <FormGroup>
                      <Label className="small-label">Sales Grade</Label>
                      <Select
                        options={
                          coffeeType === "Arabica"
                            ? arabica_sales
                            : robusta_sales
                        }
                        menuPlacement="auto"
                        styles={customStyles}
                        value={{value:salesGrade,label:salesGrade}                      }
                        placeholder="Select a Sales Grade"
                        onChange={(obj) => {
                          dispatch(changeSalesGrade(obj["value"]));
                        }}
                      />
                    </FormGroup>{" "}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-md-6">
          <Label className="small-label">Certification Type</Label>

          <div className="row">
            <div className="col-md-6 radio-text">
              {items.map((item) => (
                <div>
                  <FormGroup check>
                    <Input
                      id={item.id}
                      value={item.id}
                      type="checkbox"
                      style={customStyles}
                      checked={props.certificationType.includes(item.id)}
                      onChange={props.handleChange}
                      disabled={
                        item.id === "Not Certified" &&
                        props.certificationType.filter((val) => val !== "Not Certified").length > 0
                      }
                    />{" "}
                    <Label for={item.id}>{item.name}</Label>
                  </FormGroup>
                </div>
              ))}
            </div>
            {props.certificationType.includes("Certified-Fair Trade") ? (
              <div className="col-md-6">
                <Select
                  options={fairtrade_options}
                  styles={customStyles}
                  placeholder="Fairtrade Certification"
                  onChange={(obj) => {
                    props.setFairTrade(obj["value"]);
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
