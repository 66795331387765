import React from "react";

export const OutputTerm = () => {
    const cardData = [
        {
          title: 'Offer Price (without premium)',
          subtext: 'Price offered by the buyer without a premium in Cents/Pound  for Arabica and USD/Kg for Robusta.  In the case of Open contracts,the offer price is the futures price nearest to the delivery month',
        },
        {
          title: 'Minimum Fair trade price',
          subtext: 'Minimum price that is set by fair trade International to  protect producers in developing countries is expressed in Cents/Pound ',
        },
        {
          title: 'Final price',
          subtext: 'Final selling price. In case of not certified, Organic and Rainforest Alliance certified this is the offer price.For FT coffee fixed contract, this is higher of (i)  Fixed offer price plus quality difference and (ii) FT minimum price. For FT coffee open contract, this is higher of (i)  Near month futures price plus quality difference and (ii) FT minimum price. For organic fixed contracts this is the offer price. Premium is not added to the final selling price as the premium income is to be distributed to the growers and not retained by the co-operative. In the case of Open contracts, the final price is the futures price nearest to the delivery month ',
        },
        {
          title: 'Quality differential ',
          subtext: 'Additional price offered by a buyer based on the quality for a particular export contract expressed in USD/Kg for Robusta & Cents/Pound for Arabica',
        },
        {
           title: 'Fairtrade premium',
           subtext: 'Additional amount of money paid on top of the agreed-upon minimum price offered for Fair Trade-certified products. Expressed in Cents/Pound',
        },
        {
            title: 'Organic Premium',
            subtext: 'Additional price or premium paid for products that are certified organic. Expressed in Cents/Pound ',
        },
        {
            title: 'Rainforest Premium',
            subtext: 'Additional price or premium paid for products that are  Rain forest certified. Expressed in USD/Kg for Robusta and Cents/Pound for Arabica ',
        },
        {
            title: 'Income Other grades',
            subtext: 'Per Kilogram level, Income generated from sales of undergrades. Expressed in UGX/KG',
        },
        {
            title: 'Final selling price (UGX)',
            subtext: "Final selling price multiplied by today's exchange rate. Expressed in UGX",
        },
        {
            title: 'Variable costs-Exports',
            subtext: 'Variable costs related to exports are expenses that are specifically undertaken for export activities. Examples include packaging, shipping, customs duties, insurance, and export documentation. Export cost is inclusive of cess amount, which is captured at the transaction level. 2% of the Final Selling price in ugx is taken as Cess amount. Expressed in UGX/Kg',
        },
        {
            title: 'Net sales price',
            subtext: 'Final selling price (UGX) less Variable Costs-exports. Expresssed in UGX/Kg',
        },
        {
            title: 'Variable costs- Others',
            subtext: 'All Variable costs excluding  Exports Variable costs. Expressed in UGX /Kg ',
        },
        {
            title: 'Purchase price adjusted for out-turn',
            subtext: 'Purchase price adjusting the out-turn ratio. If purchase price is 100 and out-turn ratio is 70%, adjusted purchase price is 100/0.7',
        },
        {
            title: 'Contribution',
            subtext: 'Difference between the selling price and all variable costs calculated at a transaction level .Expresssed in UGX/Kg ',
        },
        {
            title: 'Contribution %',
            subtext: 'proportion of each sales dollar that contributes towards covering the variable costs and potentially generating profit',
        },
        {
            title: 'Target contribution',
            subtext: 'Targeted/budgeted ontribution% that the entity seeks to earn from each transaction',
        },
        {
            title: 'Fixed cost',
            subtext: 'Costs that remains constant regardless of the sales volumes.Expresssed in UGX/Kg',
        },
        {
            title: 'Profit Before Tax',
            subtext: 'Contribution less fixed costs.Expresssed in UGX/Kg',
        },
        {
            title: 'Profit Before Tax %',
            subtext: 'Ratio of net profit to total revenue expressed as a percentage',
        },
        {
            title: 'Purchase price to achieve breakeven',
            subtext: 'Suggested purchase price to achieve the target break even',
        },
       
        {
            title: 'ICE futures ',
            subtext: 'Previous day or Current day traded futures  price( for Arabica from  ICE USA, & For Robusta from ICE London).',
        },
      
      ];
  return (
    <>
      <div className="row mt-4 ">
      {cardData.map((card, index) => (
      <div className="col-md-11 " style={{border:'1px solid #E0E0E0',borderRadius:'8px',textAlign: "left", padding:'12px 16px', marginBottom:'10px', marginLeft:'10px'}}>
      <h3 
      style={{
        fontFamily:'Inter',
        fontSize:'16px',
        fontStyle: 'normal',
        fontWeight:700,
        lineHeight:'20px',
        letterSpacing:'-0.5px'
       }}>
        {card.title}</h3>
       <p 
       style={{
        fontFamily:'Inter',
        fontSize:'14px',
        fontStyle: 'normal',
        fontWeight:500,
        lineHeight:'20px',
        letterSpacing:'-0.2px',
        color:'#575757'
       }}>{card.subtext}</p>
      </div>
      ))}
        </div>
    
     
    </>
  );
};
