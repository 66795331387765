import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
export const Chart = (props) => {
  
  let subset = [];
  let final = [];
  if (props) {
    for (let i in props.data) {
      subset.push(props.data[i]["date"]);
      subset.push(props.data[i]["price"]);
      final.push(subset);
      subset = [];
    }
  }
  const options = {
    chart: {
      zoomType: "x",
      height: props.coffeeType === "Robusta" && props.name === "UCDA" ? 150:259,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      labels: {
        style: {
          fontSize: "10px",
        },
      },
    },
    yAxis: {
      title: {
        text: 
        props.coffeeType === "Robusta" && props.name === "ICE" ? "USD/kg" :
        props.coffeeType === "Arabica" && props.name === "ICE" ? "Cents/Pound" :
        "US $ cts/Lb"
  
      },
      labels: {
        style: {
          fontSize: "10px",
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.getOptions().colors[2]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[2])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },

    series: [
      {
        type: "area",
        name: " ",
        data: final,
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
