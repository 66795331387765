import React, { useState, useEffect } from "react";
import { Card, CardTitle, CardSubtitle, CardBody } from "reactstrap";
import { FiDownload } from "react-icons/fi";
import { MaterialReactTable } from "material-react-table";
import { Chart2 } from "./Chart2";
import { Box, IconButton } from "@mui/material";
import { BsGraphUpArrow } from "react-icons/bs";
import "../../../styles.css";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";

export const Shilling = () => {
  const [farmGateTable, setFarmGateTable] = useState([]);
  const [farmGateGraph, setFarmGateGraph] = useState([]);
  const [coffeeGrade, setCoffeeGrade] = useState('Arabica Parchment');
  const [lastUpdated, setLastUpdated] = useState(null);
  const [stats, setStats] = useState([]);
  const [csvFarmGateTable, setCsvFarmGateTable] = useState([]);

  const columns = [
    {
      header: "Local Grade",
      accessorKey: "coffee",
      size: 50,
    },
    {
      header: "Current Price",
      accessorKey: "price",
      size: 50,
    },
    {
      header: "1D% Change",
      accessorKey: "1day%",
      size: 50,
    },
    {
      header: "15D% Change",
      accessorKey: "15day%",
      size: 50,
    },
  ];

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      fetch("/api/market/prices/UCDAFarmGateTable", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setFarmGateTable(data["result"]);
          setLastUpdated(data["Updated On"]);
          setStats(data['result'][0]['stats'])
          const newArray = data['result'].map((obj) => {
            // Create a copy of the original object without the specified key
            const newObj = { ...obj };
            delete newObj["stats"];
            return newObj;
          });
          setCsvFarmGateTable(newArray)
        })
        .catch((error) => {

          alert("Error while loading UCDA farmgate Table")
        });
    }
    genToken();
  }, [])

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      fetch(
        "/api/market/prices/UCDAFarmGateGraph?coffee_grade=" + coffeeGrade,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setFarmGateGraph(data);
        })
        .catch((error) => {
          setFarmGateGraph("")
          alert("Error while loading UCDA farmgate graph")
        });


    }
    genToken();
  }, [coffeeGrade]);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <Card className="shadow">
            <CardBody>
              <CardTitle className="card-title-prices">
                <div className="row">
                  <div className="col-md-10" style={{ textAlign: "left" }}>
                    UCDA Farm Gate
                    <div className="row">
                      <div className="col-md-10 grey-text">UGX/Kg</div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <CSVLink data={csvFarmGateTable} filename={" UCDA_Farm_Gate.csv"}>
                      <FiDownload style={{ color: "#4C44D8" }} />
                    </CSVLink>
                  </div>
                </div>
              </CardTitle>
              <MaterialReactTable
                columns={columns}
                data={farmGateTable}
                enableRowSelection={false}
                enableColumnOrdering={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnFilters={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableRowActions
                positionActionsColumn={"last"}
                initialState={{
                  density: "compact",
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "20px",
                    letterSpacing: "-0.5px",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    zIndex: "auto",
                    color: "#4C44D8",

                    /* inter/semibold/16 */
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    letterSpacing: "-0.5px",
                  },
                }}
                muiTablePaperProps={{
                  elevation: 0, //change the mui box shadow
                }}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "Chart", //change header text
                  },
                }}
                renderRowActions={({ row }) => (
                  <Box>
                    <IconButton
                      onClick={() => {
                        setCoffeeGrade(row["original"]["coffee"]);
                        setStats(row["original"]["stats"]);
                      }}
                    >
                      <BsGraphUpArrow size={15} />
                    </IconButton>
                  </Box>
                )}
              />
              <CardSubtitle className="time-text mt-2">
                <div className="row">
                  <div className="col-md-10">Updated on {lastUpdated}</div>
                </div>
              </CardSubtitle>
            </CardBody>
          </Card>
        </div>
        <div className="col-md-6">
          <Card className="shadow">
            <CardBody>
              <CardTitle
                style={{ textAlign: "left" }}
                className="card-title-prices"
              >
                <div className="row">
                  <div className="col-md-9" style={{ textAlign: "left" }}>
                    UCDA Daily Farm Gate Prices
                  </div>
                  <div className="col-md-3 big-number">{stats["current"]}</div>
                </div>
              </CardTitle>
              <CardSubtitle
                className="mb-2 text-muted"
                tag="h6"
                style={{ textAlign: "left" }}
              >
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-10 light-text">
                        Uganda Shilling per Kg
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10 grey-text">{coffeeGrade}</div>
                    </div>
                  </div>
                  {stats["change"] !== null || stats["1day%"] !== null ? (
                    <>
                      <div className="col-md-3">
                        <div className="row justify-content-left">
                          <div className="col-md-10 light-text">
                            <span
                              style={{
                                color:
                                  parseFloat(stats["change"]) >= 0
                                    ? "#4C9A4E"
                                    : "#C90000",
                              }}
                            >
                              {parseFloat(stats["change"]) >= 0 ? "+" : ""}
                              {stats["change"]}
                            </span>
                            <span
                              style={{
                                color:
                                  parseFloat(stats["1day%"]) >= 0
                                    ? "#4C9A4E"
                                    : "#C90000",
                              }}
                            >
                              ({parseFloat(stats["1day%"]) >= 0 ? "+" : ""}
                              {stats["1day%"]}%){" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </CardSubtitle>
              <Chart2 data={farmGateGraph} />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};
