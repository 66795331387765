import React from "react";
import { Modal, ModalBody } from "reactstrap";
import "../../transaction.css";
export const LoadingModal = (props) => {
  return (
    <>
      <Modal
        isOpen={props.loadingModal}
        toggle={props.toggleLoading}
        centered={true}
      >
        <ModalBody>
          <div className="row justify-content-center">
            <div className="col-md-5">
              <img alt="Sample" src="loading.gif" height={200} />{" "}
            </div>
          </div>
          <div className="row justify-content-center big-label">
            <div className="col-md-10">The contract analysis is underway</div>
          </div>
          <div className="row justify-content-center small-label">
            <div className="col-md-10">
            we'll have the outputs for you shortly
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
