import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { Progress } from "./Progress";
import { ContractDetails } from "./ContractDetails";
import { SalesPrice } from "./SalesPrice";
import { PurchasePrice } from "./PurchasePrice";
import { LoadingModal } from "./LoadingModal";
import { OutputModal } from "./OutputModal";
import { Auth } from "aws-amplify";
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useSelector } from "react-redux";
import "animate.css";
export const InputModal = (props) => {
  const [stepper, setStepper] = useState(1);
  const [loadingModal, setLoadingModal] = useState(false);
  const toggleLoading = () => setLoadingModal(!loadingModal);
  const [outputModal, setOutputModal] = useState(false);
  const toggleOutput = () => setOutputModal(!outputModal);

  const saleType = useSelector((state) => state.saleType);
  const coffeeType = useSelector((state) => state.coffeeType);
  const buyerName = useSelector((state) => state.buyerName);
  const contractType = useSelector((state) => state.contractType);
  const contractNo = useSelector((state) => state.contractNo);
  const deliveryDate = useSelector((state) => state.deliveryDate);
  const contractDate = useSelector((state) => state.contractDate);
  const salesGrade = useSelector((state) => state.salesGrade);
  const priceFixation = useSelector((state) => state.priceFixation);
  const startDate = useSelector((state) => state.startDate);
  const endDate = useSelector((state) => state.endDate);
  const fobPriceValue = useSelector((state) => state.fobPriceValue);
  const fobPrice = useSelector((state) => state.fobPrice);
  const quality = useSelector((state) => state.quality);
  const rainforestPremium = useSelector((state) => state.rainforestPremium);
  const quantity = useSelector((state) => state.quantity);
  const purchaseGrade = useSelector((state) => state.purchaseGrade);
  const purchasePrice = useSelector((state) => state.purchasePrice);
  const outTurn = useSelector((state) => state.outTurn);
  const income_other_grades = useSelector((state) => state.income_other_grades); 
  const [contractDateError, setContractDateError] = useState(false);
  const [deliveryDateError, setDeliveryDateError] = useState(false);
  // contract details page
  const [fairTrade, setFairTrade] = useState(null);
  const [certificationType, setCertificationType] = useState([]);
  const handleChange = (event) => {
    const { checked, value } = event.currentTarget;

    setCertificationType((prev) => {
      if (checked) {
        if (value === "Not Certified") {
          return [value];
        } else if (!prev.includes("Not Certified")) {
          return [...prev, value];
        }
      } else {
        if (value === "Not Certified") {
          return [];
        }

        return prev.filter((val) => val !== value);
      }

      return prev;
    });
  };

  const [outputDetails, setOutputDetails] = useState([]);
  const [netSalesPrice, setNetSalesPrice] = useState(null);
  const [finalPriceUgx, setFinalPriceUgx] = useState(null);
  const [target, setTarget] = useState(null);
  const [step1Valid, setStep1Valid] = useState(false);
  const [step2Valid, setStep2Valid] = useState(false);
  const [step3Valid, setStep3Valid] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const handleAlertCancel = () => {
    setShowAlert(false);
  };
  const CustomAlert = ({ message, onCancel }) => {
    return (
      <div style={styles.overlay}>
        <div style={styles.alert}>
          <p>{message}</p>
          <button style={styles.okButton} onClick={onCancel}>
            Ok
          </button>
        </div>
      </div>
    );
  };
  const handleContinue = async (e, obj) => {
   
    if (stepper === 1) {
      if(contractDateError || deliveryDateError)
      {
        setAlertMessage('Please enter correct dates!');
        setShowAlert(true);
      }
      else if (coffeeType && saleType && salesGrade && buyerName && contractNo && contractType && contractDate && deliveryDate && certificationType) {
        if (
          certificationType.includes("Certified-Fair Trade") &&
          !fairTrade
        ) {
          setAlertMessage('Please fill all the fields to continue, including Fair Trade');
          setShowAlert(true);
        } else {
          // Call the API to check if the contract number exists
          const {
            accessToken: { jwtToken },
          } = await Auth.currentSession();
          const user = await Auth.currentAuthenticatedUser();
          const email = user.attributes.email;
          const response = await fetch(
            "/api/transaction/contractNumberExists?account_id="+ email+ "&contract_number=" +contractNo ,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          );
          if (response.status === 200) {
            const responseData = await response.json();
            if (responseData === 1) {
              setAlertMessage('Contract number already exists. Please enter a different one.');
              setShowAlert(true);
            } else {
              setStep1Valid(true);
              setStepper(2);
            }
          } else {
            setStep1Valid(true);
            setStepper(2);
          }
        }
      }
      else {
        setAlertMessage('Please fill all the fields to continue');
        setShowAlert(true);
      }
    } else if (stepper === 2 && step1Valid) {
      if (contractType === 'Open' && saleType === "Export") {
        if (quality && quantity && startDate && endDate && priceFixation) {
          setStep2Valid(true);
          setStepper(3);
        }
        else {
          setAlertMessage('Please fill all the fields to continue');
        setShowAlert(true);
        }
      }
      if (((contractType === 'Fixed' && (saleType === "Local" || saleType === "Export")) || (contractType === 'Open' && saleType === "Export")) && step1Valid) {
        if (quality && quantity) {
          setStep2Valid(true);
          setStepper(3);
        }
        else {
          setAlertMessage('Please fill all the fields to continue');
          setShowAlert(true);
        }
      }
    } else if (stepper === 3 && step2Valid) {

      if (purchaseGrade && purchasePrice && outTurn && income_other_grades) {
        setStep3Valid(true);
        setStepper(stepper + 1)

      } else {
        setAlertMessage('Please fill all the fields to continue');
        setShowAlert(true);
      }
    }
    else if (stepper > 3 && step3Valid) {
      setLoadingModal(true);
      setTimeout(() => {
        props.setInputModal(false);
        setStepper(1);
      }, 1000);
      setTimeout(() => {
        setLoadingModal(true);
        setOutputModal(true);
      }, 3000);
      handleOutput();
      setTimeout(() => {
        setLoadingModal(false);
        setOutputModal(true);
      }, 3000);
      //setCertificationType([]);
    }

  }
    ;
  const handleCreate = async (e, obj) => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      await fetch("/api/transaction/createTransaction", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          account_id: email,
          contract_date: contractDate,
          delivery_date: deliveryDate,
          contract_type: contractType,
          contract_number: contractNo,
          buyer_name: buyerName,
          quantity_value: quantity,
          purchase_price_value: purchasePrice,
          transaction_status: "Draft",
          sales_type: saleType,
          coffee_type: coffeeType,
          sales_grade: salesGrade,
          certification_type: certificationType,
          certified_fair_trade: fairTrade,
          price_fixation_period_type: priceFixation,
          price_fixation_start_date: startDate,
          price_fixation_end_date: endDate,
          quality_differential_value: quality,
          quality_differential_unit: "UGX/Kg",
          rainforest_premium: rainforestPremium,
          purchase_grade: purchaseGrade,
          outturn_percent: outTurn,
          offer_price_value: parseFloat(fobPriceValue),
          offer_price_unit: fobPrice,
          income_other_grades: parseFloat(income_other_grades),
          final_price_value:
            outputDetails["Table1"]["Final Price"].split(" ")[0],
          final_price_UGX:
            outputDetails["Table2"]["Final Price UGX"].split(" ")[0],
          variable_cost_export_value:
            outputDetails["Table2"]["Variable Cost Exports"].split(" ")[0],
          variable_cost_export_unit: "UGX/Kg",
          net_sales_price_value:
            outputDetails["Table2"]["Net Sales Price"].split(" ")[0],
          net_sales_price_unit: "UGX/Kg",
          variable_cost_other_value:
            outputDetails["Table3"]["Variable Cost Others"].split(" ")[0],
          variable_cost_other_unit: "UGX/Kg",
          purchase_price_after_outturn:
            outputDetails["Table3"]["Purchase Price After Outturn"].split(
              " "
            )[0],
          contribution: outputDetails["Table3"]["Contribution"].split(" ")[0],
          contribution_percent: outputDetails["Table3"]["Contribution%"],
          fixed_cost_value: outputDetails["Table4"]["Fixed Cost"].split(" ")[0],
          fixed_cost_unit: "UGX/Kg",
          net_profit_value: outputDetails["Table4"]["Profit Before Tax"].split(" ")[0],
          net_profit_unit: "UGX/Kg",
          net_profit_percent: outputDetails["Table4"]["Profit Before Tax%"],
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            fetch("/api/transaction/transactionTable?account_id=" + email, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                props.setTransactions(data);
                setOutputModal(false);
                Store.addNotification({
                  message: "Transaction was saved as a draft",
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  width: 200,
                  dismiss: {
                    duration: 2000,
                    onScreen: true,
                  },
                });
              });
          }
          else{
            alert("Error occured in Creating a transaction. Check your contract details!")
          }
        })
        .catch((error) => {
          alert("Error occured in Creating a transaction!")
        });
    }
    genToken();

  };
  const handleCancel = async (e, obj) => {
    toggleOutput();
  };
  const handleOutput = async (e, obj) => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      await fetch("/api/transaction/outputField", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          account_id: email,
          contract_date: contractDate,
          delivery_date: deliveryDate,
          contract_type: contractType,
          buyer_name: buyerName,
          quantity_value: parseFloat(quantity),
          purchase_price_value: parseFloat(purchasePrice),
          sales_type: saleType,
          coffee_type: coffeeType,
          sales_grade: salesGrade,
          certification_type: certificationType,
          certified_fair_trade: fairTrade,
          quality_differential_value: parseFloat(quality),
          quality_differential_unit: "UGX/Kg",
          purchase_grade: purchaseGrade,
          outturn_percent: parseFloat(outTurn),
          offer_price_value: parseFloat(fobPriceValue),
          offer_price_unit: fobPrice,
          income_other_grades: parseFloat(income_other_grades),
          rainforest_premium: parseFloat(rainforestPremium),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setOutputDetails(data);
          setNetSalesPrice(data["Table2"]["Net Sales Price"]);
          setTarget(data["Contribution"]["Contribution%"]);
          setFinalPriceUgx(data["Table2"]["Final Price UGX"]);
        })
        .catch((error) => {
          alert("Error occured !")
        });
    }
    genToken();

  };
  const handleBack = async (e, obj) => {
    setStepper(stepper - 1);
    if (stepper <= 1) {
      props.setInputModal(false);
      setStepper(1);
    }
  };
  return (
    <>
      <Modal
        isOpen={props.inputModal}
        toggle={props.toggleInput}
        centered={true}
        size="xl"
      >
        <ModalHeader toggle={props.toggleInput} className="news-heading">
          New Transaction
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <Progress step={stepper} />
          </div>
          {stepper === 1 ? (
            <ContractDetails
              coffeeType={coffeeType}
              contractNo={contractNo}
              saleType={saleType}
              salesGrade={salesGrade}
              buyerName={buyerName}
              contractDate={contractDate}
              deliveryDate={deliveryDate}
              contractType={contractType}
              handleChange={handleChange}
              certificationType={certificationType}
              setCertificationType={setCertificationType}
              setFairTrade={setFairTrade}
              contractDateError={contractDateError}
              setContractDateError={setContractDateError}
              deliveryDateError={deliveryDateError}
              setDeliveryDateError={setDeliveryDateError}
            />
          ) : stepper === 2 ? (
            <SalesPrice
              priceFixation={priceFixation}
              contractType={contractType}
              saleType={saleType}
              coffeeType={coffeeType}
              startDate={startDate}
              endDate={endDate}
              fobPrice={fobPrice}
              fobPriceValue={fobPriceValue}
              certificationType={certificationType}
              quality={quality}
              rainforestPremium={rainforestPremium}
              quantity={quantity}
            />
          ) : stepper === 3 ? (
            <PurchasePrice
              coffeeType={coffeeType}
              purchaseGrade={purchaseGrade}
              purchasePrice={purchasePrice}
              certificationType={certificationType}
              outTurn={outTurn}
              income_other_grades ={income_other_grades}
            />
          ) : (
            <>
              <div className="row justify-content-center mt-2">
                <div className="col-md-3">
                  <h5>Would you like to continue?</h5>
                </div>
              </div>
            </>
          )}
        </ModalBody>
        <div className="row justify-content-end mb-3">
          <div className="col-md-3">
            <div className="row">
              <div className="col-md-4">
                <Button color="secondary" onClick={handleBack}>
                  Back
                </Button>{" "}
              </div>
              <div className="col-md-6">
                <Button color="primary" onClick={handleContinue}>
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <LoadingModal
        loadingModal={loadingModal}
        toggleLoading={toggleLoading}
        setLoadingModal={setLoadingModal}
        setOutputModal={setOutputModal}
      />
      <OutputModal
        outputModal={outputModal}
        toggleOutput={toggleOutput}
        setOutputModal={setOutputModal}
        handleCancel={handleCancel}
        handleCreate={handleCreate}
        outputDetails={outputDetails}
        outTurn={outTurn ? outTurn.split(" ")[0] : ""}
        netSalesPrice={netSalesPrice ? netSalesPrice.split(" ")[0] : ""}
        target={target}
        sales_type={saleType}
        final_price_UGX={finalPriceUgx ? finalPriceUgx.split(" ")[0] : ""}
        setTarget={setTarget}
      />
       {showAlert && (
        <CustomAlert message={alertMessage} onCancel={handleAlertCancel} />
      )}
    </>
  );
};
const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,  
  },
  alert: {
    background: '#fff',
    padding: '30px',
    borderRadius: '8px',
    textAlign: 'center',
    zIndex: 10000,  
  },
  okButton: {
    background: '#4A42A0',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};
