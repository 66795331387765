import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Card, CardTitle, CardBody } from "reactstrap";
import HorizontalBar from "./HorizontalBar";

export const TopBuyers = () => {
  const [getTopBuyers, setGetTopBuyers] = useState(null);
  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      fetch("/api/home/getTopBuyers?account_id="+email, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setGetTopBuyers(data);
          if(data.message === "No transactions found for the specified account")
          setGetTopBuyers(null);
             else
             setGetTopBuyers(data);

        })
    }
    genToken();
  }, []);
  return (
    <>
    <Card className="shadow">
            <CardBody>
              <CardTitle
                style={{ textAlign: "left" }}
                className="card-title-prices"
                data-testid="topbuyertext"
              >
               YTD Sales of Top Customers (UGX Mn)
              </CardTitle>
             <HorizontalBar data={getTopBuyers}/>
            </CardBody>
          </Card>
      
     
    </>
  );
};
