import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { withStyles } from '@mui/styles';
import { Auth } from "aws-amplify";
import { useEffect } from 'react';


const PurpleSlider = withStyles({
  root: {
    color: '#4C44D8',
  },
  thumb: {
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    height: 8,
  },
  rail: {
    height: 4,
    color: '#D9D9D9'
  },
})(Slider);



export default function PopupSlider(props) {
  const [value, setValue] = React.useState(null);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    props.handleSimulatedPrice(newValue)
  };

  useEffect(() => {

    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      fetch("/api/risk/monitor/simulatedprice?account_id=" + email + "&contract_number=" + props.contractNumber, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          //console.log(data)
          setValue(data.simulated_price);
          props.handleSimulatedPrice(data.simulated_price)
        })
        .catch(() => {
          alert("Error occured in simulated price")
        });
    }
    genToken();
// eslint-disable-next-line
  }, [props.contractNumber])

  return (
    <Box sx={{}}>
      <div className="row" style={{ fontFamily: 'Inter', }}>
        <div className={props.coffee_type === "Arabica" ? "col-md-6" : "col-md-7"} style={{ textAlign: "left", fontSize: '16px', fontWeight: 700 }}>
          Simulated Price
        </div>
        <div className={props.coffee_type === "Arabica" ? "col-md-6" : "col-md-5"} style={{ fontSize: '22px', color: "#4A42A0", fontWeight: 700 }}>
          {value}{props.coffee_type === "Arabica" ? (" Cents/Pound") : (" USD/kg")}
        </div>
      </div>
      <div style={{ margin: '20px', width: '400px' }}>
        <PurpleSlider
          value={typeof value === 'number' ? value : 0}
          onChange={handleSliderChange}
          step={props.coffee_type === "Arabica" ? (1) : (0.1)} // Change step according to your preference
          min={0}
          max={props.coffee_type === "Arabica" ? (300) : (10)}
          valueLabelDisplay="auto"
        />
      </div>
    </Box>
  );
}