import React from "react";

export const MonitorTab = () => {
    const cardData = [
        {
          title: 'Contracts',
          subtext: 'Only open contracts from module 2 are reflected here',
        }, 
        {
          title: 'Total Contract Value',
          subtext: 'Total value of the coffee beans trading contract, calculated by multiplying the sales price per kilogram by the quantity in kilograms.',
        },
        {
          title: 'Breakeven price',
          subtext: 'Price at which the coffee beans need to be sold in order to cover all costs (fixed and variable) and achieve zero Net Profit',
        },
        
        {
           title: 'Current price',
           subtext: 'Price offered for the particular contract.',
        },
        {
            title: 'Current contract value',
            subtext: 'Total value of the coffee beans specified in the contract based on the current price and the quantity in kilograms.',
         },
        {
            title: 'Simulation',
            subtext:  'The benefit of hedging via Futures contracts maybe simulated under different future price scenarios as desired by the user. Simulation is shown for Robusta contracts greater than 10000kg and Arabica contracts greater than 37500 pounds .',
        },
        {
            title: 'Historical Voltaility',
            subtext: 'Volatility of one year is based on the Standard Deviation of 3 month rolling returns for that year.  Volatility is averaged for years 2022,2021 and 2020',
        },
        {
            title: 'Simulated price',
            subtext: 'Projected spot price of coffee based on current price multiplied by historical volatility.Expressed in USD/Kg for Robusta and Cents/Pound for Arabica'
        },
        {
            title: 'Minimum hedgeable quantity',
            subtext: 'Minimum quantity of coffee that could be Hedged as per ICE norms. Robusta 10 Tons and Arabica 37500 Pounds.Source ICE',
        },
        {
            title: ' Hedge value',
            subtext: ' The projected financial worth of the contract , when futures price is taken into consideration.Expressed in USD',
        },
        {
            title: 'Initial Margin',
            subtext: 'Initial amount of money required to be deposited with the exchange on executing a futures contract.The minimum contract size for robusta is 10 MT and Arabica is 37500 Pounds Source: ICE. Expressed in USD',
        },
        {
            title: 'Total cost of hedging',
            subtext: 'Sum total of the cost of borrowing the initial margin and transaction charges paid to broker',
        },

        {
            title: 'Cost of Margin Finance',
            subtext: 'Cost of borrowing the initial margin as per ICE Futures robusta and Arabica contract-based price. If a co-operative does not need to borrow, this may be 0 or reflect the opportunity cost of capital. The cost of margin finance is for a standard contract size defined by ICE for Robusta and Arabica. Expressed in USD',
          },
          {
            title: 'Transaction charges',
            subtext: 'Fees or costs associated with executing trades on Inter Continental exchange (ICE)for a minimum contract size. These charges can include brokerage fees, commissions, exchange fees, clearing fees, or any other costs incurred when entering into or closing out hedging positions.Expressed in USD',
          },
          {
            title: 'Interest rate',
            subtext: 'Cost of borrowing funds for depositing margin with the exchange',
          },
          {
            title: 'Total cost of hedging',
            subtext: 'Sum of all expenditures incurred for hedging a minimum contract of coffee. Expressed in USD',
          },
          {
            title: 'Value in case not hedged',
            subtext: 'Earnings generated form this contract in case if it was not hedged. Expressed in USD',
          },
          {
            title: 'Value of Hedged +Unhedged ',
            subtext: 'combined sum of earnings derived from both the hedged portion, which involves the minimum contract size subjected to hedging, and the earnings generated from the remaining quantity that was not included in the hedging. Expressed in USD ',
          },
          {
            title: 'Net gain/(loss) by hedging',
            subtext: 'Net financial gain or loss, considering the earnings generated from the hedging strategy and the total cost associated with implementing the hedge in the simulation. This value is then subtracted from the potential earnings that would have been achieved had the contract not been hedged.Expressed in USD',
         },


      ];
  return (
    <>
      <div className="row mt-4 ">
      {cardData.map((card, index) => (
      <div className="col-md-11 " style={{border:'1px solid #E0E0E0',borderRadius:'8px',textAlign: "left", padding:'12px 16px', marginBottom:'10px', marginLeft:'10px'}}>
      <h3 
      style={{
        fontFamily:'Inter',
        fontSize:'16px',
        fontStyle: 'normal',
        fontWeight:700,
        lineHeight:'20px',
        letterSpacing:'-0.5px'
       }}>
        {card.title}</h3>
       <p 
       style={{
        fontFamily:'Inter',
        fontSize:'14px',
        fontStyle: 'normal',
        fontWeight:500,
        lineHeight:'20px',
        letterSpacing:'-0.2px',
        color:'#575757'
       }}>{card.subtext}</p>
      </div>
      ))}
        </div>
    
     
    </>
  );
};
