import React, { useState, useEffect } from "react";
import { Card, CardTitle, CardSubtitle, CardBody } from "reactstrap";
import { FiDownload } from "react-icons/fi";
import { MaterialReactTable } from "material-react-table";
import { Chart } from "./Chart";
import { Box, IconButton } from "@mui/material";
import { BsGraphUpArrow } from "react-icons/bs";
import "../../../styles.css";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";

export const UCDA = (props) => {
  const [ucdaGraph, setUCDAGraph] = useState([]);
  const [ucdaTable, setUCDATable] = useState([]);
  const [ucdacsvTable, setUCDACsvTable] = useState([]);
  const [coffeeGrade, setCoffeeGrade] = useState('Screen 12');
  const [stats, setStats] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [name] = useState('UCDA');
  const firstCoffeeGrade = { 'value': 'Screen 12' };
  const columns = [
    {
      header: "Grade",
      accessorKey: "coffee",
      size: 50,
    },
    {
      header: "Price",
      accessorKey: "price",
      size: 50,
    },
    {
      header: "1D% Change",
      accessorKey: "1day%",
      size: 50,
    },
    {
      header: "15D% Change",
      accessorKey: "15day%",
      size: 20,
    },

  ];

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      if (props.coffeeType === "Robusta")
        setCoffeeGrade('Screen 12')
      if (props.coffeeType === "Arabica")
        setCoffeeGrade('Bugisu PB')

      fetch(
        "/api/market/prices/UCDAExportTable?coffee_type=" + props.coffeeType,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setUCDATable(data["result"]);
          setLastUpdated(data["Updated On"]);
          setStats(data["result"][0]["stats"])
          const newArray = data['result'].map((obj) => {
            // Create a copy of the original object without the specified key
            const newObj = { ...obj };
            delete newObj["stats"];
            return newObj;
          });
          setUCDACsvTable(newArray)
        });
      if (props.coffeeType === "Robusta")
        firstCoffeeGrade.value = "Screen 12";
      if (props.coffeeType === "Arabica")
        firstCoffeeGrade.value = "Bugisu PB";

      fetch("/api/market/prices/UCDAExportGraph?coffee_grade=" + firstCoffeeGrade.value, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUCDAGraph(data);
      })
      .catch(()=>{
        setUCDAGraph([]);
      })
    }
    genToken();
  // eslint-disable-next-line
  }, [props.coffeeType])


  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();


      if (props.coffeeType === "Robusta")
        firstCoffeeGrade.value = "Screen 12";
      if (props.coffeeType === "Arabica")
        firstCoffeeGrade.value = "Bugisu PB";

      fetch("/api/market/prices/UCDAExportGraph?coffee_grade=" + firstCoffeeGrade.value, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUCDAGraph(data);
        });

      fetch("/api/market/prices/UCDAExportGraph?coffee_grade=" + coffeeGrade, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUCDAGraph(data);
        })
        .catch(() => {
          setUCDAGraph([])
          alert("Error occured UCDAExport Graph")
      });

    }
    genToken();
    // eslint-disable-next-line
  }, [coffeeGrade, props.coffeeType]);


  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <Card className="shadow">
            <CardBody>
              <CardTitle className="card-title-prices">
                <div className="row">
                  <div className="col-md-10" style={{ textAlign: "left" }}>
                    UCDA Exports
                    <div className="row">
                      <div className="col-md-10 grey-text">US $ cts/Lb</div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <CSVLink data={ucdacsvTable} filename={"UCDA_Exports.csv"}>
                      <FiDownload style={{ color: "#4C44D8" }} />
                    </CSVLink>
                  </div>
                </div>
              </CardTitle>
              <MaterialReactTable
                columns={columns}
                data={ucdaTable}
                enableRowSelection={false}
                enableColumnOrdering={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnFilters={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableRowActions
                positionActionsColumn={"last"}
                initialState={{
                  density: "compact",
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "20px",
                    letterSpacing: "-0.5px",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    zIndex: "auto",
                    color: "#4C44D8",

                    /* inter/semibold/16 */
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    letterSpacing: "-0.5px",
                  },
                }}
                muiTablePaperProps={{
                  elevation: 0, //change the mui box shadow
                }}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "Chart", //change header text
                  },
                }}
                renderRowActions={({ row }) => (
                  <Box>
                    <IconButton
                      onClick={() => {
                        setCoffeeGrade(row["original"]["coffee"]);
                        setStats(row["original"]["stats"]);
                      }}
                    >
                      <BsGraphUpArrow size={15} />
                    </IconButton>
                  </Box>
                )}
              />
              <CardSubtitle className="time-text mt-2">
                <div className="row">
                  <div className="col-md-10">Updated on {lastUpdated}</div>
                </div>
              </CardSubtitle>
            </CardBody>
          </Card>
        </div>
        <div className="col-md-6">
          <Card className="shadow">
            <CardBody>
              <CardTitle
                style={{ textAlign: "left" }}
                className="card-title-prices"
              >
                <div className="row">
                  <div className="col-md-9" style={{ textAlign: "left" }}>
                    UCDA Export Prices
                  </div>
                  {stats["current"] ? (
                    <div className="col-md-3 big-number">
                      {stats["current"]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </CardTitle>
              <CardSubtitle
                className="mb-2 text-muted"
                tag="h6"
                style={{ textAlign: "left" }}
              >
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-10 light-text">US Cent Per Lb</div>
                    </div>
                    <div className="row">
                      <div className="col-md-10 grey-text">{coffeeGrade}</div>
                    </div>
                  </div>
                  {stats["change"] !== null || stats["1day%"] !== null ? (
                    <>
                      <div className="col-md-3">
                        <div className="row justify-content-left">
                          <div className="col-md-11 light-text">
                            <span
                              style={{
                                color:
                                  parseFloat(stats["change"]) >= 0
                                    ? "#4C9A4E"
                                    : "#C90000",
                              }}
                            >
                              {parseFloat(stats["change"]) >= 0 ? "+" : ""}
                              {stats["change"]}
                            </span>
                            <span
                              style={{
                                color:
                                  parseFloat(stats["1day%"]) >= 0
                                    ? "#4C9A4E"
                                    : "#C90000",
                              }}
                            >
                              ({parseFloat(stats["1day%"]) >= 0 ? "+" : ""}
                              {stats["1day%"]}%){" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </CardSubtitle>
              <Chart data={ucdaGraph} name={name} coffeeType={props.coffeeType}/>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};
