import React, { useState,useEffect} from "react";
import { FiDownload, FiFilter } from "react-icons/fi";
import "react-notifications-component/dist/theme.css";
import { CSVLink } from "react-csv";
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import "animate.css";
import RiskPositionTable from "./RiskPoistionTable";
import { Auth, Analytics } from "aws-amplify";

export const RiskPosition = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [filter, setFilter] = React.useState('All');
  const [riskPositionData, setRiskPositionData] = React.useState([]);

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    async function genToken() {
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      Analytics.autoTrack('session', {
        // REQUIRED, turn on/off the auto tracking
        enable: true,
        // OPTIONAL, the attributes of the event, you can either pass an object or a function
        // which allows you to define dynamic attributes
        attributes: {
          email: email,
          page: 'Risk_Position'
        },
      });
    }
    genToken()
  }, [])

  return (
    <>
      <div className="row mt-5 justify-content-end mb-2">
        <div className="col-md-11">
          <div className="row">
            <div className="col-md-9 mb-4" style={{ textAlign: "left" }}>
              <h5
                style={{
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "28px",
                  letterSpacing: "-0.5px",
                }}
              >
                Risk Position
              </h5>
            </div>
            <div className="col-md-2">
              <div className="row">
                <div className="col mt-1">
                  <CSVLink data={riskPositionData} filename={"Risk Position.csv"}>
                    <FiDownload size={20} style={{ color: "#4A42A0" }} />
                  </CSVLink>
                </div>
                <div className="col mt-1" style={{ color: "#4A42A0" }} onClick={toggleDrawer} >
                  <FiFilter size={20} style={{ color: "#4A42A0" }} data-testid="filterButton" /> Filter
                  <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
                    <div style={{ width: 280 }}>
                      <List>
                        <ListItem onClick={toggleDrawer} data-testid="applyFilterButton">
                          <ListItemText primary="Filters" />
                        </ListItem>
                        <ListItem>Coffee type</ListItem>
                        <ListItem onClick={toggleDrawer}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={filter}
                              label="Select"
                              onChange={handleChange}>
                              <MenuItem value={"Robusta"}>Robusta</MenuItem>
                              <MenuItem value={"Arabica"}>Arabica</MenuItem>
                              <MenuItem value={"All"}>All</MenuItem>
                            </Select>
                          </FormControl>
                        </ListItem>

                      </List>
                    </div>
                  </Drawer>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 justify-content-center">
            <RiskPositionTable filter={filter} riskPositionData={e => setRiskPositionData(e)} />
          </div>
        </div>

      </div>

    </>
  );
};
