import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const ColumnChart = (props) => {
  let categories = [];
  let final = [];
  let colors = ["#D09FE6", "#8F7BE0", "#F89570", "#79C7E3"]; // Define colors for each bar

  for (let i in props.data) {
    categories.push(i);

    let seriesName = ""; 

    if (i === "ICE Future Price") {
      seriesName = props.data[i].Month;
    } else if (i === "UCDA Price") {
      seriesName = props.data[i]["Grade Name"];
    } else {
      seriesName = ""; }

    final.push({
      name: seriesName, 
      y: i === "ICE Future Price" || i === "UCDA Price" ? props.data[i].Price : props.data[i],
      color: colors[final.length],
    });
  }
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      accessibility: {
        description: "Prices",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "UGX/Kg",
      },
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.x}</b><br>${this.series.name}  ${this.point.name}: ${this.y} (UGX/Kg)<br>`;
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "", 
        showInLegend: false,
        data: final,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
