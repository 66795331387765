import React ,{useState}from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Prices } from './Prices';
import { Production } from './Production';
import { OutputTerm } from './OutputTerm';
import { Contracts } from './Contracts';
import { CostMaster } from './CostMaster';
import { MonitorTab } from './MonitorTab';
import { RiskPositionTab } from './RiskPositionTab';
import { HomePage } from './HomePage';
const AntTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
      backgroundColor: '#4C44D8',
    },
  });
  
  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: 600,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: 'Inter',

    '&.Mui-selected': {
      color: '#4C44D8',
      fontWeight:700,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }));
  
function NestedTbs() {
  const [parentTabValue, setParentTabValue] = useState(0);
  const [childTabValue, setChildTabValue] = useState(0);

  const handleParentTabChange = (event, newValue) => {
    setParentTabValue(newValue);
    setChildTabValue(0);
  };

  const handleChildTabChange = (event, newValue) => {
    setChildTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <AntTabs value={parentTabValue} onChange={handleParentTabChange} >
        <AntTab label="Home" />
        <AntTab label="Market" />
        <AntTab label="Transaction" />
        <AntTab label="Risks" />

      </AntTabs>
      {parentTabValue === 0 && (
        <div>
        <HomePage/>
        </div>
      )}
      {parentTabValue === 1 && (
        <div>
          <AntTabs value={childTabValue} onChange={handleChildTabChange} >
            <AntTab label="Prices" />
            <AntTab label="Production" />
          </AntTabs>
          {childTabValue === 0 && <Prices/>}
          {childTabValue === 1 && <Production/>}
        </div>
      )}
      {parentTabValue === 2 && (
        <div>
          <AntTabs value={childTabValue} onChange={handleChildTabChange} >
            <AntTab label="Contract" />
            <AntTab label="Output Term" />
            <AntTab label="CostMaster" />


          </AntTabs>
          {childTabValue === 0 && <Contracts/>}
          {childTabValue === 1 && <OutputTerm/>}
          {childTabValue === 2 && <CostMaster/>}
        
        </div>
      )}
        {parentTabValue === 3 && (
        <div>
          <AntTabs value={childTabValue} onChange={handleChildTabChange} >
            <AntTab label="Monitor" />
            <AntTab label="Risk Position" />
          </AntTabs>
          {childTabValue === 0 && <MonitorTab/>}
          {childTabValue === 1 && <RiskPositionTab/>}
        </div>
      )}
    </Box>
  );
}

export default NestedTbs;
