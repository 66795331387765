import React, { useEffect, useState } from "react";
import { Card, CardTitle, CardBody, CardSubtitle } from "reactstrap";
import "../../../styles.css";
import { Auth } from "aws-amplify";

export const FairTrade = (props) => {
  const [fairTradePremium, setFairTradePremium] = useState([]);
  const [fairTradeMin, setFairTradeMin] = useState([]);

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      fetch(
        "/api/market/prices/fairTradePremium?coffee_type=" + props.coffeeType,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setFairTradePremium(data);
        });
      fetch(
        "/api/market/prices/fairTradeMinPrice?coffee_type=" + props.coffeeType,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setFairTradeMin(data);
        })
        .catch((error) => {
          alert("Error occured in Fair Trade")
          setFairTradeMin(null);
      });
    }
    genToken();
  }, [props.coffeeType]);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <Card className="shadow">
            <CardBody>
              <CardTitle className="card-title-prices">
                <div className="row">
                  <div className="col-md-10" style={{ textAlign: "left" }}>
                    Fairtrade Minimum Price
                    <div className="row">
                      <div className="col-md-10 grey-text">USD/Pound</div>
                    </div>
                  </div>
                </div>
              </CardTitle>
              <div className="row justify-content-center">
                {Object.entries(fairTradeMin).map(([key, value]) => (
                    <div className="col-md-4" key={key}>
                      <Card
                        className="shadow"
                        style={{
                          borderColor: "#f5f5f5",
                          borderRadius: "10px",
                          width: "180px",
                          justifyContent: "center",
                          display: "inline-flex",
                        }}
                      >
                        <CardTitle
                          style={{
                            color: "#4C44D8",
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontStyle: "normal",
                            letterSpacing: "-0.5px",
                          }}
                        >
                          {key}
                        </CardTitle>
                        <span
                          className="big-number"
                          style={{ fontSize: "30px", fontWeight: "bold" }}
                        >
                          {value}
                        </span>
                      </Card>
                    </div>
                  
                ))}
              </div>
              <CardSubtitle className="time-text mt-2">
                <div className="row">
                  <div className="col-md-10">Effective from August 2023</div>
                </div>
              </CardSubtitle>
            </CardBody>
          </Card>
        </div>
        <div className="col-md-6">
          <Card className="shadow">
            <CardBody>
              <CardTitle className="card-title-prices">
                <div className="row">
                  <div className="col-md-10" style={{ textAlign: "left" }}>
                    Fairtrade Premium
                    <div className="row">
                      <div className="col-md-10 grey-text">USD/Pound</div>
                    </div>
                  </div>
                </div>
              </CardTitle>
              <div className="row justify-content-center">
                {Object.entries(fairTradePremium).map(([key, value]) => (
                  
                    <div className="col-md-4" key={key}>
                      <Card
                        className="shadow"
                        style={{
                          borderColor: "#f5f5f5",
                          borderRadius: "10px",
                          width: "180px",
                          justifyContent: "center",
                          display: "inline-flex",
                        }}
                      >
                        <CardTitle
                        
                          style={{
                            color: "#4C44D8",
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontStyle: "normal",
                            letterSpacing: "-0.5px",
                          }}
                        >
                          {key}
                        </CardTitle>
                        <span
                        
                          className="big-number"
                          style={{ fontSize: "30px", fontWeight: "bold" }}
                        >
                          {value}
                        </span>
                      </Card>
                    </div>
                  
                ))}
              </div>

              <CardSubtitle className="time-text mt-2">
                <div className="row">
                  <div className="col-md-10">Effective from August 2023</div>
                </div>
              </CardSubtitle>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};
