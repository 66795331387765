import React from "react";

export const Production = () => {
    const ProductionData = [
        {
          title: 'Harvest Calender',
          subtext: 'The timeline that outlines the expected harvest periods for Coffee throughout the year for the top 5 competing countries',
        },
        {
          title: 'Main Crop',
          subtext: ' Major crop that is cultivated during the main growing season and typically accounts for the largest portion of the harvest. ',
        },
        {
          title: 'Fly Crop',
          subtext: 'Smaller secondary harvest that occurs outside of the primary harvest season',
        },
        {
          title: 'Forecasted production',
          subtext: ' Prediction of the quantity of Coffee that will be produced within a season- source USDA . Expressed in Million 60 kg Bags ',
        },
        {
           title: 'Percentage change over lastyear',
           subtext: 'Change over this years prediction and last years production expressed as a percentage',
        },
        {
            title: 'Weather forecast',
            subtext: '7 day weather forecast -(Temperature& Rainfall) forecast of all major coffee growing districts of Uganda from rainfall- chirps GEFS (Global Ensemble Forecast System), Temperature- GFS: (Global Forecast System)',
        }
      ];
  return (
    <>
      <div className="row mt-4 ">
      {ProductionData.map((card, index) => (
      <div className="col-md-11 " style={{border:'1px solid #E0E0E0',borderRadius:'8px',textAlign: "left", padding:'12px 16px', marginBottom:'10px', marginLeft:'10px'}}>
      <h3 
      style={{
        fontFamily:'Inter',
        fontSize:'16px',
        fontStyle: 'normal',
        fontWeight:700,
        lineHeight:'20px',
        letterSpacing:'-0.5px'
       }}>
        {card.title}</h3>
       <p 
       style={{
        fontFamily:'Inter',
        fontSize:'14px',
        fontStyle: 'normal',
        fontWeight:500,
        lineHeight:'20px',
        letterSpacing:'-0.2px',
        color:'#575757'
       }}>{card.subtext}</p>
      </div>
      ))}
        </div>
    
     
    </>
  );
};
