import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";

HighchartsExporting(Highcharts);

const HorizontalBar = (props) => {
  if (!props.data || typeof props.data !== "object") {
    return <div>No data available</div>;
  }
  const { data } = props;

  const categories = Object.keys(data);
  const values = Object.values(data);

  const options = {
    chart: {
      type: "bar",
      height:300
    },
    title: {
      text: " ",
    },
    xAxis: {
      categories: categories, 
      title: {
        text: "",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    credits: {
      enabled: false,
    },
    legend:{
      enabled: false,
    },
    series: [
      {
        name: "",
        data: values, 
        color: '#4A42A0',
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default HorizontalBar;
