import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { NewsCards } from "./NewsCards";

export const AllModal = (props) => {
  return (
    <Modal
      isOpen={props.allModal}
      toggle={props.toggleAll}
      centered={true}
      size="xl"
      className="mb-2"
    >
      <ModalHeader toggle={props.toggleAll} className="news-heading">
        News
      </ModalHeader>
      <ModalBody>
        <div className="row scroll">
          <div className="col-md-4">
            {props.data !== null ? (
              <NewsCards data={props.data.slice(0, 7)} />
            ) : (
              ""
            )}
          </div>
          <div className="col-md-4">
            {props.data !== null ? (
              <NewsCards data={props.data.slice(8, 14)} />
            ) : (
              ""
            )}
          </div>
          <div className="col-md-4">
            {props.data !== null ? (
              <NewsCards data={props.data.slice(14, 21)} />
            ) : (
              ""
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
