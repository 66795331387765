import React, { useMemo } from 'react';
import { useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Auth } from "aws-amplify";

const RiskPositionTable = (props) => {
  const [riskPosition, setRiskPosition] = useState([]);
  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      fetch(
        "/api/risk/position/positionTable?account_id=" + email + "&start_year=2023&end_year=2024&coffee_type=" + props.filter,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setRiskPosition(data);
          props.riskPositionData(data)
        })
        .catch(() => {
          setRiskPosition([])
      });
    }
    genToken();
   // eslint-disable-next-line
  }, [props.filter]);

  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorKey: 'Contract',
        header: 'Contract(All quantities in MT)',
        size: 130,

      },

      {
        accessorKey: 'Sep',
        header: 'Sep',
        size: 20
      },
      {
        accessorKey: 'Oct',
        header: 'Oct',
        size: 20
      },
      {
        accessorKey: 'Nov',
        header: 'Nov',
        size: 20
      },
      {
        accessorKey: 'Dec',
        header: 'Dec',
        size: 20
      },
      {
        accessorKey: 'Jan',
        header: 'Jan',
        size: 20
      },
      {
        accessorKey: 'Feb',
        header: 'Feb',
        size: 20
      },
      {
        accessorKey: 'Mar',
        header: 'Mar',
        size: 20
      },
      {
        accessorKey: 'Apr',
        header: 'Apr',
        size: 20
      },
      {
        accessorKey: 'May',
        header: 'May',
        size: 20
      },
      {
        accessorKey: 'Jun',
        header: 'Jun',
        size: 20
      },
      {
        accessorKey: 'Jul',
        header: 'Jul',
        size: 20
      },
      {
        accessorKey: 'Aug',
        header: 'Aug',
        size: 20
      },

    ],
    //end
    [],
  );

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={riskPosition}
        enableDensityToggle={false}
        enableRowSelection={false}
        enableFullScreenToggle={false}
        enableColumnFilters={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableRowActions={false}
        enableColumnActions={false}
        enableColumnOrdering={false}
        muiTableBodyCellProps={{
          sx: {
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "20px",
            letterSpacing: "-0.2px",
            height: '36px',
            borderBottom: 'none'
          },
        }}
        
        muiTableBodyRowProps={({ row }) => ({
          hover: false,
          sx: {
            backgroundColor: row.index === 5|| row.index === 9 || row.index === 12? '#E0E0E0' : '',
           }
        })}
        muiTableHeadCellProps={{
          sx: {
            zIndex: "auto",
            lineHeight: "20px",
            color: "#4C44D8",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "600",
            letterSpacing: "-0.2px",
            background: '#E5E5F2',
          },
        }}
        muiTableHeadRowProps={{
          sx: {
            background: '#E5E5F2',
          },
        }}
        initialState={{
          density: 'compact',
          pagination: { pageIndex: 0, pageSize: 15 },
        }}
      />
    </div>
  );
};

export default RiskPositionTable;
