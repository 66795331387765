import React from "react";
import { Label, Input, FormGroup } from "reactstrap";
import "../../transaction.css";
import { changeFobPrice, changeFobPriceValue, changePriceFixation, changeQuality, changeQuantity, changeRainforestPremium, changeendDate, changestartDate } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
export const SalesPrice = (props) => {
  const dispatch = useDispatch();
  const priceFixation = useSelector((state) => state.priceFixation); 
  const startDate= useSelector((state) => state.startDate); 
  const endDate= useSelector((state) => state.endDate); 
  const fobPriceValue= useSelector((state) => state.fobPriceValue); 
  const quality= useSelector((state) => state.quality); 
  const rainforestPremium= useSelector((state) => state.rainforestPremium); 
  const quantity= useSelector((state) => state.quantity); 
  const customStyles = {
    border:'2px solid #c6c6c6',
  };
  return (
    <>
       { props.contractType === "Open" && props.saleType === "Export"  ? (
        <>
      <div className="row">
        <div className="col-md-7 big-label">Sales Price</div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Label className="small-label"> Price Fixation Period</Label>
          <div className="row radio-text">
            {props.contractType === "Fixed" ? (
              <>
                <div className="col-md-4">
                  <FormGroup check>
                    <Input
                      name="fixation"
                      type="radio"
                      style={customStyles}
                      onClick={() =>
                        props.setPriceFixation("Price Fixation Period")
                      }
                    />{" "}
                    <Label check>Fixed pricing period</Label>
                  </FormGroup>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-4">
                  <FormGroup check>
                    <Input
                      name="fixation"
                      type="radio"
                      style={customStyles}
                      checked={priceFixation === 'Buyers Call'}
                      onClick={() => dispatch(changePriceFixation('Buyers Call'))  }
                    />{" "}
                    <Label check>Buyers Call</Label>
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup check>
                    <Input
                      name="fixation"
                      type="radio"
                      style={customStyles}
                      checked={priceFixation === 'Sellers Call'}
                      onClick={() => dispatch(changePriceFixation('Sellers Call'))  }
                    />{" "}
                    <Label check>Sellers Call</Label>
                  </FormGroup>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6">
          <FormGroup>
            <Label className="small-label">Start Date</Label>
            <Input
              id="exampleDate"
              name="date"
              style={{
                width: "70%",
                ...customStyles, 
              }}
              placeholder="date placeholder"
              type="date"
              onChange={(e) => {
                dispatch(changestartDate(e.target.value));
              }}
              value={startDate}
            />{" "}
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup>
            <Label className="small-label">End Date</Label>
            <Input
              id="exampleDate"
              name="date"
              style={{
                width: "70%",
                ...customStyles,
              }}
              placeholder="date placeholder"
              type="date"
              onChange={(e) => {
                dispatch(changeendDate(e.target.value));
              }}
              value={endDate}
            />{" "}
          </FormGroup>{" "}
        </div>
      </div>
      </>
      ) : (
        ""
      )}
      { (props.contractType === "Fixed" && props.saleType === "Export" )|| (props.saleType === "Local") ? (
      <>
      <div className="row mt-3">
        <div className="col-md-7 big-label">Offer Price (without premium)</div>
      </div>
    
          <div className="row">
            <div className="col-md-6">
              <FormGroup>
              { props.coffeeType === "Robusta" ? (
                <Label className="small-label">Value (USD/kg) </Label>
                
                ) : (
                  <Label className="small-label">Value (Cents/Pound) </Label>
                )}
                <Input
                  bsSize="sm"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  style={{
                    width: "70%",
                    ...customStyles, 
                  }}
                  value={fobPriceValue}
                  onChange={(e) => {
                    dispatch(changeFobPriceValue(e.target.value));
                    if (props.coffeeType === "Arabica")
                    dispatch(changeFobPrice("Cents/Pound"));
                    if (props.coffeeType === "Robusta")
                    dispatch(changeFobPrice("UGX/Kg"));
                  }}
                />
              </FormGroup>
            </div>
          </div>
      </>
      ) : (
        ""
      )}

      <div className="row mt-2">
        <div className="col-md-6">
          <FormGroup>
          { props.coffeeType === "Robusta" ? (
          <Label className="small-label">Quality differential per unit (USD/kg) </Label>
          ) : (
          <Label className="small-label">Quality differential per unit (Cents/Pound) </Label>
          )}
            <Input
              bsSize="sm"
              type="number"
              onWheel={(e) => e.target.blur()}
              style={{
                width: "70%",
                ...customStyles, 
              }}
              placeholder={
                props.coffeeType === "Robusta"
                  ? "Enter value in (USD/kg)"
                  : "Enter value in (Cents/Pound)"
              }
              value={quality}
              onChange={(e) => {
                dispatch(changeQuality(e.target.value));
              }}
            />
          </FormGroup>
        </div>
        {props.certificationType.includes("Certified Rainforest") ? (
        <div className="col-md-6">
          <FormGroup>
            <Label className="small-label">Rainforest premium</Label>
            <Input
              bsSize="sm"
              onWheel={(e) => e.target.blur()}
              type="number"
              style={{
                width: "70%",
                ...customStyles,
              }}
              placeholder={
                props.coffeeType === "Arabica"
                  ? "Enter value in Cents/Pound"
                  : "Enter value in Cents/kg"
              }
              value={rainforestPremium}
              onChange={(e) => {
                dispatch(changeRainforestPremium(e.target.value));
              }}
            />
          </FormGroup>{" "}
        </div>
          ) : (
            ""
          )}
      </div>
      <div className="row mt-2">
        <div className="col-md-6">
          <FormGroup>
            <Label className="small-label">Quantity</Label>
            <Input
              bsSize="sm"
              onWheel={(e) => e.target.blur()}
              type="number"
              style={{
                width: "70%",
                ...customStyles, 
              }}
              placeholder="Enter value in kgs"
              value={quantity}
              onChange={(e) => {
                const inputValue = e.target.value;
              if (/^\d*$/.test(inputValue)) { 
              dispatch(changeQuantity(inputValue));
              } else {
              alert("quantity should be in integer only")
              }
              }}
            />
          </FormGroup>
        </div>
      </div>
    </>
  );
};
