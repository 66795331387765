import React, { useState, useEffect } from "react";

import { Card, CardTitle, CardBody } from "reactstrap";
import { Auth } from "aws-amplify";
import Intersectiongraph from "./Intersectiongraph";

export const LineColumnChart = () => {
  const [monthlySalesQuantity, setMonthlySalesQuantity] = useState(null);
  const [monthlySalesValue, setmonthlySalesValue] = useState(null);
  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      fetch("api/home/monthlySalesQuantity?account_id="+email, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setMonthlySalesQuantity(data);
          //console.log(data)
          if(data.message === "No transactions found for the specified account")
              setMonthlySalesQuantity(null);
             else
             setMonthlySalesQuantity(data);

        });

        fetch("/api/home/monthlySalesValue?account_id="+email,  {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setmonthlySalesValue(data);
            if(data.message === "No transactions found for the specified account")
              setmonthlySalesValue(null);
             else
             setmonthlySalesValue(data);

          })
    }
    genToken();
  }, []);
  return (
    <>
     
          <Card className="shadow" data-testid="lineColumnChartCard">
            <CardBody>
              <CardTitle
                style={{ textAlign: "left" }}
                className="card-title-prices"
                data-testid="chartTitle"
              >
                <div className="row">
                  <div className="col-md-5" style={{ textAlign: "left" }}>
                  Monthly Sales Quantity vs Monthly Sales Value
                  </div>
                </div>
              </CardTitle>
              <Intersectiongraph
              monthlySalesQuantity={monthlySalesQuantity}
              monthlySalesValue={monthlySalesValue}
            /> 
            </CardBody>
          </Card>
      
     
     
    </>
  );
};
