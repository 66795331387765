import React, { useState, useEffect } from 'react';
import Ticker, { FinancialTicker } from 'nice-react-ticker';
import { Auth } from "aws-amplify";
import "../../../src/tickerComponent.css";

const TickerComponent = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    async function genToken() {
      try {
        const {
          accessToken: { jwtToken },
        } = await Auth.currentSession();

        const response = await fetch('/api/home/priceTicker', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        alert('Error in Prices data');
      }
    }

    genToken();
  }, []);
  const tickerData = Object.keys(data).map((key) => {
    return {
      id: key,
      symbol: key,
      lastPrice: data[key],
      change:true
    };
  });

  return (
    <div className='tickerclass'>
      
      <Ticker slideSpeed ={50} isNewsTicker={true} >
        {tickerData.map((ticker) => (
          <FinancialTicker
            key={ticker.id}
            {...ticker}
           
          />
        ))}
      </Ticker>
    </div>
  );
};

export default TickerComponent;