import React from "react";

export const Contracts = () => {
    const cardData = [
        {
          title: 'Type of sales',
          subtext: 'Coffee sales are categorized as local sales or export sales.',
        },
        {
          title: 'Open Contract',
          subtext: 'Agreement where a buyer commits to purchasing coffee in the future,even though the price has not been agreed upon at the time of signing. Open contracts are reference to the futures contract traded on ICE',
        },
        {
          title: 'Fixed Contract',
          subtext: 'Agreement between the buyer and seller where coffee prices are negotiated and mutually agreed upon and remain fixed throughout the duration of the contract.',
        },
        {
          title: 'Type of Contract',
          subtext: 'Coffee contract are classified as Fixed or Open contracts',
        },
        {
          title: 'Contract number',
          subtext: 'Unique identifier or reference number assigned to the specific contract. ',
        },
        {
           title: 'Contract date',
           subtext: 'The date on which the contract or agreement for the sale and purchase of coffee is entered into between the buyer and seller',
        },
        {
            title: 'Delivery date',
            subtext: ' The agreed  date on which the seller is obligated to deliver the specified quantity and quality of coffee to the buyer',
         },
        {
            title: 'Coffee Type',
            subtext: 'Coffee type is classified as Arabica or Robusta',
        },
        {
            title: 'Grade',
            subtext: ' Screen size they pass through during sorting, to categorize coffee beans based on their physical dimensions. The nomenclature differs between domestic and exports as well as Robusta & Arabica',
        },
        {
            title: 'Certification Type',
            subtext: 'The recognition the coffee producers, cooperatives, or companies have obtained for meeting specific standards and criteria related to sustainability, social responsibility, environmental practices, and/or quality assurance.'
        },
        {
            title: 'Not certified',
            subtext: "Traded coffee beans don't hold any kind of certifications provided by third-party certification agencies",
        },
        {
            title: 'Natural',
            subtext: 'Naturally processed coffee is sun-dried and hulled to get green beans ',
        },
        {
            title: 'Washed',
            subtext: 'Washed or wet processed coffee is Depulped, fermented and dried to obatin green beans',
        },
        //Sales Price
        {
            title: 'Price fixing period',
            subtext: 'Period of time during which sellers/buyers of futures contracts are required to establish the final price at which they will deliver the underlying asset to the buyers. ',
          },
          {
            title: 'Sellers Call',
            subtext: 'The seller has the right to determine the price at which the coffee will be sold within an agreed  timeframe-based on a pre-agreed formula?',
          },
          {
            title: 'Buyers Call',
            subtext: 'The buyer has right or option to decide the offer price and delivery time.',
          },
          {
            title: 'Offer price  (without premium)',
            subtext: 'The proposed or specified price at which a buyer is willing to buy coffee from the seller. The price to be entered here is without including any premiums.Expressed in USD/Kg for Robusta and Cents/Pound for Arabica',
          },
          {
            title: 'Quality differential per unit',
            subtext: 'Additional price offered by a buyer based on the quality for a particular export contract expressed in USD/Kg for Robsta & Cents/Pound for Arabica',
         },
         {
            title: 'Organic Premium',
            subtext: 'Additional price or premium paid for products that are certified organic set by Fairtrade . Expressed in USD/Pound. Source Fairtrade International',
         },
         {
            title: 'Rainforest Premium',
            subtext: 'Additional premium paid for rainforest certified coffee , negotiated mutually by buyer and seller as per the norms set by Rainforest Alliance. Expressed in Cents/Kg',
         },
        
         //Purchase price
         {
            title: 'Purchase grade',
            subtext: 'Grade of coffee beans purchased from farmers/aggregator . These grades vary between Arabica and Robusta.',
         },
         {
            title: 'Purchase price',
            subtext: 'Price at which a particular grade of coffee is bought from farmers/traders/aggregators in UGX/Kg',
         },

         {
            title: 'Out Turn %',
            subtext: 'Ratio of green coffee beans obtained from a given weight of harvested cherry or parchment coffee. The out-turn percentage is calculated by dividing the weight of the green coffee beans by the weight of the cherry or parchment coffee',
         },
         {
          title: 'Income  Other grades',
          subtext: 'Lumpsum amount of revenue that can be generated through sales of undergrades, that is generated from the processing of coffee procured for honuring this contract. Expressed in UGX',
       },

      ];
  return (
    <>
      <div className="row mt-4 ">
      {cardData.map((card, index) => (
      <div className="col-md-11 " style={{border:'1px solid #E0E0E0',borderRadius:'8px',textAlign: "left", padding:'12px 16px', marginBottom:'10px', marginLeft:'10px'}}>
      <h3 
      style={{
        fontFamily:'Inter',
        fontSize:'16px',
        fontStyle: 'normal',
        fontWeight:700,
        lineHeight:'20px',
        letterSpacing:'-0.5px'
       }}>
        {card.title}</h3>
       <p 
       style={{
        fontFamily:'Inter',
        fontSize:'14px',
        fontStyle: 'normal',
        fontWeight:500,
        lineHeight:'20px',
        letterSpacing:'-0.2px',
        color:'#575757'
       }}>{card.subtext}</p>
      </div>
      ))}
        </div>
    
     
    </>
  );
};
