export function changeSaleType(data) {
  return {
    type: "CHANGE_SALE_TYPE",
    payload: { saleType: data },
  };
}
export function changecoffeeType(data) {
  return {
    type: "CHANGE_COFFEE_TYPE",
    payload: { coffeeType: data },
  };
}
export function changebuyerName(data) {
  return {
    type: "CHANGE_BUYER_NAME",
    payload: { buyerName: data },
  };
}
export function changeContractType(data) {
  return {
    type: "CHANGE_CONTRACT_TYPE",
    payload: { contractType: data },
  };
}
export function changeContractNo(data) {
  return {
    type: "CHANGE_CONTRACT_NO",
    payload: { contractNo: data },
  };
}

export function changedeliveryDate(data) {
  return {
    type: "CHANGE_DELIVERY_DATE",
    payload: { deliveryDate: data },
  };
}
export function changecontractDate(data) {
  return {
    type: "CHANGE_CONTRACT_DATE",
    payload: { contractDate: data },
  };
}
export function changeSalesGrade(data) {
  return {
    type: "CHANGE_SALES_GRADE",
    payload: { salesGrade: data },
  };
}
//sales price
export function changestartDate(data) {
  return {
    type: "CHANGE_START_DATE",
    payload: { startDate: data },
  };
}
export function changeendDate(data) {
  return {
    type: "CHANGE_END_DATE",
    payload: { endDate: data },
  };
}
export function changePriceFixation(data) {
  return {
    type: "CHANGE_PRICE_FIXATION",
    payload: { priceFixation: data },
  };
}
export function changeFobPriceValue(data) {
  return {
    type: "CHANGE_FOB_PRICE_VALUE",
    payload: { fobPriceValue: data },
  };
}
export function changeFobPrice(data) {
  return {
    type: "CHANGE_FOB_PRICE",
    payload: { fobPrice: data },
  };
}
export function changeQuantity(data) {
  return {
    type: "CHANGE_QUANTITY",
    payload: { quantity: data },
  };
}
export function changeQuality(data) {
  return {
    type: "CHANGE_QUALITY",
    payload: { quality: data },
  };
}
export function changeRainforestPremium(data) {
  return {
    type: "CHANGE_RAINFORESTPREMIUM",
    payload: { rainforestPremium: data },
  };
}
export function changePurchaseGrade(data) {
  return {
    type: "CHANGE_PURCHASE_GRADE",
    payload: { purchaseGrade: data },
  };
}
export function changePurchasePrice(data) {
  return {
    type: "CHANGE_PURCHASE_PRICE",
    payload: { purchasePrice: data },
  };
}
export function changeoutTurn(data) {
  return {
    type: "CHANGE_OUT_TURN",
    payload: { outTurn: data },
  };
}
export function changeincome_other_grades(data) {
  return {
    type: "CHANGE_INCOME_OTHER_GRADES",
    payload: { income_other_grades: data },
  };
}