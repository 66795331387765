import React, { useEffect } from "react";
import { GanttChart } from "./GanttChart";
import { GanttChart2 } from "./GanttChart2";
import "../../../styles.css";
import UgandianClimate from "./UgandianClimate";
import { Auth, Analytics } from "aws-amplify";


export const Production = () => {

  useEffect(() => {
    async function genToken() {
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      Analytics.autoTrack('session', {
        // REQUIRED, turn on/off the auto tracking
        enable: true,
        // OPTIONAL, the attributes of the event, you can either pass an object or a function
        // which allows you to define dynamic attributes
        attributes: {
          email: email,
          page: 'Production'
        },
      });
    }
    genToken()
  }, [])
  return (
    <>
      <div className="row mt-5 justify-content-end mb-2 news-page">
        <div className="col-md-11">
        <div className="scrollable-content">
          <div className="row">
            <div className="col-md-6" style={{ display: 'flex', gap: '8px', textAlign: "left" }}>
              <h5
                style={{
                  fontFamily: "Inter",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "28px",
                  letterSpacing: "-0.5px",
                }}
              >
                Production
              </h5>
            </div>
            <div className="col-md-6">
              <div className="row justify-content-left card-title-prices">
                Production Forecast Data
              </div>
              <div
                className="row justify-content-left mt-2"
                style={{
                  overflow: "hidden",
                  color: "#4A42A0",
                  textAlign: "center",
                  textOverflow: "ellipsis",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "20px",
                  letterSpacing: "-0.2px",
                }}
              >
                <div
                  className="col-md-5"
                  style={{
                    border: "1px solid #E0E0E0",
                    borderTopLeftRadius: '8px',
                    background: '#E5E5F2',
                    padding: "5px"
                  }}
                >
                  World Coffee Production Forecast - 2023
                </div>
                <div
                  className="col-md-5"
                  style={{
                    border: "1px solid #E0E0E0",
                    borderTopRightRadius: '8px',
                    background: '#E5E5F2',
                    padding: "5px"
                  }}
                >
                  178.6 60kg Million Bags
                </div>
              </div>
              <div
                className="row justify-content-left"
                style={{
                  overflow: "hidden",
                  color: "#4A42A0",
                  textAlign: "center",
                  textOverflow: "ellipsis",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "20px",
                  letterSpacing: "-0.2px",
                }}
              >
                <div
                  className="col-md-5"
                  style={{
                    border: "1px solid #E0E0E0",
                    borderBottomLeftRadius: '8px',
                    background: '#E5E5F2',
                    padding: "5px"
                  }}
                >
                  World Coffee Production Forecast - 2022
                </div>
                <div
                  className="col-md-5"
                  style={{
                    border: "1px solid #E0E0E0",
                    borderBottomRightRadius: '8px',
                    background: '#E5E5F2',
                    padding: "5px"
                  }}
                >
                  174.3 60kg Million Bags
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <h5
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "-0.5px",
                textAlign: 'left'
              }}
            >
              Ugandan Weather Data
            </h5>
            <div className="col-md-11">
              <UgandianClimate />
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <h5
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "-0.5px",
                textAlign: 'left'
              }}
            >
              Arabica Harvest Calendar
            </h5>
            <div className="col-md-11">
              <GanttChart />
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <h5
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "-0.5px",
                textAlign: 'left'

              }}
            >
              Robusta Harvest Calendar
            </h5>
            <div className="col-md-11">
              <GanttChart2 />
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};
