import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const Piechart = (props) => {
    if (!props.data || typeof props.data !== "object") {
      return <div>No data available</div>;
    }
  
  const data = Object.entries(props.data).map(([name, value]) => ({
    name,
    y: parseFloat(value),
  }));

  const options = {
    chart: {
      type: "pie",
      height:300
    },
    title: {
      text: "",
    },
    plotOptions: {
      pie: {
        innerSize: "50%", 
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Sales grade",
        data: data,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
