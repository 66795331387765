import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Card, CardTitle, CardBody } from "reactstrap";
import { Piechart } from "./Piechart";

export const Salesgrade = () => {
  const [calculateShare, setCalculateShare] = useState(null);
  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      fetch("/api/home/calculateShare?account_id="+email, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setCalculateShare(data);
          //console.log(data,'api')
          if(data.message === "No transactions found for the specified account")
          setCalculateShare(null);
             else
             setCalculateShare(data);

        })
        
    }
    genToken();
  }, []);
  return (
    <>
    <Card className="shadow">
            <CardBody>
              <CardTitle
                style={{ textAlign: "left" }}
                className="card-title-prices"
                data-testid="salesgradetext"
              >
                 Sales Grade (%) 
              </CardTitle>
             <Piechart data={calculateShare} data-testid="piechart"/>
            </CardBody>
          </Card>
      
     
    </>
  );
};
