import React from "react";

export const CostMaster = () => {
    const cardData = [
      {
         title: 'Variable costs',
         subtext: ' Variable costs are bifurcated at four levels and are converted at the unit level. All Costs are expressed in UGX',
       },
        {
          title: 'Factory Level',
          subtext: 'Expenses incurred by a cooperative/SME for bringing the collected cherries from the collection point to factory and thereafter transporting to warehouse',
        },
        {
          title: 'Offloading & Weighing',
          subtext: 'Expenses associated with unloading coffee from the truck to the factory and weighing it. It includes all cost of transportation, labors and related costs',
        },
        {
          title: 'Milling & Grading',
          subtext: 'All the expenses incurred for processing cherries to green coffee viz- cleaning, washing, drying,hulling and grading',
        },
        {
          title: 'Loading to warehouse',
          subtext: 'Expenses incurred in packing and loading the green beans to warehouse',
        },
        {
           title: 'Local Transport',
           subtext: 'Expenses incurred in transporting the green beans to the warehouse viz- labour',
        },
        {
            title: 'Maintainence cost',
            subtext: ' All expenses incurred for mainaitenance of machines used',
         },
        {
            title: 'Others',
            subtext: 'All other costs incurred at the factory excluding the above mentioned',
        },
        {
            title: 'At Warehouse',
            subtext: 'Expenses incurred by cooperative/SME for storing the beans at warehouse which is ready for disposal, expressed in UGX',
        },
        {
            title: 'Offloading',
            subtext: 'Expenses incurred while unloading the beans recived at the warehouse from the factory'
        },
        {
            title: 'Handsorting',
            subtext: 'Expenses associated with hand sorting the green beans according to screen size /other parameters ',
        },
        {
            title: 'Packaging Cost',
            subtext: 'Expenses incurred in packaging the coffee beans before they are stored or shipped from the warehouse.Packaging costs include-cost of Jute bags, Stitching, Labeling/Printing bags,&Labor  ',
        },
        {
            title: 'Stacking Costs',
            subtext: 'Expenses incurred in arranging and organizing the bags or containers of coffee beans in a stack or pallet within the warehouse',
        },

        {
            title: 'Storage cost',
            subtext: 'Storage costs refer to the expenses incurred for storing coffee beans, in the warehouse viz- electricity, insurance, labour, security etc ',
          },
          {
            title: 'Others',
            subtext: 'All other costs incurred at the warehouse excluding the above mentioned',
          },
          {
            title: 'At Export',
            subtext: 'Expenses incurred in the process of exporting coffee from Uganda to the destination country.Expressed in UGX',
          },
          {
            title: 'Transport FOB - Mombasa',
            subtext: 'Expenses associated with transporting coffee beans, from the warehouse to the designated port of shipment- Mombasa.',
          },
          {
            title: 'Loading - Container ',
            subtext: 'Expenses associated with the process of loading packed coffee beans, into shipping containers for shipping.It includes labor, port handling charges, freight forward fees',
          },
          {
            title: 'Inspection by ucda',
            subtext: 'Fees associated with the inspection of packed coffee by  UCDA before exporting',
         },
         {
            title: 'Documentation - Clearing',
            subtext: 'Expenses associated with the preparation and processing of the necessary documents and customs procedures for exporting coffee from Uganda to other countries.',
         },
         {
            title: 'Export Clearance  &agent fees',
            subtext: 'Fees imposed by government authorities or regulatory bodies for the clearance of coffee for export and include the commission paid to the agent for exporting. .',
         },
         {
            title: 'Sampling &courier',
            subtext: ' Expenses associated with collecting and sending samples of coffee beans to potential buyers or importers.',
         },
         {
            title: 'PSI Services',
            subtext: ' Pre-Shipment Inspection (PSI) service for coffee exports is the fees paid to an independent inspection agency to verify the quantity, quality, and compliance of the coffee beans being exported before shipment.',
         },
         {
            title: 'Quality Control Costs',
            subtext: 'Expenses associated with inspecting, testing, and ensuring the quality of goods before they are shipped to international markets.',
         },
      
         {
            title: 'Insurance Costs',
            subtext: 'Expenses associated with insuring packed coffee bags at warehouse plus insurance expenses taken to protect the coffee beans while exporting to protect coffee bgas from various perils during transit.',
         },
         {
            title: 'Others',
            subtext: 'All other costs incurred for exports excluding the above mentioned',
         },
         {
            title: 'Total variable cost',
            subtext: 'Sum total of all costs expressed in UGX/Kg',
         },
         {
            title: 'Estimated annual fixed costs (Shillings)',
            subtext: 'Projected sum of all expenses that remain constant over a one-year period, regardless of fluctuations in production or sales volume',
         },
         {
            title: 'Estimated sales for the year (Kg)',
            subtext: 'Anticipated quantity of sales, measured in kilograms, expected to be achieved within a year.',
         },

      ];
  return (
    <>
      <div className="row mt-4 ">
      {cardData.map((card, index) => (
      <div className="col-md-11 " style={{border:'1px solid #E0E0E0',borderRadius:'8px',textAlign: "left", padding:'12px 16px', marginBottom:'10px', marginLeft:'10px'}}>
      <h3 
      style={{
        fontFamily:'Inter',
        fontSize:'16px',
        fontStyle: 'normal',
        fontWeight:700,
        lineHeight:'20px',
        letterSpacing:'-0.5px'
       }}>
        {card.title}</h3>
       <p 
       style={{
        fontFamily:'Inter',
        fontSize:'14px',
        fontStyle: 'normal',
        fontWeight:500,
        lineHeight:'20px',
        letterSpacing:'-0.2px',
        color:'#575757'
       }}>{card.subtext}</p>
      </div>
      ))}
        </div>
    
     
    </>
  );
};
