import React, { useState, useEffect } from "react";
import "../../../switch.css";
import { ICESection } from "./ICESection";
import { UCDA } from "./UCDA";
import { Shilling } from "./Shilling";
import { ExchangeRate } from "./ExchangeRate";
import { FairTrade } from "./FairTrade";
import { Auth, Analytics } from "aws-amplify";


export const Prices = () => {

  useEffect(() => {
    async function genToken() {
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      Analytics.autoTrack('session', {
        // REQUIRED, turn on/off the auto tracking
        enable: true,
        // OPTIONAL, the attributes of the event, you can either pass an object or a function
        // which allows you to define dynamic attributes
        attributes: {
          email: email,
          page: 'Prices'
        },
      });
    }
    genToken()
  }, [])

  const [coffeeType, setCoffeeType] = useState("Robusta");
  return (
    <>
      <div className="prices-container row mt-5 justify-content-end mb-2">
        <div className="col-md-11">
        <div className="scrollable-content">
          <div className="row">
            <div className="col-md-10" style={{ textAlign: "left" }}>
              <h5
                style={{
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "28px",
                  letterSpacing: "-0.5px",
                }}
              >
                Prices
              </h5>
            </div>
            <div className="col-md-2">
              <div className="switches-container">
                <input
                  type="radio"
                  id="switchRobusta"
                  name="switchPlan"
                  value="Robusta"
                  checked={coffeeType === "Robusta"}
                  onChange={() => setCoffeeType("Robusta")}
                />
                <input
                  type="radio"
                  id="switchArabica"
                  name="switchPlan"
                  value="Arabica"
                  checked={coffeeType === "Arabica"}
                  onChange={() => setCoffeeType("Arabica")}
                />
                <label htmlFor="switchRobusta">Robusta</label>
                <label htmlFor="switchArabica">Arabica</label>
                <div className="switch-wrapper">
                  <div className="switch">
                    <div>Robusta</div>
                    <div>Arabica</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="mt-2">
            <ICESection coffeeType={coffeeType} />
          </div>
          <div className="mt-4">
            <UCDA coffeeType={coffeeType} />
          </div>
          <div className="mt-4">
            <Shilling />
          </div>
          <div className="mt-4">
            <ExchangeRate />
          </div>
          <div className="mt-4">
            <FairTrade coffeeType={coffeeType} />
          </div>
          </div>
        </div>
      </div>
    </>
  );
};
