import React from "react";
import {FiArrowLeft } from "react-icons/fi";
import "react-notifications-component/dist/theme.css";
import "animate.css";
import DetailTable from "./DetailTable";

export const ContractDetails = (props) => {

  return (
    <>
      <div className="row mt-5 justify-content-end mb-2">
        <div className="col-md-11">
          <div className="row">
            <div className="col-md-9 mb-4" style={{ textAlign: "left" }}>
              <h5
                style={{
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "28px",
                  letterSpacing: "-0.5px",
                }}
              ><div onClick={props.handleBack}>
                  <FiArrowLeft size={30} style={{ marginRight: '5px' }} />
                  Contract Details
                </div>
              </h5>
            </div>
          </div>
          <div className="row mt-2 justify-content">
            <div className="col-md-9" style={{ textAlign: "left" }}>
              <h5
                style={{
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "28px",
                  letterSpacing: "-0.5px",
                }}
              >
                Summary
              </h5>
            </div>
          </div>
          <div className="container mt-10" style={{ border: '1px solid #E0E0E0', borderRadius: '8px', textAlign: "left", padding: '16px' }}>
            <h5
              style={{
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "28px",
                letterSpacing: "-0.5px",
                color: '#4C44D8'
              }}
            >
              {props.buyerName}
            </h5>
          
          </div>
          <div className="container mt-4" style={{ textAlign: "left", padding: '16px' }}>

            <DetailTable contractNumber={props.contractNumber} />
          </div>
        </div>

      </div>

    </>
  );
};
