import React from "react";

export const HomePage = () => {
    const cardData = [
        {
          title: 'Monthly Sales Quantity vs Monthly Sales Value Graph',
          subtext: 'Double-axis graph illustrates the relationship between the monthly volume of coffee sold (Quantity in MT) and the corresponding revenue generated (Sales in Million UGX). ',
        },
        {
          title: 'YTD Sales Quantity (MT)',
          subtext: 'Cumulative volume of products sold in metric tons till today. This data is based on the contracts you have entered in the transaction module and has a  delivered status',
        },
        {
          title: 'YTD Sales Values (UGX Mn)',
          subtext: 'Total monetary value in millions of Ugandan Shillings generated from those sales year-to-date.',
        },
        {
          title: 'Monthly Average ICE Price',
          subtext: 'Mean value of the Intercontinental Exchange (ICE) coffee futures prices over a specific month, serving as a benchmark for global coffee market trends.',
        }, 
        {
          title: 'Sales Grade (%)',
          subtext: 'Percentage distribution of coffee sales among different quality grades, indicating the relative proportion of each grade in the overall sales.',
        },
        {
          title: 'YTD Sales of Top Customers (UGX Mn)',
          subtext: 'Cumulative sales, measured in Ugandan Shillings (UGX) million, for the current year-to-date period, attributed to the top customers to whom coffee has been successfully sold.',
        },
       

      ];
  return (
    <>
      <div className="row mt-4 ">
      {cardData.map((card, index) => (
      <div className="col-md-11 " style={{border:'1px solid #E0E0E0',borderRadius:'8px',textAlign: "left", padding:'12px 16px', marginBottom:'10px', marginLeft:'10px'}}>
      <h3 
      style={{
        fontFamily:'Inter',
        fontSize:'16px',
        fontStyle: 'normal',
        fontWeight:700,
        lineHeight:'20px',
        letterSpacing:'-0.5px'
       }}>
        {card.title}</h3>
       <p 
       style={{
        fontFamily:'Inter',
        fontSize:'14px',
        fontStyle: 'normal',
        fontWeight:500,
        lineHeight:'20px',
        letterSpacing:'-0.2px',
        color:'#575757'
       }}>{card.subtext}</p>
      </div>
      ))}
        </div>
    
     
    </>
  );
};
