import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { FiDownload } from "react-icons/fi";
import { MaterialReactTable } from "material-react-table";
import { NewModal } from "./NewModal";
import { InputModal } from "./InputModal";
import { Auth, Analytics } from "aws-amplify";
import { Box, IconButton } from "@mui/material";
import { MdModeEdit, MdDelete } from "react-icons/md";
import { CostMasterModal } from "./CostMasterModal";
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import "animate.css";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { OutputModal2 } from "./OutputModal2";
export const Transaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [downloadTransactions, setdownloadTransactions] = useState([]);
  const [variableCost, setVariableCost] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const toggleNew = () => setNewModal(!newModal);
  const [inputModal, setInputModal] = useState(false);
  const toggleInput = () => setInputModal(!inputModal);
  const [costMasterModal, setCostMasterModal] = useState(false);
  const toggleCostMaster = () => setCostMasterModal(!costMasterModal);
  const [update, setUpdate] = useState(0)
  const [outputModal, setOutputModal] = useState(false);
  const toggleOutput = () => setOutputModal(!outputModal);
  const [outputDetails, setOutputDetails] = useState([]);
  const [netSalesPrice, setNetSalesPrice] = useState(null);
  const [finalPriceUgx, setFinalPriceUgx] = useState(null);
  const [salesType, setsalesType] = useState('');
  const outTurn = useSelector((state) => state.outTurn);
  const [target, setTarget] = useState(null);
  const columns = [

    {
      header: "Coffee",
      accessorKey: "Coffee Type",
      size: 50,
      enableEditing: false
    },
    {
      header: "Buyer",
      accessorKey: "Buyer's Name",
      size: 50,
      enableEditing: false
    },
    {
      header: "Contract #",
      accessorKey: "Contract#",
      size: 50,
      enableEditing: false
    },
    {
      header: "Contract Date",
      accessorKey: "Date",
      size: 50,
      enableEditing: false,
      filterFn: 'lessThanOrEqualTo',
      sortingFn: (a, b) => {
        const dateA = new Date(a.original.Date.split('-').reverse().join('-'));
        const dateB = new Date(b.original.Date.split('-').reverse().join('-'));
        return dateA - dateB;
      },
      Filter: ({ column }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(newValue) => {
              column.setFilterValue(newValue);
            }}
            slotProps={{
              textField: {
                helperText: 'Filter Mode: Less Than',
                sx: { minWidth: '120px' },
                variant: 'standard',
              },
            }}
            value={column.getFilterValue()}
          />
        </LocalizationProvider>
      ),

    },
    {
      header: "Type",
      accessorKey: "Type",
      size: 70,
      enableEditing: false
    },
    {
      header: "Offer Price",
      accessorKey: "Offer Price",
      size: 50,
      enableEditing: (row) => row.original.Type === "Fixed" && row.original.Status !== 'Delivered',
    },
    {
      header: "Qty",
      accessorKey: "Qty",
      size: 50,
      enableEditing: false
    },
    {
      header: "Variable Cost",
      accessorKey: "Variable Cost",
      size: 50,
      enableEditing: false
    },
    {
      header: "Purchase Price",
      accessorKey: "Purchase Price",
      size: 50,
      enableEditing: false
    },
    {
      header: "Income_Other_Grades",
      accessorKey: "Income_Other_Grades",
      size: 50,
      enableEditing: (row) => row.original.Status !== 'Delivered',
    },
    {
      header: "Delivery Date",
      accessorKey: "Delivery Date",
      size: 50,
      filterFn: 'lessThanOrEqualTo',
      sortingFn: (a, b) => {
        const dateA = new Date(a.original['Delivery Date'].split('-').reverse().join('-'));
        const dateB = new Date(b.original['Delivery Date'].split('-').reverse().join('-'));
        return dateA - dateB;
      },
      Filter: ({ column }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(newValue) => {
              column.setFilterValue(newValue);
            }}
            slotProps={{
              textField: {
                helperText: 'Filter Mode: Less Than',
                sx: { minWidth: '120px' },
                variant: 'standard',
              },
            }}
            value={column.getFilterValue()}
          />
        </LocalizationProvider>
      ),
    },
    {
      header: "Price Fixation Start Date",
      accessorKey: "Price_Fixation_Start_Date",
      size: 50,
      enableEditing: (row) => row.original.Type !== "Fixed" && row.original.Status !== 'Delivered',
      sortingFn: (a, b) => {
        const dateA = new Date(a.original.Date.split('-').reverse().join('-'));
        const dateB = new Date(b.original.Date.split('-').reverse().join('-'));
        return dateA - dateB;
      },
      Filter: ({ column }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(newValue) => {
              column.setFilterValue(newValue);
            }}
            slotProps={{
              textField: {
                helperText: 'Filter Mode: Less Than',
                sx: { minWidth: '120px' },
                variant: 'standard',
              },
            }}
            value={column.getFilterValue()}
          />
        </LocalizationProvider>
      ),


    },
    {
      header: "Price Fixation End Date",
      accessorKey: "Price_Fixation_End_Date",
      size: 50,
      enableEditing: (row) => row.original.Type !== "Fixed" && row.original.Status !== 'Delivered',

      sortingFn: (a, b) => {
        const dateA = new Date(a.original.Date.split('-').reverse().join('-'));
        const dateB = new Date(b.original.Date.split('-').reverse().join('-'));
        return dateA - dateB;
      },
      Filter: ({ column }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(newValue) => {
              column.setFilterValue(newValue);
            }}
            slotProps={{
              textField: {
                helperText: 'Filter Mode: Less Than',
                sx: { minWidth: '120px' },
                variant: 'standard',
              },
            }}
            value={column.getFilterValue()}
          />
        </LocalizationProvider>
      ),


    },
    {
      header: "Status",
      accessorKey: "Status",
      size: 50,
      editVariant: 'select',
      enableEditing: (row) => row.original.Status !== "Delivered",
      editSelectOptions: ["Draft", "Active", "Delivered"]
    },
  ];

  useEffect(() => {
    async function genToken() {
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      Analytics.autoTrack('session', {
        // REQUIRED, turn on/off the auto tracking
        enable: true,
        // OPTIONAL, the attributes of the event, you can either pass an object or a function
        // which allows you to define dynamic attributes
        attributes: {
          email: email,
          page: 'Transaction'
        },
      });
    }
    genToken()
  }, [])
  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      fetch("/api/transaction/transactionTable?account_id=" + email, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setTransactions(data);
        });
      fetch("/api/transaction/downloadTransactionTable?account_id=" + email, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setdownloadTransactions(data);
        })
        .catch((error) => {
          alert("Error occured in Transaction Table")
        });

    }
    genToken();
  }, [update]);
  const handleCreate = async (e, obj) => {
    setOutputModal(false);

  };
  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    if (row.original.Status === "Draft" && values["Status"] === "Delivered") {
      alert('Draft transaction cannot be made Delivered! First make the status as Active')
      return;
    }
    else if (row.original.Status === "Active" && values["Status"] === "Draft") {
      alert('Active transaction cannot be made Draft')
      return;
    }
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      // console.log(row,values)
      await fetch(
        "/api/transaction/editTransaction?account_id=" + email + "&transaction_id=" +
        row["original"]["Transaction ID"] + "&buyers_name=" + values["Buyer's Name"] + "&coffee_type=" + values["Coffee Type"] + "&contract_number=" + values["Contract#"] + "&contract_date=" + values["Date"] + "&delivery_date=" + values["Delivery Date"] + "&offer_price_value=" + values["Offer Price"] + "&purchase_price_value=" + values["Purchase Price"] + "&quantity_value=" + values["Qty"] + "&variable_cost_value=" + values["Variable Cost"] + "&transaction_status=" + values["Status"] + "&contract_type=" + values["Type"] + "&income_other_grades=" + values["Income_Other_Grades"] + "&price_fixation_start_date=" + values["Price_Fixation_Start_Date"] + "&price_fixation_end_date=" + values["Price_Fixation_End_Date"],
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .catch((error) => {
          alert("Error occured in editing transaction!")
        });
    }
    genToken();
    exitEditingMode();
    handleupdatetransaction(row, values["Status"]);
  };
  const handleupdatetransaction = async (row, status) => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      const response1 = await fetch("/api/transaction/getTransaction?account_id=" + email + "&transaction_id=" + row["original"]["Transaction ID"] + "&transaction_status=" + row["original"]["Status"], {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      });
      if (!response1.ok) {
        throw new Error('Failed to fetch data');
      }
      const gettransactions = await response1.json();
      const response2 = await fetch("/api/transaction/outputField", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          account_id: email,
          contract_date: moment(gettransactions[0].Contract_Date).format("YYYY-MM-DD"),
          delivery_date: moment(gettransactions[0].Delivery_Date).format("YYYY-MM-DD"),
          contract_type: gettransactions[0].Contract_Type,
          contract_number: gettransactions[0].Contract_Number,
          buyer_name: gettransactions[0].Buyers_Name,
          quantity_value: parseFloat(gettransactions[0].Quantity_Value),
          purchase_price_value: parseFloat(gettransactions[0].Purchase_Price_Value),
          sales_type: gettransactions[0].Sales_Type,
          coffee_type: gettransactions[0].Coffee_Type,
          sales_grade: gettransactions[0].Sales_Grade,
          certification_type: gettransactions[0].Certification_Type,
          quality_differential_value: parseFloat(gettransactions[0].Quality_Differential_Value),
          quality_differential_unit: gettransactions[0].Quality_Differential_Unit,
          rainforest_premium: parseFloat(gettransactions[0].Rainforest_Premium),
          purchase_grade: gettransactions[0].Purchase_Grade,
          outturn_percent: parseFloat(gettransactions[0]["Outturn%"]),
          offer_price_value: parseFloat(gettransactions[0].Offer_Price_Value),
          certified_fair_trade: gettransactions[0].Certified_Fair_Trade,
          income_other_grades: gettransactions[0].Income_Other_Grades,
        }),
      })
      if (!response2.ok) {
        throw new Error('Failed to fetch data');
      }
      const outputDetails = await response2.json();
      const tabledata = {
        "account_id": email,
        "transaction_id": row["original"]["Transaction ID"],
        "contract_number": gettransactions[0].Contract_Number,
        "contract_date": moment(gettransactions[0].Contract_Date).format("YYYY-MM-DD"),
        "delivery_date": moment(gettransactions[0].Delivery_Date).format("YYYY-MM-DD"),
        "contract_type": gettransactions[0].Contract_Type,
        "buyer_name": gettransactions[0].Buyers_Name,
        "quantity_value": parseFloat(gettransactions[0].Quantity_Value),
        "purchase_price_value": parseFloat(gettransactions[0].Purchase_Price_Value),
        "transaction_status": status,
        "sales_type": gettransactions[0].Sales_Type,
        "coffee_type": gettransactions[0].Coffee_Type,
        "sales_grade": gettransactions[0].Sales_Grade,
        "certification_type": gettransactions[0].Certification_Type,
        "price_fixation_period_type": gettransactions[0].Price_Fixation_Period_Type,
        "price_fixation_start_date": moment(gettransactions[0].Price_Fixation_Start_Date).format("YYYY-MM-DD"),
        "price_fixation_end_date": moment(gettransactions[0].Price_Fixation_End_Date).format("YYYY-MM-DD"),
        "quality_differential_value": parseFloat(gettransactions[0].Quality_Differential_Value),
        "quality_differential_unit": gettransactions[0].Quality_Differential_Unit,
        "rainforest_premium": parseFloat(gettransactions[0].Rainforest_Premium),
        "purchase_grade": gettransactions[0].Purchase_Grade,
        "outturn_percent": parseFloat(gettransactions[0]["Outturn%"]),
        "income_other_grades": parseFloat(gettransactions[0].Income_Other_Grades),
        "offer_price_value": parseFloat(gettransactions[0].Offer_Price_Value),
        "final_price_value":
          parseFloat(outputDetails["Table1"]["Final Price"].split(" ")[0]),
        "final_price_UGX":
          parseFloat(outputDetails["Table2"]["Final Price UGX"].split(" ")[0]),
        "variable_cost_export_value":
          parseFloat(outputDetails["Table2"]["Variable Cost Exports"].split(" ")[0]),
        "variable_cost_export_unit": "UGX/Kg",
        "net_sales_price_value":
          parseFloat(outputDetails["Table2"]["Net Sales Price"].split(" ")[0]),
        "net_sales_price_unit": "UGX/Kg",
        "variable_cost_other_value":
          parseFloat(outputDetails["Table3"]["Variable Cost Others"].split(" ")[0]),
        "variable_cost_other_unit": "UGX/Kg",
        "purchase_price_after_outturn":
          parseFloat(outputDetails["Table3"]["Purchase Price After Outturn"].split(
            " "
          )[0]),
        "contribution": parseFloat(outputDetails["Table3"]["Contribution"].split(" ")[0]),
        "contribution_percent": parseFloat(outputDetails["Table3"]["Contribution%"]),
        "fixed_cost_value": parseFloat(outputDetails["Table4"]["Fixed Cost"].split(" ")[0]),
        "fixed_cost_unit": "UGX/Kg",
        "net_profit_value": parseFloat(outputDetails["Table4"]["Profit Before Tax"].split(" ")[0]),
        "net_profit_unit": "UGX/Kg",
        "net_profit_percent": parseFloat(outputDetails["Table4"]["Profit Before Tax%"]),
      }
      if (gettransactions[0].contract_type === "Open") {
        tabledata["price_fixation_period_type"] = gettransactions[0].Price_Fixation_Period_Type;
        tabledata["price_fixation_start_date"] = moment(gettransactions[0].Price_Fixation_Start_Date).format("YYYY-MM-DD");
        tabledata["price_fixation_end_date"] = moment(gettransactions[0].Price_Fixation_End_Date).format("YYYY-MM-DD");
      }
      if (gettransactions[0].Certification_Type.includes("Certified-Fair Trade")) {
        tabledata["certified_fair_trade"] = gettransactions[0].Certified_Fair_Trade;
      }

      await fetch("/api/transaction/updateTransaction", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify(
          tabledata
        ),

      })
        .then(async (response) => {
          if (response.status === 200) {
            await fetch("/api/transaction/transactionTable?account_id=" + email, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                setTransactions(data);
                setUpdate(update + 1)
              });
          }
        })

        .catch((error) => {
          alert("Error occured !")
        });

      await fetch("/api/transaction/downloadTransactionTable?account_id=" + email, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setdownloadTransactions(data);
        })
        .catch((error) => {
          alert("Error occured in Transaction Table")
        });

    }
    genToken();
  };

  const handleDeletion = async (row) => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      //console.log(row)
      await fetch(
        "/api/transaction/deleteTransaction?account_id=" + email + "&transaction_id=" +
        row["original"]["Transaction ID"],
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => console.log("response", response))
        .then((data) => {
          Store.addNotification({
            title: "Agtuall",
            message: "Transaction was deleted",
            type: "info",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        })
        .catch((error) => {
          alert("Error occured while deleting a transaction")
        });
    }
    genToken();
  };
  const handleNewButton = async (row) => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      await fetch("/api/transaction/variableCost?account_id=" + email, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setVariableCost(data["variable cost"]);
          toggleNew();
        })
        .catch((error) => {
          alert("Error occured in variable cost")
        });
    }
    genToken();

  };
  const handleCancel = async (e, obj) => {
    toggleOutput();
  };
  const handleShowOutputmodal = async (row, values) => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      const response1 = await fetch("/api/transaction/getTransaction?account_id=" + email + "&transaction_id=" + values["Transaction ID"] + "&transaction_status=" + values["Status"], {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      });
      if (!response1.ok) {
        throw new Error('Failed to fetch data');
      }
      const gettransactions = await response1.json();
      setsalesType(gettransactions[0].Sales_Type);
      const response2 = await fetch("/api/transaction/outputField", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          account_id: email,
          contract_date: moment(gettransactions[0].Contract_Date).format("YYYY-MM-DD"),
          delivery_date: moment(gettransactions[0].Delivery_Date).format("YYYY-MM-DD"),
          contract_type: gettransactions[0].Contract_Type,
          contract_number: gettransactions[0].Contract_Number,
          buyer_name: gettransactions[0].Buyers_Name,
          quantity_value: parseFloat(gettransactions[0].Quantity_Value),
          purchase_price_value: parseFloat(gettransactions[0].Purchase_Price_Value),
          sales_type: gettransactions[0].Sales_Type,
          coffee_type: gettransactions[0].Coffee_Type,
          sales_grade: gettransactions[0].Sales_Grade,
          certification_type: gettransactions[0].Certification_Type,
          quality_differential_value: parseFloat(gettransactions[0].Quality_Differential_Value),
          quality_differential_unit: gettransactions[0].Quality_Differential_Unit,
          rainforest_premium: parseFloat(gettransactions[0].Rainforest_Premium),
          purchase_grade: gettransactions[0].Purchase_Grade,
          outturn_percent: parseFloat(gettransactions[0]["Outturn%"]),
          offer_price_value: parseFloat(gettransactions[0].Offer_Price_Value),
          certified_fair_trade: gettransactions[0].Certified_Fair_Trade,
          income_other_grades: gettransactions[0].Income_Other_Grades,


        }),
      })
      if (!response2.ok) {
        alert('Error occured in fetching data')
      }
      const data = await response2.json();

      setOutputDetails(data)
      setNetSalesPrice(data["Table2"]["Net Sales Price"]);
      setTarget(data["Contribution"]["Contribution%"]);
      setFinalPriceUgx(data["Table2"]["Final Price UGX"]);

    }
    genToken();
  };

  return (
    <>
      <div className="row mt-5 justify-content-end mb-2">
        <div className="col-md-11">
          <div className="row">
            <div className="col-md-9" style={{ textAlign: "left" }}>
              <h5
                style={{
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "28px",
                  letterSpacing: "-0.5px",
                }}
              >
                Transaction
              </h5>
            </div>
            <div className="col-md-3">
              <div className="row">
                <div className="col-md-3">
                  <Button
                    style={{
                      borderColor: "#4C44D8",
                      backgroundColor: "#4C44D8",
                      color: "#fff",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }}
                    onClick={handleNewButton}
                  >
                    New
                  </Button>
                </div>
                <div className="col-md-6">
                  <Button
                    style={{
                      borderColor: "#4C44D8",
                      backgroundColor: "#E5E5F2",
                      color: "#4C44D8",
                    }}
                    onClick={toggleCostMaster}
                  >
                    Set Costmaster
                  </Button>
                </div>
                <div className="col-md-1 mt-1">
                  <CSVLink data={downloadTransactions} filename={"Transactions.csv"}>
                    <FiDownload size={20} style={{ color: "#4A42A0" }} />
                  </CSVLink>
                </div>{" "}
              </div>
            </div>
          </div>
          <div className="row mt-2 justify-content-center">
            <div className="col-md-12">
              <MaterialReactTable
                columns={columns}
                data={transactions}
                enableRowSelection={false}
                enableColumnOrdering={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnFilters={false}
                enableTopToolbar
                enableBottomToolbar
                enableRowActions
                positionActionsColumn="last"
                enableColumnActions={false}
                // enableEditing
                onEditingRowSave={handleSaveRow}
                initialState={{
                  columnVisibility: {
                    Price_Fixation_End_Date: false,
                    Price_Fixation_Start_Date: false,
                    Income_Other_Grades: false
                  },
                  density: "compact",
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "20px",
                    letterSpacing: "-0.5px",
                  },
                }}
                muiTableBodyRowProps={(row) => ({
                  onDoubleClick: (event) => {
                    setOutputModal(true);
                    const values = row.row.original;
                    // console.log(row.row.original['Transaction ID'])
                    handleShowOutputmodal(row, values)
                  },
                })}
                muiTableHeadCellProps={{
                  sx: {
                    zIndex: "auto",
                    color: "#4C44D8",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    letterSpacing: "-0.5px",
                  },
                }}
                renderRowActions={({ row, table }) => (
                  <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }} onClick={() => setOutputModal(false)}>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        table.setEditingRow(row);
                      }}
                    >
                      <MdModeEdit />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => {
                        setOutputModal(false);
                        transactions.splice(row.index, 1); //assuming simple data table
                        setTransactions([...transactions]);
                        setUpdate(update + 1)
                        handleDeletion(row);
                      }}
                    >
                      <MdDelete />
                    </IconButton>
                  </Box>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <NewModal
        toggleNew={toggleNew}
        newModal={newModal}
        toggleInput={toggleInput}
        setNewModal={setNewModal}
        setInputModal={setInputModal}
        toggleCostMaster={toggleCostMaster}
        variableCost={variableCost}
      />
      <InputModal
        toggleInput={toggleInput}
        inputModal={inputModal}
        setInputModal={setInputModal}
        setTransactions={setTransactions}
      />
      <CostMasterModal
        toggleCostMaster={toggleCostMaster}
        costMasterModal={costMasterModal}
        setCostMasterModal={setCostMasterModal}

      />
      <OutputModal2
        outputModal={outputModal}
        toggleOutput={toggleOutput}
        setOutputModal={setOutputModal}
        handleCancel={handleCancel}
        handleCreate={handleCreate}
        outputDetails={outputDetails}
        outTurn={outTurn ? outTurn.split(" ")[0] : "100"}
        netSalesPrice={netSalesPrice ? netSalesPrice.split(" ")[0] : ""}
        target={target}
        sales_type={salesType}
        final_price_UGX={finalPriceUgx ? finalPriceUgx.split(" ")[0] : ""}
        setTarget={setTarget}
      />
    </>
  );
};

