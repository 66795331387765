import React from "react";

const Footer = () => {
  return (
    <div
      className="row "
      style={{
        position: "fixed",
        bottom: "0", 
        right:"0",
        width: "100%", 
        backgroundColor: "#E5E5F2",
        zIndex: "1000", 
        padding: "7px 7px",
      }}
      data-testid='beanbooktext'>
             <h3
              style={{
                color:'#575757',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight:" 20px",
                letterSpacing: "-0.2px",                
              }}>
             For any assistance, write to us at  {" "}
             <span style={{ color: "#4C44D8", fontWeight:700 }}> beanbookinfo@gmail.com</span>
            </h3>
          </div> 
  );
};

export default Footer;
