import { combineReducers } from "redux";
import { changebuyerNameReducer } from "./reducers/changebuyerNameReducer";
import { changeContractTypeReducer } from "./reducers/changeContractTypeReducer";
import { changeContractNoReducer } from "./reducers/changeContractNoReducer";
import { changecontractDateReducer } from "./reducers/changecontractDateReducer";
import { changedeliveryDateReducer } from "./reducers/changedeliveryDateReducer copy";
import { changeCoffeeTypeReducer } from "./reducers/changeCoffeeTypeReducer";
import { changeSalesGradeReducer } from "./reducers/changeSalesGradeReducer";
import { changeSaleTypeReducer } from "./reducers/changeSaleTypeReducer";
import { changestartDateReducer } from "./reducers/changestartDateReducer";
import { changeendDateReducer } from "./reducers/changeendDateReducer";
import { changePriceFixationReducer } from "./reducers/changePriceFixationReducer";
import { changeQuantityReducer } from "./reducers/changeQuantityReducer";
import { changeQualityReducer } from "./reducers/changeQualityReducer";
import { changeRainforestPremiumReducer } from "./reducers/changeRainforestPremiumReducer";
import { changePurchaseGradeReducer } from "./reducers/changePurchaseGradeReducer";
import { changePurchasePriceReducer } from "./reducers/changePurchasePriceReducer";
import { changeoutTurnReducer } from "./reducers/changeoutTurnReducer";
import { changeFobPriceValueReducer } from "./reducers/changeFobPriceValueReducer";
import { changeFobPriceReducer } from "./reducers/changeFobPriceReducer";
import { changeincome_other_gradesReducer } from "./reducers/changeincome_other_gradesReducer.js";

const rootReducer = combineReducers({
  saleType:changeSaleTypeReducer,
  coffeeType:changeCoffeeTypeReducer,
  buyerName: changebuyerNameReducer,
  contractType: changeContractTypeReducer,
  contractNo: changeContractNoReducer,
  deliveryDate:changedeliveryDateReducer,
  contractDate:changecontractDateReducer,
  salesGrade:changeSalesGradeReducer,
  //salesprice
  priceFixation:changePriceFixationReducer,
  startDate:changestartDateReducer,
  endDate:changeendDateReducer,
  fobPriceValue: changeFobPriceValueReducer,
  fobPrice: changeFobPriceReducer,
  quantity:changeQuantityReducer,
  quality:changeQualityReducer,
  rainforestPremium:changeRainforestPremiumReducer,
  //purchase price
  purchaseGrade:changePurchaseGradeReducer,
  purchasePrice:changePurchasePriceReducer,
  outTurn:changeoutTurnReducer,
  income_other_grades: changeincome_other_gradesReducer,
});

export default rootReducer;
