import React from 'react';
import { useState , useEffect} from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Auth } from "aws-amplify";

const UgandianClimate = () => {
  const [tabledata, settableData] = useState([]);
  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const currentDate = new Date();

    // Format the date as "YYYY-MM-DD"
    const formattedDate = currentDate.toISOString().split('T')[0];

    fetch(
      `/api/market/production/climate_data?date=${formattedDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      }
    )
        .then((response) => response.json())
        .then((data) => {
         settableData(data);
        })
        .catch((error) => {
          alert("Error occured in climate Data")
          settableData(null);
      });
    }
    genToken();
  }, []);
  const columns = [
    {
      header: "Name of District",
      id: "name",
      columns: [
        {
          header: " ",
          accessorKey: "district",
          size:150

        },
      ]
    },
    
    {
      header: "Rainfall ( mm ) ",
      id: "Rainfall",
      columns: [
        {
          header: "Forecast",
          accessorKey: "forecast rain",
          size:150

        },
  
        {
          header: "Average ",
          accessorKey: "average rainfall",
          size : 150
        },
  
      ]
    },
    {
      header: "Temperature ( °C )",
      id: "Temperature",
      columns: [
        {
          header: "Forecast Min ",
          accessorKey: "forecast min temp",
          size:150

        },
  
        {
          header: "Forecast Max ",
          accessorKey: "forecast max temp",
          size:150

        },
        {
          header: "Average min ",
          accessorKey: "average min temp",
          size:150

        },
        {
          header: "Average max",
          accessorKey: "average max temp",
          size:150

        }
  
      ]
    },
  ]

  return (
    <div>
    <MaterialReactTable
      columns={columns}
      data={tabledata}
      enablePagination={false}
      enableRowVirtualization
      enableRowSelection={false}
      enableFullScreenToggle={false}
      enableColumnFilters={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableRowActions={false}
      enableColumnActions={false}
      enableColumnOrdering={false}
      initialState={{
        density: 'spacious'
      }} 
      muiTableContainerProps={{
        sx: {
          maxHeight: '400px'
        }
      }} 
      muiTableBodyCellProps={{
        sx: {
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "20px",
          letterSpacing: "-0.2px",
          height:'36px',
          // padding:0.1,
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          zIndex: "auto",
          lineHeight: "20px",
          color: "#4C44D8",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "600",
          letterSpacing: "-0.2px",
          background:'#E5E5F2',
        },
      }}
      muiTableHeadRowProps={{
        sx: {
            background:'#E5E5F2',
          },
      }}
    
    />
    </div>
  );
};

export default UgandianClimate;
