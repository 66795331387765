import React from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

export const NewModal = (props) => {
  return (
    <>
      <Modal isOpen={props.newModal} toggle={props.toggleNew} centered={true}>
        <ModalHeader toggle={props.toggleNew} className="news-heading">
          Set Cost
        </ModalHeader>
        <ModalBody>
          Variable Cost: {props.variableCost} UGX/Kg
          <div className="row">
            <div className="col-md-7 grey-text">
              Would you like to continue?
            </div>
          </div>
        </ModalBody>
        <div className="row justify-content-center mb-3">
          <div className="col-md-2">
            <Button color="secondary" onClick={props.toggleCostMaster}>
              Reset
            </Button>{" "}
          </div>
          <div className="col-md-2">
            <Button
              color="primary"
              onClick={() => {
                props.setInputModal(true);
                props.setNewModal(false);
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
