import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import "../../transaction.css";
import { Auth } from "aws-amplify";
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";
export const CostMasterModal = (props) => {
  // factory
  const [variableCost, setVariableCost] = useState(false);
  const [factoryOffloading, setFactoryOffloading] = useState(null);
  const [factoryHulling, setFactoryHulling] = useState(null);
  const [factoryWarehouse, setFactoryWarehouse] = useState(null);
  const [factoryTransport, setFactoryTransport] = useState(null);
  const [factoryFuel, setFactoryFuel] = useState(null);
  const [factoryOthers, setFactoryOthers] = useState(null);
  // warehouse
  const [warehouseOffLoading, setWarehouseOffLoading] = useState(null);
  const [warehouseSorting, setWarehouseSorting] = useState(null);
  const [warehousePackaging, setWarehousePackaging] = useState(null);
  const [warehouseStacking, setWarehouseStacking] = useState(null);
  const [warehouseOthers, setWarehouseOthers] = useState(null);

  // export
  const [exportTransport, setExportTransport] = useState(null);
  const [exportLoading, setExportLoading] = useState(null);
  const [exportInspection, setExportInspection] = useState(null);
  const [exportClearance, setExportClearance] = useState(null);
  const [exportSampling, setExportSampling] = useState(null);
  const [exportQuality, setExportQuality] = useState(null);
  const [exportPSI, setExportPSI] = useState(null);
  const [exportOthers, setExportOthers] = useState(null);

  // insurance
  const [insuranceShipping, setInsuranceShipping] = useState(null);

  // estimated
  const [fixedCosts, setFixedCosts] = useState(null);
  const [sales, setSales] = useState(null);

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      fetch("/api/transaction/extractCostMasterFields?account_id=" + email, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
                setFactoryOffloading(data[0].Factory_Offloading_Weighing || null);
                setFactoryHulling(data[0].Factory_Hulling_Grading_All_Processing_Cost|| null);
                setFactoryWarehouse(data[0].Factory_Loading_To_Warehouse || null);
                setFactoryTransport(data[0].Factory_Transport_To_Warehouse|| null);
                setFactoryFuel(data[0].Factory_Fuel_Maintenance|| null);
                setFactoryOthers(data[0].Factory_Others|| null);
        
                // At Warehouse - UGX/Kg
                setWarehouseOffLoading(data[0].Warehouse_Offloading || null);
                setWarehouseSorting(data[0].Warehouse_Handsorting || null);
                setWarehousePackaging(data[0].Warehouse_Packaging || null);
                setWarehouseStacking(data[0].Warehouse_Stacking || null);
                setWarehouseOthers(data[0].Warehouse_Others || null);
          
                // At Export - UGX/Kg
                setExportTransport(data[0].Export_Transport_FOB_Mombasa || null);
                setExportLoading(data[0].Export_Loading_Container || null);
                setExportInspection(data[0].Export_Inspection_By_UCDA|| null);
                setExportClearance(data[0].Export_Clearance_Agent_Fees|| null);
                setExportSampling(data[0].Export_Sampling_Courier || null);
                setExportQuality(data[0].Export_Quality_Control || null);
                setExportPSI(data[0].Export_PSI_Services || null);
                setExportOthers(data[0].Export_Others || null);
        
                // Insurance
                setInsuranceShipping(data[0].Export_Insurance_Warehouse_Shipping || null);
                  
                // Estimated
                setFixedCosts(data[0].Estimated_Annual_Fixed_Cost_Value || null);
                setSales(data[0].Estimated_Sales_Of_Year_Value || null);
        })
        .catch((error) => {
          alert("Error occured in costmaster")
        });
        
      await fetch("/api/transaction/variableCost?account_id=" + email, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setVariableCost(data["variable cost"]);
        })
        .catch((error) => {
          alert("Error occured in variable cost")
        });

    }
    genToken();
  }, []);
  const formvalid = () => {
    if (!sales && !fixedCosts && (props.variableCost === 0||props.variableCost=== false)) {
      alert("Please fill the mandatory fields");
    }
    else if (!fixedCosts) {
      alert("Please fill Estimated Annual Fixed Costs");
    }
    else if (props.variableCost === 0 ||props.variableCost=== false) {
      alert("Please click on Get Variable Cost button");
    }
    else if (!sales) {
      alert("Please fill Estimated Sales of the Year (MT)");
    }
    else {
      handleSubmit()
    }
  }
  const handleSubmit = async (e, obj) => {
    let didCancel = false;
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      await fetch("/api/transaction/editCostMaster", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          factory_offloading_weighing: factoryOffloading,
          factory_hulling_grading_all_processing_cost: factoryHulling,
          factory_loading_to_warehouse: factoryWarehouse,
          factory_transport_to_warehouse: factoryTransport,
          factory_fuel_maintenance: factoryFuel,
          factory_others: factoryOthers,
          warehouse_offloading: warehouseOffLoading,
          warehouse_handsorting: warehouseSorting,
          warehouse_packaging: warehousePackaging,
          warehouse_stacking: warehouseStacking,
          warehouse_others: warehouseOthers,
          export_transport_fob_mombasa: exportTransport,
          export_loading_container: exportLoading,
          export_inspection_by_ucda: exportInspection,
          export_clearance_agent_fees: exportClearance,
          export_sampling_courier: exportSampling,
          export_quality_control: exportQuality,
          export_psi_services: exportPSI,
          export_others: exportOthers,
          export_insurance_warehouse_shipping: insuranceShipping,
          estimated_annual_fixed_cost_unit: "UGX/Kg",
          estimated_annual_fixed_cost_value: fixedCosts,
          cost_master_unit: "UGX/Kg",
          estimated_sales_of_year_unit: "mt",
          estimated_sales_of_year_value: sales,
          account_id: email,
          cost_master_status: "active",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
            Store.addNotification({
              title: "Agtuall",
              message: "Cost Master was updated",
              type: "info",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
        });
      props.setCostMasterModal(false);
      if (!didCancel) {
        // Ignore if we started fetching something else
        console.log("....");
      }
    }
    genToken();
    return () => {
      didCancel = true;
    };
  };
  const handleVarCost = async (e, obj) => {
    let didCancel = false;
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      await fetch("/api/transaction/currentVariableCost?account_id=" + email, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          factory_offloading_weighing: parseFloat(factoryOffloading),
          factory_hulling_grading_all_processing_cost:
            parseFloat(factoryHulling),
          factory_loading_to_warehouse: parseFloat(factoryWarehouse),
          factory_transport_to_warehouse: parseFloat(factoryTransport),
          factory_fuel_maintenance: parseFloat(factoryFuel),
          factory_others: parseFloat(factoryOthers),
          warehouse_offloading: parseFloat(warehouseOffLoading),
          warehouse_handsorting: parseFloat(warehouseSorting),
          warehouse_packaging: parseFloat(warehousePackaging),
          warehouse_stacking: parseFloat(warehouseStacking),
          warehouse_others: parseFloat(warehouseOthers),
          export_transport_fob_mombasa: parseFloat(exportTransport),
          export_loading_container: parseFloat(exportLoading),
          export_inspection_by_ucda: parseFloat(exportInspection),
          export_clearance_agent_fees: parseFloat(exportClearance),
          export_sampling_courier: parseFloat(exportSampling),
          export_quality_control: parseFloat(exportQuality),
          export_psi_services: parseFloat(exportPSI),
          export_others: parseFloat(exportOthers),
          insurance_warehouse_shipping: parseFloat(insuranceShipping),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setVariableCost(data["variable cost"]);
        });
      if (!didCancel) {
        // Ignore if we started fetching something else
        console.log("....");
      }
    }
    genToken();
    return () => {
      didCancel = true;
    };
  };
  return (
    <>
      <Modal
        isOpen={props.costMasterModal}
        toggle={props.toggleCostMaster}
        centered={true}
        size="xl"
      >
        <ModalHeader toggle={props.toggleCostMaster} className="news-heading">
          Cost Master
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-7 big-label">Factory Level-UGX/Kg</div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Offloading & Weighing</Label>
                <Input
                  bsSize="sm"
                  name="f_offloading"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setFactoryOffloading(e.target.value);
                  }}
                  value={factoryOffloading}
                />
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">
                  Hulling, Grading - All Processing Cost
                </Label>
                <Input
                  bsSize="sm"
                  name="f_hulling"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setFactoryHulling(e.target.value);
                  }}
                  value={factoryHulling}
                />
              </FormGroup>{" "}
            </div>
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Loading to Warehouse</Label>
                <Input
                  bsSize="sm"
                  name="f_loading"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setFactoryWarehouse(e.target.value);
                  }}
                  value={factoryWarehouse}
                />
              </FormGroup>{" "}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Local Transport </Label>
                <Input
                  bsSize="sm"
                  name="f_transport"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setFactoryTransport(e.target.value);
                  }}
                  value={factoryTransport}
                />
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Fuel & Maintenance </Label>
                <Input
                  bsSize="sm"
                  name="f_fuel"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setFactoryFuel(e.target.value);
                  }}
                  value={factoryFuel}
                />
              </FormGroup>{" "}
            </div>
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Others</Label>
                <Input
                  bsSize="sm"
                  name="f_others"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setFactoryOthers(e.target.value);
                  }}
                  value={factoryOthers}
                />
              </FormGroup>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 big-label">At Warehouse-UGX/Kg</div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Offloading</Label>
                <Input
                  bsSize="sm"
                  name="w_offloading"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setWarehouseOffLoading(e.target.value);
                  }}
                  value={warehouseOffLoading}
                />
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Hand sorting </Label>
                <Input
                  bsSize="sm"
                  name="w_sorting"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setWarehouseSorting(e.target.value);
                  }}
                  value={warehouseSorting}
                />
              </FormGroup>{" "}
            </div>
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Packaging Costs</Label>
                <Input
                  bsSize="sm"
                  name="w_packaging"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    setWarehousePackaging(e.target.value);
                  }}
                  value={warehousePackaging}
                />
              </FormGroup>{" "}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Stacking Costs</Label>
                <Input
                  bsSize="sm"
                  name="w_stacking"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setWarehouseStacking(e.target.value);
                  }}
                  value={warehouseStacking}
                />
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Others</Label>
                <Input
                  bsSize="sm"
                  name="w_others"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setWarehouseOthers(e.target.value);
                  }}
                  value={warehouseOthers}
                />
              </FormGroup>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 big-label">At Export-UGX/Kg</div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Transport FOB - Mombasa</Label>
                <Input
                  bsSize="sm"
                  name="e_transport"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setExportTransport(e.target.value);
                  }}
                  value={exportTransport}
                />
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Loading - Container </Label>
                <Input
                  bsSize="sm"
                  name="e_loading"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setExportLoading(e.target.value);
                  }}
                  value={exportLoading}
                />
              </FormGroup>{" "}
            </div>
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Inspection by UCDA</Label>
                <Input
                  bsSize="sm"
                  name="e_inspection"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setExportInspection(e.target.value);
                  }}
                  value={exportInspection}
                />
              </FormGroup>{" "}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">
                  Export Clearance & Agent Fees
                </Label>
                <Input
                  bsSize="sm"
                  name="e_clearance"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setExportClearance(e.target.value);
                  }}
                  value={exportClearance}
                />
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Sampling & Courier </Label>
                <Input
                  bsSize="sm"
                  name="e_sampling"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setExportSampling(e.target.value);
                  }}
                  value={exportSampling}
                />
              </FormGroup>{" "}
            </div>
            <div className="col-md-4">
                <FormGroup>
                  <Label className="small-label">Insurance - Warehouse + shipping </Label>
                  <Input
                    bsSize="sm"
                    name="i_shipping"
                    onWheel={(e) => e.target.blur()}
                    type="number"
                    onChange={(e) => {
                      setInsuranceShipping(e.target.value);
                    }}
                    value={insuranceShipping}
                  />
                </FormGroup>{" "}
              </div>
           
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">Quality Control</Label>
                <Input
                  bsSize="sm"
                  name="e_quality"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setExportQuality(e.target.value);
                  }}
                  value={exportQuality}
                />
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup>
                <Label className="small-label">PSI Services </Label>
                <Input
                  bsSize="sm"
                  name="e_psi"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setExportPSI(e.target.value);
                  }}
                  value={exportPSI}
                />
              </FormGroup>{" "}
            </div>
            <div className="col-md-4">
                <FormGroup>
                  <Label className="small-label">Others</Label>
                  <Input
                    bsSize="sm"
                    name="e_others"
                    onWheel={(e) => e.target.blur()}
                    type="number"
                    onChange={(e) => {
                      setExportOthers(e.target.value);
                    }}
                    value={exportOthers}
                  />
                </FormGroup>{" "}
              </div>
          </div>

          <hr />
          <div className="row">
            <div className="col-md-6 big-label">
              <InputGroup>
                <InputGroupText
                  style={{
                    backgroundColor: "#E5E5F2",
                    borderColor: "#E0E0E0",
                    color: "#4C44D8",
                  }}
                >
                  Total Variable Cost
                </InputGroupText>
                <Input
                  style={{ fontWeight: "bold" }}
                  placeholder=""
                  bsSize="sm"
                  name="variable_cost"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  value={variableCost}
                />
              </InputGroup>
            </div>
            <div className="col-md-4 ">
                <Button color="primary" onClick={handleVarCost}>
                Calculate Variable Cost 
                </Button>
              </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <FormGroup>
                <Label className="small-label">
                  Estimated Annual Fixed Costs (Shilling)
                </Label>
                <Input
                  bsSize="sm"
                  name="fixed_costs"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  required
                  onChange={(e) => {
                    setFixedCosts(e.target.value);
                  }}
                  value ={fixedCosts}
                />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup>
                <Label className="small-label">
                  Estimated Sales of the Year (Kg){" "}
                </Label>
                <Input
                  bsSize="sm"
                  name="sales"
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  required
                  onChange={(e) => {
                    setSales(e.target.value);
                  }}
                  value={sales}
                />
              </FormGroup>{" "}
            </div>
          </div>
        </ModalBody>
        <div className="row justify-content-end mb-3">
          <div className="col-md-3">
            <div className="row">
              <div className="col-md-4">
                <Button color="secondary" onClick={props.toggleCostMaster}>
                  Cancel
                </Button>{" "}
              </div>
              <div className="col-md-6">
                <Button color="primary" onClick={formvalid}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
