import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Button, ModalHeader } from "reactstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "../../transaction.css";
import { ColumnChart } from "./ColumnChart";
import { Auth } from "aws-amplify";

export const OutputModal = (props) => {
  const [purchasePrice, setPurchasePrice] = useState([]);
  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      if (
        props.outTurn !== "" &&
        props.target !== null && props.final_price_UGX !== null
      ) {
        await fetch(
          "/api/transaction/getPurchasePrice?account_id=" + email + "&final_price_ugx=" +
          props.final_price_UGX +
          "&outturn_percent=" +
          props.outTurn +
          "&user_contribution_percent=" +
          props.target + "&sales_type=" + props.sales_type,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setPurchasePrice(data);
          })
          .catch(() => {
            // alert("Error occured in Purchase price")
            console.log("Error occured in purchare price")
        });
      }
    }
    
  genToken();
  }, [props.outTurn, props.final_price_UGX, props.target, props.sales_type]);

  return (
    <>
      <Modal
        isOpen={props.outputModal}
        toggle={props.toggleOutput}
        centered={true}
        size="xl"
      >
        <ModalHeader toggle={props.toggleOutput} className="news-heading">
          New Transaction
        </ModalHeader>
        <ModalBody>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="row">
                <h5>Margin Analytics</h5>
              </div>
              <div className="row">
                {props.outputDetails.length !== 0
                  ? Object.entries(props.outputDetails["Table1"]).map(
                    ([key, value],index, array) => (
                      <>
                        <div
                          className="row justify-content-center"
                          style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "20px",
                            letterSpacing: "-0.2px",
                          }}
                        >
                          <div
                            className="col-md-5"
                            style={{
                              padding: "5px",
                              backgroundColor: "#BDD7EE",

                            }}
                          >
                            {key}
                          </div>
                          <div
                            className="col-md-3"
                            style={{
                              padding: "5px",
                              borderBottom :index === array.length - 1 ? "1px solid #BDD7EE" : "none"
                            }}
                          >
                            {value}
                          </div>
                        </div>
                      </>
                    )
                  )
                  : ""}
              </div>
              <div className="row mt-4">
                {props.outputDetails.length !== 0
                  ? Object.entries(props.outputDetails["Table2"]).map(
                    ([key, value],index, array) => (
                      <>
                        <div
                          className="row justify-content-center"
                          style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "20px",
                            letterSpacing: "-0.2px",
                          }}
                        >
                          <div
                            className="col-md-5"
                            style={{
                              padding: "5px",
                              backgroundColor: "#C6E0B4",
                              
                            }}
                          >
                            {key}
                          </div>
                          <div
                            className="col-md-3"
                            style={{
                              padding: "5px",
                              borderBottom :index === array.length - 1 ? "1px solid #C6E0B4" : "none"
                              
                            }}
                          >
                            {value}
                          </div>
                        </div>
                      </>
                    )
                  )
                  : ""}
              </div>
              <div className="row mt-4">
                {props.outputDetails.length !== 0
                  ? Object.entries(props.outputDetails["Table3"]).map(
                    ([key, value],index, array) => (
                      <>
                        <div
                          className="row justify-content-center"
                          style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "20px",
                            letterSpacing: "-0.2px",
                          }}
                        >
                          <div
                            className="col-md-5"
                            style={{
                              padding: "5px",
                              backgroundColor: "#FFF2CC",
                              border: key === "Contribution%" ? "1px solid black" : "none",
                              
                            }}
                          >
                            {key}
                          </div>
                          <div
                            className="col-md-3"
                            style={{
                            padding: "5px",
                            border: key === "Contribution%" ? "1px solid black" : "none",                             
                            }}
                          >
                            {value}
                          </div>
                        </div>
                      </>
                    )
                  )
                  : ""}
              </div>
              <div className="row mt-4">
                {props.outputDetails.length !== 0
                  ? Object.entries(props.outputDetails["Table4"]).map(
                    ([key, value], index, array) => (
                      <>
                        <div
                          className="row justify-content-center"
                          style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "20px",
                            letterSpacing: "-0.2px",
                            
                          }}
                        >
                          <div
                            className="col-md-5"
                            style={{
                              padding: "5px",
                              backgroundColor: "#B4C6E7",
                            }}
                          >
                            {key}
                          </div>
                          <div
                            className="col-md-3"
                            style={{
                              padding: "5px",
                              borderBottom :index === array.length - 1 ? "1px solid #B4C6E7" : "none"
                            }}
                          >
                            {value}
                          </div>
                        </div>
                      </>
                    )
                  )
                  : ""}
              </div>
            </div>
            <div className="col-md-6">
              <div className="row justify-content-between">
                <div className="col-md-7">
                  <h5>Target Contribution %</h5>
                </div>
                <div className="col-md-2 card-title-prices">
                  {props.target}%
                </div>
              </div>
              <div className="row justify-content-left">
                <div className="col-md-10">
                  <Slider
                    min={0}
                    max={50}
                    defaultValue={props.target}
                    step={1}
                    marks={{ 0: 0, 50: 50 }}
                    onChange={(e) => {
                      props.setTarget(e);
                    }}
                    trackStyle={{ backgroundColor: "#4C44D8", height: 10 }}
                    handleStyle={{
                      borderColor: "#E5E5F2",
                      height: 20,
                      width: 20,
                      marginLeft: -5,
                      marginTop: -5,
                      backgroundColor: "#D9D9D9",
                      marginBottom: "80px",
                    }}
                    railStyle={{ backgroundColor: "#E5E5F2", height: 10 }}
                  />
                </div>
              </div>
              <div className="row mt-5 justify-content-center">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-7 ">
                      <h5>  Suggested Purchase Price </h5>
                    </div>
                    <div className="col-md-5">
                      <span
                        className="big-number"
                        style={{ color: "#4C44D8" }}
                      >
                        <h5> {purchasePrice ? purchasePrice["Purchase Price"] : ""} (UGX/Kg)</h5>
                      </span>
                    </div>


                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <h5>Sales Price Comparison</h5>
                <ColumnChart
                  data={props.outputDetails ? props.outputDetails["Graph"] : ""}
                />
                
              </div>
            </div>
          </div>
        </ModalBody>
        <div className="row justify-content-end mb-3">
          <div className="col-md-3">
            <div className="row">
              
              <div className="col-md-4">
                <Button color="secondary" onClick={props.handleCancel}>
                  Cancel
                </Button>{" "}
              </div>
              <div className="col-md-4">
                <Button color="primary" onClick={props.handleCreate}>
                  Submit
                </Button>{" "}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
