import React from "react";
import Highcharts from "highcharts/highcharts-gantt";
import HighchartsReact from "highcharts-react-official";
export const GanttChart = (props) => {
  console.log(props,'prop')
  const options = {
    title: {
      text: "",
    },
    chart: {
      height: 300,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        point: {
          events: {
            click: function () {
              if (this.options.url) {
                window.open(this.options.url, '_blank'); 
              }
            },
          },
        },
      },
    },
    xAxis: [
      {
        tickInterval: 1000 * 60 * 60 * 24 * 30,
        labels: {
          backgroundColor: '#4C44D8',
          format: "{value:%b}",
          style: {
            fontSize: "14px",
            background: "#E5E5F2",
            color: "#4C44D8",
           
          },
        },
        min: Date.UTC(2023, 0, 1),
        max: Date.UTC(2023, 12, 0),
        currentDateIndicator: false,
      },
    ],

    yAxis: {
      type: "category",
      grid: {
        enabled: true,
        borderColor: "rgba(0,0,0,0.3)",
        borderWidth: 1,
        fontSize:'14px',
        columns: [
          {
            title: {
              text: "Country",
            },
            labels: {
              format: "{point.name}",
              style: {
                fontSize: "14px",
                backgroundColor: "red",
              },
            },
          },
          {
            title: {
              text: "Production(M60KgBg)", 
              
            },
            labels: {
              format: "{point.production}",
            },
          },
        ],
      },
    },
    series: [
      {
        name: "Harvest Period",
        dataLabels: {
          verticalAlign: "top",
          inside: false,
        },
        data: [
          {
            start: Date.UTC(2023, 4, 1),
            end: Date.UTC(2023, 7, 31),
            y: 0,
            color: "#52A353",
            name: "Brazil",
            production: "38 (17.36%)",
            url: "https://www.reuters.com/markets/commodities/too-wet-bone-dry-indonesian-coffee-crop-faces-el-nino-jolt-2023-08-14/",
          },
          {
            start: Date.UTC(2023, 0, 0),
            end: Date.UTC(2023, 1, 28),
            y: 1,
            name: "Ethiopia",
            color: "#52A353",
            production: "8.27 ( 0.96 % ) ",
            url: "https://www.benzinga.com/pressreleases/23/09/34811545/2023-2031-ethiopia-coffee-market-is-growing-rapidly-with-modern-trend"

          },
          {
            start: Date.UTC(2023, 10, 0),
            end: Date.UTC(2023, 11, 31),
            y: 1,
            color: "#52A353",
            name: "Eithiopia",
            production: "",
            url: "https://www.benzinga.com/pressreleases/23/09/34811545/2023-2031-ethiopia-coffee-market-is-growing-rapidly-with-modern-trend"
          },

          {
            start: Date.UTC(2023, 3, 0),
            end: Date.UTC(2023, 5, 30),
            y: 2,
            color: "#52A353",
            name: "Columbia",
            production: "11.1 (4.50%)",
            url: "https://english.elpais.com/international/2023-09-10/droughts-and-declining-consumption-put-the-health-of-colombian-coffee-to-the-test.html"
          },
          {
            start: Date.UTC(2023, 8, 0),
            end: Date.UTC(2023, 10, 30),
            y: 2,
            color: "#F1D500",
            name: "Columbia",
            production: "8 (4%)",
            url: "https://english.elpais.com/international/2023-09-10/droughts-and-declining-consumption-put-the-health-of-colombian-coffee-to-the-test.html"
          },
          {
            start: Date.UTC(2023, 5, 1),
            end: Date.UTC(2023, 10, 31),
            y: 3,
            color: "#52A353",
            name: "Indonesia",
            production: "1.35 (-3.70%)",
            url: "https://www.reuters.com/markets/commodities/too-wet-bone-dry-indonesian-coffee-crop-faces-el-nino-jolt-2023-08-14/"
          },
         
        ],
      },
    ],
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"ganttChart"}
      options={options}
    />
  );
};
