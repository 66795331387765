import React, { useState, useEffect } from "react";
import { DualChart } from "./DualChart";
import { Card, CardTitle, CardBody, CardSubtitle } from "reactstrap";
import "../../../styles.css";
import { Auth } from "aws-amplify";

export const ExchangeRate = () => {
  const [exchangeRate, setExchangeRate] = useState(null);
  const [exchangeGraph, setExchangeGraph] = useState([]);

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      fetch("/api/market/prices/currentCurrency", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setExchangeRate(data);
        });
      fetch("/api/market/prices/currencyGraph", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setExchangeGraph(data);
        })
        .catch((error) => {
          alert("Error occured while loading Exchange Rate")
      });
    }
    genToken();
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          {exchangeRate ? (
            <>
              <Card className="shadow">
                <CardBody>
                  <CardTitle className="card-title-prices">
                    <div className="row">
                      <div className="col-md-10" style={{ textAlign: "left" }}>
                        Daily Exchange Rate
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          >
                            USD/UGX
                          </div>
                        </div>
                        <div className="row mt-5 justify-content-center">
                          <div
                            className="col-md-12 grey-text"
                            style={{ textAlign: "center" }}
                          >
                            Current Price
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12 big-number">
                            {exchangeRate["USD_UGX"]}
                            <span
                              style={{
                                fontSize: "11px",
                                color:
                                  exchangeRate["USD_UGX%"] >= 0
                                    ? "#458A46"
                                    : "#E31A1A",
                              }}
                            >
                              {exchangeRate["previous_USD_UGX"]}{" "}
                            </span>
                            <span
                              style={{
                                fontSize: "11px",
                                color:
                                  exchangeRate["USD_UGX%"] >= 0
                                    ? "#458A46"
                                    : "#E31A1A",
                              }}
                            >
                              ({exchangeRate["USD_UGX%"]}%)
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{ fontSize: "25px", fontWeight: "bold" }}
                          >
                            USD/B-Real
                          </div>
                        </div>
                        <div className="row mt-5">
                          <div
                            className="col-md-12 grey-text"
                            style={{ textAlign: "center" }}
                          >
                            Current Price
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12 big-number">
                            {exchangeRate["USD_BRL"]}{" "}
                            <span
                              style={{
                                fontSize: "11px",
                                color:
                                  exchangeRate["USD_BRL%"] >= 0
                                    ? "#458A46"
                                    : "#E31A1A",
                              }}
                            >
                              {exchangeRate["previous_USD_BRL"]}{" "}
                            </span>
                            <span
                              style={{
                                fontSize: "11px",
                                color:
                                  exchangeRate["USD_BRL%"] >= 0
                                    ? "#458A46"
                                    : "#E31A1A",
                              }}
                            >
                              ({exchangeRate["USD_BRL%"]}%)
                            </span>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <CardSubtitle className="time-text mt-2">
                      <div className="row">
                        <div className="col-md-10">
                          Updated on {exchangeRate["date"]}
                        </div>
                      </div>
                    </CardSubtitle>
                  </CardTitle>
                </CardBody>
              </Card>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6">
          <Card className="shadow">
            <CardBody>
              <CardTitle
                style={{ textAlign: "left" }}
                className="card-title-prices"
              >
                <div className="row">
                  <div className="col-md-10" style={{ textAlign: "left" }}>
                    Exchange Rate
                  </div>
                </div>
              </CardTitle>
              <DualChart data={exchangeGraph} />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};
