import React from "react";

const Header = () => {
  const headerStyle = {
    // width: "1440px",
    height: "50px",
    padding: "20px 0px 20px 20px",
    borderBottom: "1px solid #E0E0E0",
    background: "#FFF",
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    alignItems: "center",    
    zIndex: "1000", 
    display: "flex",
  };

  return (
    <div style={headerStyle} data-testid="img4">
      
      <img src="new.png" alt="Logo 1" style={{height:'40px'}} />
     
    </div>
  );
};

export default Header;
