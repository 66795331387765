import React, { useState } from "react";
import "../styles.css";
import {
  Nav,
  NavItem,
  CardImg,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FaHome } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Auth } from "aws-amplify";
export const Sidebar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const clickHome = () => {
    setIsOpen((prevState) => !prevState);
  };
  const clickMarket = () => {
    setIsOpen((prevState) => !prevState);
  };
  const clickRisk = () => {
    setIsOpen2((prevState) => !prevState);
  };
  const handleLogout = async (e, obj) => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  return (
    <>
      <div className="sidebar-sticky d-none d-md-block" style={{marginTop:'50px', zIndex:1001}}>
        <Nav className="flex-column">
        <div
            className="nav-links"
            onClick={() => {
              props.setMenu("Home");
            }}
          >
        <NavItem
              className="mb-2 mt-3"
              style={{ color: props.menu === "Home" ? "#4A42A0" : "#575757" }}
              onClick={clickHome}
              data-testid="home-menu"
            >
              Home
            </NavItem>
            </div>
          <div
            className="nav-links"
            onClick={() => {
              props.setMenu("Market");
            }}
           
          >
            <NavItem
              className="mb-2 mt-3"
              style={{ color: props.menu === "Market" ? "#4A42A0" : "#575757" }}
              onClick={clickMarket}
              data-testid="market-menu"
            >
              Market
              <MdKeyboardArrowDown />
            </NavItem>
          </div>
          {isOpen ? (
            <>
              <div
                className="nav-links"
                onClick={() => {
                  props.setMenu("Prices");
                }}
              >
                <NavItem
                  className="mt-2 mb-2"
                  style={{
                    color: props.menu === "Prices" ? "#4A42A0" : "#575757",
                    fontSize: "12px",
                  }}
                  data-testid="prices-menu"
                >
                  Prices
                </NavItem>
              </div>
              <div
                className="nav-links"
                onClick={() => {
                  props.setMenu("News");
                }}
                data-testid="news-menu"
              >
                <NavItem
                  className="mt-2 mb-2"
                  style={{
                    color: props.menu === "News" ? "#4A42A0" : "#575757",
                    fontSize: "12px",
                  }}
                >
                  News
                </NavItem>
              </div>
              <div
                className="nav-links"
                onClick={() => {
                  props.setMenu("Production");
                }}
                data-testid="production-menu"
              >
                <NavItem
                  className="mt-2 mb-2"
                  style={{
                    color: props.menu === "Production" ? "#4A42A0" : "#575757",
                    fontSize: "12px",
                  }}
                >
                  Production
                </NavItem>
              
              </div>
            </>
          ) : null}
          <div
            className="nav-links"
            onClick={() => {
              props.setMenu("Transaction");
            }}
            data-testid="transaction-menu"
          >
            <NavItem
              className="mt-2 mb-2"
              style={{
                color: props.menu === "Transaction" ? "#4A42A0" : "#575757",
              }}
            >
              Transaction
            </NavItem>
          </div>
          <div
            className="nav-links"
            onClick={() => {
              props.setMenu("Risk");
            }}
           
           
          >
            <NavItem
              className="mt-2 mb-2"
              style={{ color: props.menu === "Risk" ? "#4A42A0" : "#575757" }}
             
            >
              Risk
              <MdKeyboardArrowDown onClick={clickRisk}  data-testid="risk-menu"/>
            </NavItem>
          </div>
          {isOpen2 ? (
            <>
              <div
                className="nav-links"
                onClick={() => {
                  props.setMenu("Monitor");
                }}
                
              >
                <NavItem
                  className="mt-2 mb-2"
                  style={{
                    color: props.menu === "Monitor" ? "#4A42A0" : "#575757",
                    fontSize: "12px",
                  }}
                  data-testid="risk-monitor"
                >
                  Monitor
                </NavItem>
              </div>
              <div
                className="nav-links"
                onClick={() => {
                  props.setMenu("Risk Position");
                }}
                
              >
                <NavItem
                  className="mt-2 mb-2"
                  style={{
                    color:
                      props.menu === "Risk Position" ? "#4A42A0" : "#575757",
                    fontSize: "12px",
                  }}
                  data-testid="risk-position"
                >
                  Risk Position
                </NavItem>
              </div>
            </>
          ) : null}
          <div
            className="nav-links"
            onClick={() => {
              props.setMenu("Glossary");
            }}
            data-testid="glossary-menu"
          >
            <NavItem
              className="mt-2 mb-2"
              style={{
                color: props.menu === "Glossary" ? "#4A42A0" : "#575757",
              }}
            >
              Glossary
            </NavItem>
          </div>
          <div className="nav-links" data-testid="logout-menu" onClick={handleLogout}>
            <NavItem className="mt-2 mb-2">Logout</NavItem>
          </div>
        <div className="lastitems"   style={{ position: 'absolute', bottom: '100px', width: '100%' }}>
          <NavItem className="mt-3  justify-content-center"  data-testid="img1">
            <CardImg
              className="mr-4"
              style={{
                // width:"105px"
              }}
              src="raboofficial.png"
              alt="Card image cap"
            />
            
          </NavItem>
          <NavItem className="mt-2  justify-content-center" data-testid="img2">
            <CardImg
              className=""
              style={{
                padding:10
              }}
              src="adc.png"
              alt="Card image cap"
            />
            
          </NavItem>
          <NavItem className="mt-2 justify-content-center"  data-testid="img3">
            <CardImg
              className="mr-5"
              style={{
                // width:"105px"
              }}
              src="agtuall.png"
              alt="Card image cap"
            />
            
          </NavItem>
          </div>
        </Nav>
      </div>
      <nav className="navbar sticky-top navbar-light bg-light d-block d-md-none">
        <div className="row justify-content-between">
          <div className="col-3">
            <NavbarBrand href="/">
              <CardImg
                style={{
                  width: "25px",
                  float: "right",
                }}
                src="logo.jpg"
                alt="Card image cap"
              />
            </NavbarBrand>
          </div>
          <div className="col-7">
            <UncontrolledDropdown>
              <DropdownToggle nav>Menu</DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <NavItem className="mt-2 mb-2">
                    <FaHome
                      size={24}
                      style={{
                        margin: "0px 10px 10px 0px",
                      }}
                    />
                    Home
                  </NavItem>
                </DropdownItem>
                <DropdownItem>
                  <NavItem className="mt-2 mb-2">
                    <FaHome
                      size={24}
                      style={{
                        margin: "0px 10px 10px 0px",
                      }}
                    />
                    Home
                  </NavItem>
                </DropdownItem>
                <DropdownItem>
                  <NavItem className="mt-2 mb-2">
                    <FaHome
                      size={24}
                      style={{
                        margin: "0px 10px 10px 0px",
                      }}
                    />
                    Home
                  </NavItem>
                </DropdownItem>
                <DropdownItem>
                  <NavItem className="mt-2 mb-2">
                    <FaHome
                      size={24}
                      style={{
                        margin: "0px 10px 10px 0px",
                      }}
                    />
                    Home
                  </NavItem>
                </DropdownItem>
                <DropdownItem>
                  <NavItem className="mt-2 mb-2">
                    <FaHome
                      size={24}
                      style={{
                        margin: "0px 10px 10px 0px",
                      }}
                    />
                    Home
                  </NavItem>
                </DropdownItem>
                <DropdownItem>
                  <NavItem className="mt-2 mb-2">
                    <FaHome
                      size={24}
                      style={{
                        margin: "0px 10px 10px 0px",
                      }}
                    />
                    Home
                  </NavItem>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </nav>
    </>
  );
};
