import React, { useState, useEffect } from "react";
import { Card } from "reactstrap";
import "../../../news.css";
import { LuFilter } from "react-icons/lu";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { NewsCards } from "./NewsCards";
import { AllModal } from "./AllModal";
import { Auth, Analytics } from "aws-amplify";
import "../../../switch.css";

export const News = () => {
  const [news, setNews] = useState(null);

  const [filterModal, setFilterModal] = useState(false);
  const toggleFilter = () => setFilterModal(!filterModal);
  const [allModal, setAllModal] = useState(false);
  const toggleAll = () => setAllModal(!allModal);

  const [allModal1, setAllModal1] = useState(false);
  const toggleAll1 = () => setAllModal1(!allModal1);

  const [allModal2, setAllModal2] = useState(false);
  const toggleAll2 = () => setAllModal2(!allModal2);

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;

      Analytics.autoTrack('session', {
        // REQUIRED, turn on/off the auto tracking
        enable: true,
        // OPTIONAL, the attributes of the event, you can either pass an object or a function
        // which allows you to define dynamic attributes
        attributes: {
          email: email,
          page: 'News'
        },
      });

      fetch("/api/market/news/news", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setNews(data);
        })
        .catch((error) => {
          setNews(null);
          console.log('Error', error)
        });
    }
    genToken();

  }, []);
  return (
    <>
      <div className="row mt-5 justify-content-end mb-2 news-page">
       <div className="col-md-11" style={{ overflow: "hidden" }}>
       <div className="scrollable-content">
          <div className="row">
            <div className="col-md-9" style={{ textAlign: "left" }}>
              <div className="row">
                <div className="col-md-11">
                  <h5
                    style={{
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "28px",
                      letterSpacing: "-0.5px",
                    }}
                  >
                    News
                  </h5>
                </div>
                <div className="col-md-1">
                  <LuFilter
                    size={25}
                    style={{ color: "#4A42A0" }}
                    onClick={toggleFilter}
                  />
                  <span
                    style={{
                      color: "#4A42A0",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "20px",
                      letterSpacing: "-0.2px",
                    }}
                  >
                    Filters
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-9 grey-text">Last Week</div>
                    <div className="col-md-3 grey-text" onClick={toggleAll}>
                      See All
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 scroll">
                      {news ? (
                        <NewsCards data={news["last_week"].slice(0, 7)} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-9 grey-text">Last Month</div>
                    <div className="col-md-3 grey-text" onClick={toggleAll1}>
                      See All
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 scroll">
                      {news ? (
                        <NewsCards data={news["last_month"].slice(0, 7)} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-9 grey-text">Report</div>
                    <div className="col-md-3 grey-text" onClick={toggleAll2}>
                      See All
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 scroll">
                      {news ? (
                        <NewsCards data={news["reports"].slice(0, 7)} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <Card
                style={{
                  borderRadius: "8px",
                  border: "1px solid #670B8C",
                  marginRight: "10px",
                }}
              >
                <div
                  style={{
                    margin: "10px 130px 0px 0px",
                    borderRadius: "0px 4px 4px 0px",
                    border: "1px solid #670B8C",
                    backgroundColor: "#670B8C",
                    color: "#fff",
                    fontSize: "12px",
                  }}
                >
                  ADC Announcement
                </div>
                <div className="row m-1">
                  <div className="col-md-12 news-heading" style={{ textAlign: "left" }}>
                    "NSSF Hi-Innovator partners with ADC to boost entrepreneurs' capacity and resources. Join the Business Academy today and supercharge your entrepreneurial journey!"
                  </div>
                </div>
                <div className="row m-1">

                  <div className="col-md-12 grey-text">
                    Oct 8, 2023, 12:15 PM
                  </div>
                </div>
              </Card>
              <div className="row justify-content-center mt-5">
                <div className="col-md-11 scroll">
                  <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="agribdcu"
                    options={{ height: 600 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      {/* <FilterModal filterModal={filterModal} toggleFilter={toggleFilter} /> */}
      <AllModal
        allModal={allModal}
        toggleAll={toggleAll}
        data={news ? news["last_week"] : null}
      />
      <AllModal
        allModal={allModal1}
        toggleAll={toggleAll1}
        data={news ? news["last_month"] : null}
      />
      <AllModal
        allModal={allModal2}
        toggleAll={toggleAll2}
        data={news ? news["reports"] : null}
      />
       <AllModal
        allModal={allModal2}
        toggleAll={toggleAll2}
        data={news ? news["reports"] : null}
      />
    </>
  );
};