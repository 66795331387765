import React, { useEffect, useState } from 'react';
import { Auth } from "aws-amplify";
const ContractDetails = ({ contracts }) => {

  return (
    <div>
      <div>
        {contracts.map((contract, index) => (
          <div className="row" style={{
            textAlign: "left",
            fontFamily: 'Inter',
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "-0.2px",
          }}
            key={index}>
            <div className="col-md-6">
              <p>Type of Contract: {contract.typeOfContract}</p>
              <p>Sales Grade: {contract.salesGrade}</p>
              <p>Quantity in Kg: {contract.quantity}</p>
              <p>Contract Date: {contract.contractDate}</p>
              <p>Delivery Date: {contract.deliveryDate}</p>
              <p>Certification: {contract.certification} </p>
              <p>Price Fixing Period: {contract.priceFixingPeriod}</p>
            </div>
            <div className="col-md-3">
              <p>Price Fixing End: {contract.priceFixingEnd}</p>
              <p>Offer Price: {contract.offerPrice}</p>
              <p>Quality Differential: {contract.qualityDifferential}</p>
              {contract.certification.includes("Certified Rainforest") && (
                <p>Rainforest Premium: {contract.rainforestPremium}</p>
              )}
              <p>Total Contract Value: {contract.totalContractValue}</p>
              <p>ICE Futures Price: {contract.iceFuturesPrice}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const DetailTable = (props) => {

  const [contracts, setContracts] = useState([]);
  useEffect(() => {

    let didCancel = false;
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      fetch(
        "/api/risk/monitor/contractDetail?account_id="+email+"&contract_number=" + props.contractNumber,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setContracts(data)
        })
        .catch(()=>{
          setContracts([]);
        })

      if (!didCancel) {
        console.log("....");
      }
    }
    genToken();
    return () => {
      didCancel = true;
    };
  }, [props.contractNumber])

  return (
    <div>
      <ContractDetails contracts={contracts} />
    </div>
  );
};

export default DetailTable;
