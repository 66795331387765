import "./App.css";
import { MainLayout } from "./components/MainLayout";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { ReactNotifications } from "react-notifications-component";
import { withAuthenticator } from "@aws-amplify/ui-react";
import awsmobile from "./aws-exports";
Amplify.configure(awsmobile);
function App() {
  return (
    <div className="App">
      <ReactNotifications />
      <MainLayout />
    </div>
  );
}

export default withAuthenticator(App);
